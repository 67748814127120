import { createTheme } from '@mui/material';

export const VARIANT_BODY2_FONT_SIZE = '0.875rem';
export const VARIANT_BODY2_LINE_HEIGHT = 1.43;

export const theme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				option: {
					fontSize: VARIANT_BODY2_FONT_SIZE,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				label: {
					fontSize: VARIANT_BODY2_FONT_SIZE,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
			},
		},
		MuiDialogContentText: {
			defaultProps: {
				variant: 'body2',
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontSize: VARIANT_BODY2_FONT_SIZE,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontSize: VARIANT_BODY2_FONT_SIZE,
					lineHeight: VARIANT_BODY2_LINE_HEIGHT,
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				variant: 'body2',
			},
		},
	},
});
