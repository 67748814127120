import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
	MRT_Row,
} from 'material-react-table';
import { Tooltip, Box, IconButton, Paper, Stack } from '@mui/material';
import {
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Info as InfoIcon,
	Policy as PolicyIcon,
} from '@mui/icons-material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { Link } from '../../components/Link/Link';
import { useNavigate } from '../../hooks/useNavigate';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { securityPolicySchema } from './schema';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { PAMTargetGroupDetailModel, GetSecurityPoliciesResponseDto, SecurityPolicyModel } from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';

export const SecurityPolicyList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const [securityPolicyIDToDelete, setSecurityPolicyIDToDelete] = React.useState<number | null>(null);
	const [multiSecurityPolicyIDToDelete, setMultiSecurityPolicyIDToDelete] = React.useState<{ ids: number[] }>({
		ids: [],
	});
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'description']);

	const { data, isError, isRefetching, isLoading, error } = useQuery<GetSecurityPoliciesResponseDto>({
		queryKey: [EQueryKey.SECURITY_POLICY_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					filterList: swaggerQuery.filter,
					sortList: swaggerQuery.sort,
					columnsList: swaggerQuery.columns,
					offset: swaggerQuery.offset,
					limit: swaggerQuery.limit,
				};

				const response = await api.securityPolicies.getSecurityPolicies(query);
				response.data.entities.forEach((group) => {
					securityPolicySchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.securityPolicy.list.confirmation.delete.title'));
				setConfirmationText(t('page.securityPolicy.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.securityPolicy.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.securityPolicy.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenSecurityPolicyDeleteDialog = React.useCallback(
		(securityPolicyID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setSecurityPolicyIDToDelete(securityPolicyID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnOpenMultipleSecurityPoliciesDelete = React.useCallback(
		(table: MRTTableInstance<Partial<PAMTargetGroupDetailModel>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.securityPolicy.list.errorMessages.noSecurityPoliciesSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiSecurityPolicyIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed],
	);

	const handleOnConfirmSecurityPolicyDelete = React.useCallback(async () => {
		if (securityPolicyIDToDelete) {
			try {
				await api.securityPolicies.deleteSecurityPolicy(securityPolicyIDToDelete);
				enqueueSnackbar(t('page.securityPolicy.list.actionMessages.securityPolicySuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setSecurityPolicyIDToDelete(null);
				updateRowSelection([securityPolicyIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiSecurityPolicyIDToDelete.ids.length > 0) {
			try {
				await api.securityPolicies.deleteSecurityPolicies(multiSecurityPolicyIDToDelete);
				enqueueSnackbar(t('page.securityPolicy.list.actionMessages.securityPoliciesSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiSecurityPolicyIDToDelete({ ids: [] });
				updateRowSelection(multiSecurityPolicyIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [securityPolicyIDToDelete, multiSecurityPolicyIDToDelete, isAllowed]);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		if (!isError) {
			return;
		}

		if (error instanceof AxiosError) {
			const errorResponse = error.response?.data.message ? error.response?.data.message : error.message;
			enqueueSnackbar(errorResponse, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((error as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [isError]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	// TODO: Just for temporary testing
	const shouldDeleteSecurityPolicy = (row: MRT_Row<Partial<SecurityPolicyModel>>) => {
		const protectedPolicyIDs = [1, 2, 3];

		return !protectedPolicyIDs.includes(row.original.id as number);
	};

	const columns = React.useMemo<MRTColumnDef<Partial<SecurityPolicyModel>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				header: t('page.securityPolicy.edit.table.header.name'),
				accessorKey: 'name',
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'description',
				header: t('page.securityPolicy.edit.table.header.description'),
			},
		],
		[isAllowed],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.securityPolicy.list.title')}
						description={t('page.securityPolicy.list.description')}
						icon={PolicyIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						enableRowActions
						enableStickyHeader={false}
						state={{
							isLoading: isLoading,
							showAlertBanner: error !== null,
							pagination,
							rowSelection,
							showProgressBars: isRefetching,
							columnFilters,
							globalFilter,
							sorting,
							columnVisibility,
						}}
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						rowCount={total}
						manualPagination
						manualFiltering
						manualSorting
						onPaginationChange={setPagination}
						enableRowSelection={isAllowed([EPermission.SECURITY_POLICIES_DELETE])}
						onRowSelectionChange={setRowSelection}
						onSortingChange={setSorting}
						onGlobalFilterChange={setGlobalFilter}
						onColumnFiltersChange={setColumnFilters}
						onColumnVisibilityChange={setColumnVisibility}
						getRowId={(originalRow) => originalRow.id?.toString() || ''}
						renderRowActions={({ row }) => (
							<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
								{isAllowed([EPermission.SECURITY_POLICIES_UPDATE]) && (
									<Tooltip
										arrow
										placement='left'
										title={t('page.securityPolicy.list.tooltips.edit')}
										enterDelay={500}
									>
										<IconButton
											onClick={handleRedirect(`/security/securityPolicy/edit/${row.original.id}`)}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
								{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) &&
									shouldDeleteSecurityPolicy(row) && (
										<Tooltip
											arrow
											placement='right'
											title={t('page.securityPolicy.list.tooltips.delete')}
											enterDelay={500}
										>
											<IconButton
												color='error'
												onClick={handleOnOpenSecurityPolicyDeleteDialog(
													row.original.id as number,
												)}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
							</Box>
						)}
						renderToolbarInternalActions={({ table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<MRTToggleGlobalFilterButton table={table} />
								<MRTToggleFiltersButton table={table} />
								<MRTShowHideColumnsButton table={table} />
								{/* <Tooltip arrow title={t('page.securityPolicy.list.tooltips.export')} enterDelay={500}>
									<IconButton>
										<SaveAltIcon />
									</IconButton>
								</Tooltip> */}
								{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) && (
									<Tooltip
										title={t('page.securityPolicy.list.tooltips.removeSelected')}
										enterDelay={500}
									>
										<span>
											<IconButton
												color='error'
												disabled={table.getSelectedRowModel().rows.length === 0}
												onClick={handleOnOpenMultipleSecurityPoliciesDelete(table)}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								<MRTToggleDensePaddingButton table={table} />
								<MRTFullScreenToggleButton table={table} />
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.securityPolicy.edit.table.header.actions'),
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1250 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiSelectCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiSelectAllCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								verticalAlign: 'baseline',
							},
						})}
						editDisplayMode='modal'
						positionActionsColumn='last'
						muiTableBodyRowProps={({ row }) => ({
							onClick:
								isAllowed([EPermission.SECURITY_POLICIES_READ]) ?
									handleRedirect(`/security/securityPolicy/${row.original.id}`)
								:	undefined,
							sx: { cursor: 'pointer' },
						})}
						localization={MRTLocalization}
					/>
				</Stack>
			</Paper>

			{isAllowed([EPermission.SECURITY_POLICIES_CREATE]) && (
				<Link to='/security/securityPolicy/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.securityPolicy.list.ariaLabel.createSecurityPolicy')}
						tooltipTitle={t('page.securityPolicy.list.tooltips.createSecurityPolicy')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.SECURITY_POLICIES_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmSecurityPolicyDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.securityPolicy.list.confirmation.delete.cancel')}
					confirmText={t('page.securityPolicy.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
