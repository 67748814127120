import { Stack, Typography } from '@mui/material';

import { Heading } from '../Heading/Heading';
import { Props } from './types';

export const SectionHeader: React.FC<Props> = ({ description, title }) => (
	<Stack>
		<Heading label={title} />
		<Typography>{description}</Typography>
	</Stack>
);
