import * as React from 'react';
import { Typography, Box, Paper, useMediaQuery, CircularProgress, Link } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import AppStoreIcon from '../../assets/icons/AppStoreIcon';
import GooglePlayIcon from '../../assets/icons/GooglePlayIcon';
import loginBackground from '../../assets/images/login-background.png';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { SEO } from '../../components/SEO/SEO';
import { useNetworkContext } from '../../hooks/useNetworkContext';

const StyledPaper = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	maxWidth: 500,
	color: theme.palette.text.primary,
}));

const Background = styled('div')({
	width: '100%',
	height: '100%',
	backgroundImage: `url(${loginBackground})`,
	backgroundPosition: 'center',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	zIndex: 2,
	top: 0,
	left: 0,
	color: 'rgba( 0, 0, 0, 0.5 )',
});

const MobileStyles = {
	StyledPaper: styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		width: '100vw',
		height: '100vh',
		margin: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: theme.palette.text.primary,
	})),
};

export const LoginPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery('(max-width:600px)');
	const PaperComponent = isMobile ? MobileStyles.StyledPaper : StyledPaper;
	const authContext = React.useContext(AuthContext);
	const [firstCall, setFirstCall] = React.useState(true);
	const [loginQrCode, setLoginQrCode] = React.useState<string | null>(null);
	const networkContext = useNetworkContext();
	const [tenant, setTenant] = React.useState<{
		slug?: string;
		name: string;
	} | null>(null);

	const swaggerApi = useSwaggerApi();

	const handleAuthLogin = React.useCallback(
		async (first: boolean, format: 'svg') => {
			try {
				const response = await swaggerApi.auth.login({
					first,
					format,
				});
				const { qr, tenant } = response.data;
				setTenant(tenant ?? null);
				if (qr) {
					setLoginQrCode(qr);
				} else {
					authContext.handleOnLoadUser();
				}
			} catch (error) {
				console.error(error);
			} finally {
				if (first) {
					setFirstCall(false);
				}
			}
		},
		[firstCall],
	);

	React.useEffect(() => {
		let isMounted = true;
		const bootstrap = async () => {
			try {
				if (!isMounted || !networkContext.isApiAvailable || !networkContext.isNetworkAvailable) {
					return;
				}
				await handleAuthLogin(firstCall, 'svg');
			} catch (error: any) {
				console.error(error);
			}
		};

		bootstrap();

		return () => {
			isMounted = false;
		};
	}, [loginQrCode, firstCall, networkContext.isApiAvailable, networkContext.isNetworkAvailable]);

	return (
		<Background>
			<Box
				sx={{
					overflow: 'hidden',
				}}
			>
				<SEO title={t('page.login.title')} description={t('page.login.description')} />
				<PaperComponent
					sx={{
						mx: 'auto',
						p: 4,
					}}
				>
					<Grid container wrap='wrap' spacing={2}>
						<Grid container>
							<Typography align='center' variant='h6'>
								{t('page.login.text.qr')}
							</Typography>
							{tenant && (
								<Grid container justifyContent='center'>
									<Box mr={1}>
										<Typography variant='h6'>{'Tenant:'}</Typography>
									</Box>
									<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
										{tenant.name}
									</Typography>
								</Grid>
							)}
						</Grid>
						<Grid item xs zeroMinWidth>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									cursor: 'none',
								}}
								height={350}
							>
								{loginQrCode ?
									<Box
										loading='lazy'
										component='img'
										width={350}
										src={`data:image/svg+xml;utf8,${encodeURIComponent(loginQrCode)}`}
										draggable={false}
									/>
								:	<CircularProgress />}
							</Box>
						</Grid>
					</Grid>
					<Typography align='center' pb={2} mt={5}>
						{t('page.login.text.mobileApp')}
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs>
							{/* <GooglePlayIcon /> */}
							<Link
								href='https://play.google.com/store/apps/details?id=com.v4xclbr'
								target='_blank'
								rel='noreferrer'
							>
								<GooglePlayIcon />
							</Link>
						</Grid>
						<Grid item xs>
							<Link
								href='https://apps.apple.com/us/app/excalibur-v4/id6630382972?l=cs'
								target='_blank'
								rel='noreferrer'
							>
								<AppStoreIcon />
							</Link>
						</Grid>
					</Grid>
				</PaperComponent>
			</Box>
		</Background>
	);
};
