import { TFunction } from 'i18next';
import { z } from 'zod';

export const networkAddressSchema = z.object({
	id: z.number(),
	tenantID: z.number().nullable(),
	networkAddress: z.string(),
});

const ACCEPTED_FILE_EXTENSIONS = ['.txt'];

export const networkPolicyFormSchema = (t: TFunction) =>
	z.object({
		file: z
			.instanceof(File)
			.nullable()
			.refine((file) => !file || ACCEPTED_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext)), {
				message: t('component.networkAddressTable.errorMessage.fileExtension'),
			}),
	});
