import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { Typography, Tooltip, Box, IconButton, Paper, Stack } from '@mui/material';
import {
	ContentCopy as ContentCopyIcon,
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Info as InfoIcon,
	Workspaces as WorkspacesIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { Link } from '../../../components/Link/Link';
import { useNavigate } from '../../../hooks/useNavigate';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { targetGroupSchema } from './schema';
import { ChipArray } from '../../../components/ChipArray/ChipArray';
import { EConfirmDialogState } from '../../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import {
	GetPAMTargetGroupDetailsResponseDto,
	PAMTargetGroupDetailModel,
	PAMTargetSummaryModel,
} from '../../../api/Api';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';
import { useTableQuery } from '../../../hooks/useTableQuery';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';

export const PAMTargetGroupList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const [rowSelection, setRowSelection] = React.useState<MRTRowSelectionState>({});
	const [groupIDToDelete, setGroupIDToDelete] = React.useState<number | null>(null);
	const [multiGroupIDToDelete, setMultiGroupIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name']);

	const { data, isError, isRefetching, isLoading, error } = useQuery<GetPAMTargetGroupDetailsResponseDto>({
		queryKey: [EQueryKey.PAM_GROUP_DETAIL_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.pamGroups.getPamTargetGroupDetails(query);
				response.data.entities.forEach((group) => {
					targetGroupSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.pamGroup.list.confirmation.delete.title'));
				setConfirmationText(t('page.pamGroup.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.pamGroup.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.pamGroup.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenGroupDeleteDialog = React.useCallback(
		(tenantID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setGroupIDToDelete(tenantID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnOpenMultipleGroupsDelete = React.useCallback(
		(table: MRTTableInstance<Partial<PAMTargetGroupDetailModel>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.pamGroup.list.errorMessages.noPamGroupSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiGroupIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed],
	);

	const handleOnConfirmGroupDelete = React.useCallback(async () => {
		if (groupIDToDelete) {
			try {
				await api.pamGroups.deletePamTargetGroup(groupIDToDelete);
				enqueueSnackbar(t('page.pamGroup.list.actionMessages.pamGroupSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setGroupIDToDelete(null);
				updateRowSelection([groupIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiGroupIDToDelete.ids.length > 0) {
			try {
				await api.pamGroups.deletePamTargetGroups(multiGroupIDToDelete);
				enqueueSnackbar(t('page.pamGroup.list.actionMessages.pamGroupsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});

				reactQueryClient.invalidateQueries();
				setOpen(false);
				setMultiGroupIDToDelete({ ids: [] });
				updateRowSelection(multiGroupIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}
	}, [groupIDToDelete, multiGroupIDToDelete, isAllowed]);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	const handleOnChipClick = React.useCallback(
		(target: PAMTargetSummaryModel) => {
			if (!isAllowed([EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN], false)) {
				return;
			}
			navigate(`/pam/${target.id}`);
		},
		[isAllowed],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<PAMTargetGroupDetailModel>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.pamGroup.edit.table.header.groups'),
				grow: 1,
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip title={row.original.description} placement='right' enterDelay={500} arrow>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.targets}`,
				header: t('page.pamGroup.edit.table.header.targets'),
				accessorKey: 'target',
				enableColumnFilter: false,
				enableGlobalFilter: false,
				enableSorting: false,
				grow: 3,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={
							isAllowed([EPermission.PAM_TARGETS_READ_ALL, EPermission.PAM_TARGETS_READ_OWN], false) ?
								row.original.targets
							:	undefined
						}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
		],
		[isAllowed],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.pamGroup.list.title')}
						description={t('page.pamGroup.list.description')}
						icon={WorkspacesIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						enableStickyHeader={false}
						state={{
							isLoading: isLoading,
							showAlertBanner: error !== null,
							pagination,
							rowSelection,
							showProgressBars: isRefetching,
							columnFilters,
							globalFilter,
							sorting,
							columnVisibility,
						}}
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						rowCount={total}
						manualPagination
						manualFiltering
						manualSorting
						onSortingChange={setSorting}
						onGlobalFilterChange={setGlobalFilter}
						onColumnFiltersChange={setColumnFilters}
						onPaginationChange={setPagination}
						onColumnVisibilityChange={setColumnVisibility}
						enableRowActions
						enableRowSelection={isAllowed([EPermission.PAM_GROUPS_DELETE])}
						getRowId={(originalRow) => originalRow.id?.toString() || ''}
						onRowSelectionChange={setRowSelection}
						renderRowActions={({ row }) => (
							<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
								{isAllowed([EPermission.PAM_GROUPS_CREATE]) && (
									<Tooltip
										title={t('page.pamGroup.list.tooltips.duplicate')}
										placement='left'
										enterDelay={500}
										arrow
									>
										<IconButton
											onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
												event.stopPropagation();
												navigate('/pam/groups/new', {
													state: { copyFromId: row.original.id },
												});
											}}
										>
											<ContentCopyIcon />
										</IconButton>
									</Tooltip>
								)}
								{isAllowed([EPermission.PAM_GROUPS_UPDATE]) && (
									<Tooltip
										title={t('page.pamGroup.list.tooltips.edit')}
										placement='left'
										enterDelay={500}
										arrow
									>
										<IconButton onClick={handleRedirect(`/pam/groups/edit/${row.original.id}`)}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
								{isAllowed([EPermission.PAM_GROUPS_DELETE]) && (
									<Tooltip
										title={t('page.pamGroup.list.tooltips.delete')}
										placement='right'
										enterDelay={500}
										arrow
									>
										<IconButton
											color='error'
											onClick={handleOnOpenGroupDeleteDialog(row.original.id as number)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						)}
						renderToolbarInternalActions={({ table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<MRTToggleGlobalFilterButton table={table} />
								<MRTToggleFiltersButton table={table} />
								<MRTShowHideColumnsButton table={table} />
								{/* <Tooltip title={t('page.pamGroup.list.tooltips.export')} enterDelay={500}>
									<IconButton>
										<SaveAltIcon />
									</IconButton>
								</Tooltip> */}
								{isAllowed([EPermission.PAM_GROUPS_DELETE]) && (
									<Tooltip title={t('page.pamGroup.list.tooltips.removeSelected')} enterDelay={500}>
										<span>
											<IconButton
												color='error'
												disabled={table.getSelectedRowModel().rows.length === 0}
												onClick={handleOnOpenMultipleGroupsDelete(table)}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								<MRTToggleDensePaddingButton table={table} />
								<MRTFullScreenToggleButton table={table} />
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.pamGroup.edit.table.header.actions'),
								size: 180,
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1250 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								paddingLeft: 2,
								paddingBottom: 2,
							},
						})}
						muiTableBodyCellProps={() => ({
							sx: {
								paddingLeft: 2,
							},
						})}
						editDisplayMode='modal'
						positionActionsColumn='last'
						muiTableBodyRowProps={({ row }) => ({
							onClick:
								isAllowed([EPermission.PAM_GROUPS_READ]) ?
									handleRedirect(`/pam/groups/${row.original.id}`)
								:	undefined,
							sx: { cursor: 'pointer' },
						})}
						localization={MRTLocalization}
						layoutMode='grid-no-grow'
					/>
				</Stack>
			</Paper>

			{isAllowed([EPermission.PAM_GROUPS_CREATE]) && (
				<Link to='/pam/groups/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.pamGroup.list.ariaLabel.createGroup')}
						tooltipTitle={t('page.pamGroup.list.tooltips.createGroup')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.PAM_GROUPS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmGroupDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.pamGroup.list.confirmation.delete.cancel')}
					confirmText={t('page.pamGroup.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
