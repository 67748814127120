import * as React from 'react';
import { WeekdaySelectorProps } from './types';
import {
	Box,
	FormControlLabel,
	FormHelperText,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	FormLabel,
	Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { getDays } from './constants';

export const WeekdaySelector: React.FC<WeekdaySelectorProps> = ({
	control,
	rules,
	name,
	label,
	error,
	disabled = false,
	helperText,
}) => {
	const [selectedAll, setSelectedAll] = React.useState<boolean>(false);
	const [lastManualSelected, setLastManualSelected] = React.useState<string[]>([]);
	const { t } = useTranslation();

	const DAYS = React.useMemo(() => getDays(t), [t]);

	return (
		<Box>
			<FormLabel sx={{ color: 'text.primary' }}>{label}</FormLabel>
			<Controller
				name={name}
				control={control}
				rules={rules}
				defaultValue={[]}
				render={({ field: { value, onChange }, fieldState }) => {
					const errorField = error ? error : fieldState.error;

					const handleOnChange = React.useCallback(
						(_: React.MouseEvent<HTMLElement>, newValue: string[]) => {
							if (newValue.length === DAYS.length) {
								setSelectedAll(true);
							} else {
								setSelectedAll(false);
								setLastManualSelected(newValue);
							}
							onChange(newValue);
						},
						[DAYS.length],
					);

					const handleCheckboxChange = React.useCallback(
						(event: React.ChangeEvent<HTMLInputElement>) => {
							setSelectedAll(event.target.checked);
							if (event.target.checked) {
								setLastManualSelected(value);
								onChange(DAYS.map((day) => day.value));
							} else {
								onChange(lastManualSelected);
							}
						},
						[DAYS, onChange, value, lastManualSelected],
					);

					React.useEffect(() => {
						if (value.length === DAYS.length) {
							setSelectedAll(true);
						} else {
							setSelectedAll(false);
						}
					}, [value, DAYS.length]);

					return (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
							<ToggleButtonGroup
								value={value}
								onChange={handleOnChange}
								disabled={disabled}
								sx={{ display: 'flex', flexFlow: 'row wrap' }}
							>
								{DAYS.map((day, index) => {
									return (
										<Tooltip
											arrow
											placement='top'
											title={t(`component.weekdaySelector.body.days.${day.value}.long`)}
											key={index}
										>
											<span>
												<ToggleButton key={index} value={day.value} sx={{ width: 45 }}>
													{day.label}
												</ToggleButton>
											</span>
										</Tooltip>
									);
								})}
							</ToggleButtonGroup>
							{errorField && typeof errorField.message === 'string' ?
								<FormHelperText error id='security-error'>
									{errorField.message}
								</FormHelperText>
							:	<FormHelperText>{helperText}</FormHelperText>}
							<FormControlLabel
								label={t('component.weekdaySelector.body.everyDay')}
								disabled={disabled}
								control={<Switch checked={selectedAll} onChange={handleCheckboxChange} />}
							/>
						</Box>
					);
				}}
			/>
		</Box>
	);
};
