import * as React from 'react';
import { Grid, Typography, IconButton, Box, useMediaQuery, Tooltip } from '@mui/material';
import { PlayArrowSharp, Pause, Fullscreen } from '@mui/icons-material';
import { Progress } from './Progress';
import { useTranslation } from 'react-i18next';
import { ControlPanelProps } from './types';

export const ControlPanel: React.FC<ControlPanelProps> = ({
	isPlaying,
	position,
	duration,
	status,
	userInput,
	onPlay,
	onSeek,
	fullScreenMode,
}) => {
	const [adjustedPosition, setAdjustedPosition] = React.useState<number>(0);

	const { t } = useTranslation();

	const disabled = status === 'unloaded' || status === 'seek';

	const isSmallScreen = useMediaQuery('(max-width: 600px)');

	const formatTime = React.useCallback((timeInMilliSeconds: number, duration: number): string => {
		const timeInSeconds = Math.floor(timeInMilliSeconds / 1000);
		const durationInSeconds = Math.floor(duration / 1000);

		const hours = Math.floor(timeInSeconds / 3600);
		const minutes = Math.floor((timeInSeconds % 3600) / 60);
		const seconds = Math.floor(timeInSeconds % 60);

		const formattedHours = String(hours).padStart(2, '0');
		const formattedMinutes = String(minutes).padStart(2, '0');
		const formattedSeconds = String(seconds).padStart(2, '0');

		const result =
			durationInSeconds >= 3600 ?
				`${formattedHours}:${formattedMinutes}:${formattedSeconds}`
			:	`${formattedMinutes}:${formattedSeconds}`;

		return result;
	}, []);

	React.useEffect(() => {
		let intervalId: NodeJS.Timer | null = null;

		const updatePosition = (): void => {
			setAdjustedPosition((prevPosition) => (prevPosition < duration ? prevPosition + 100 : duration));
		};

		if (isPlaying) {
			setAdjustedPosition(position);
			intervalId = setInterval(updatePosition, 100);
		} else if (intervalId) {
			clearInterval(intervalId);
			intervalId = null;
		}

		if (status === 'ended') {
			setAdjustedPosition(duration);
		} else if (status === 'started') {
			setAdjustedPosition(position);
		}

		return () => {
			if (intervalId) {
				clearInterval(Number(intervalId));
				intervalId = null;
			}
		};
	}, [isPlaying, duration, status]);

	return (
		<Box
			sx={{
				position: 'absolute',
				bottom: 0,
				left: 0,
				right: 0,
				bgcolor: 'rgba(0, 0, 0, 0.5)',
				padding: '5px 10px',
			}}
		>
			<Grid container direction='row' wrap='nowrap' justifyContent='space-between' alignItems='center'>
				<Grid item>
					<Tooltip title={t('component.videoplayer.body.control')}>
						<IconButton aria-label='control button' onClick={onPlay} disabled={disabled}>
							{isPlaying ?
								<Pause fontSize='medium' style={{ color: 'white' }} />
							:	<PlayArrowSharp fontSize='medium' style={{ color: 'white' }} />}
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item textAlign='center'>
					{!isSmallScreen && (
						<Typography style={{ color: 'white', padding: '0 10px' }}>
							{formatTime(adjustedPosition, duration)}
						</Typography>
					)}
				</Grid>
				<Grid item xs={true}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Progress
							currentValue={adjustedPosition}
							maxValue={duration}
							disabled={disabled}
							userInput={userInput}
							onChangeProgress={onSeek}
						/>
					</Box>
				</Grid>
				<Grid item textAlign='center'>
					{!isSmallScreen && (
						<Typography style={{ color: 'white', padding: '0 10px' }}>
							{formatTime(duration, duration)}
						</Typography>
					)}
				</Grid>
				<Grid item textAlign='center'>
					<Tooltip title={t('component.videoplayer.body.fullscreen')}>
						<IconButton aria-label='fullscreen toggle' onClick={fullScreenMode}>
							<Fullscreen fontSize='medium' style={{ color: 'white' }} />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>
		</Box>
	);
};
