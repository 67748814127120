import * as React from 'react';

import { SwaggerApiClientType } from '../contexts/SwaggerApiContext/SwaggerApiClient';
import { SwaggerApiContext } from '../contexts/SwaggerApiContext/SwaggerApiContext';
import { Api } from '../api/Api';

export const useSwaggerApi = (): SwaggerApiClientType => {
	const swaggerApiClient = React.useContext(SwaggerApiContext).swaggerApi as SwaggerApiClientType;

	return swaggerApiClient;
};
