import { useCallback } from 'react';
import { ContentCopy as ContentCopyIcon, Help as HelpIcon } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../components/Heading/Heading';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Preloader } from '../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { ServiceName, Versions } from './types';
import { mapServiceName } from './utils';

export const About = () => {
	const { t } = useTranslation();

	const api = useSwaggerApi();

	const { data, isLoading } = useQuery({
		queryKey: [EQueryKey.VERSIONS_QUERY],
		queryFn: async () => {
			try {
				const { data } = await api.settings.getVersions();

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		select: (data) => {
			if (data) {
				return { ...data, dashboard: (process.env.REACT_APP_VERSION as string) ?? '' } as Versions;
			}
		},
	});

	const copyVersionToClipboard = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, version: string) => {
			event.preventDefault();

			copy(version);
			enqueueSnackbar(t('page.about.versionCopiedToClipboard'), {
				variant: 'success',
			});
		},
		[t],
	);

	return (
		<Box>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.about.title')}
						description={t('page.about.description')}
						icon={HelpIcon}
					/>
					{isLoading && <Preloader />}
					{!isLoading && data && (
						<Stack>
							<Heading label={t('page.about.applicationServices')} />
							<Grid container gap={1}>
								{Object.keys(data)
									.sort()
									.map((key) => {
										const version = data[key as keyof Versions];

										if (!version) {
											return null;
										}

										return (
											<Grid key={key} item xs={12}>
												<Grid container spacing={1} alignItems='center'>
													<Grid item xs={12} sm={4} md={3} lg={2} xl={1.5}>
														<Typography px={1} sx={{ fontWeight: 'bold' }}>
															{`${mapServiceName(key as ServiceName)}: `}
														</Typography>
													</Grid>
													<Grid item xs={12} sm>
														<Grid container alignItems='center'>
															<Grid item xs>
																<Typography px={1}>{version}</Typography>
															</Grid>
															<Grid item xs={6} sm={8} md={9} lg={10} xl={11}>
																<Tooltip
																	title={t('page.about.copyToClipboardTooltip')}
																	enterDelay={500}
																	arrow
																>
																	<IconButton
																		aria-label='copy'
																		size='small'
																		onClick={(event) =>
																			copyVersionToClipboard(
																				event,
																				`${key}: ${version}`,
																			)
																		}
																	>
																		<ContentCopyIcon />
																	</IconButton>
																</Tooltip>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										);
									})}
							</Grid>
						</Stack>
					)}
				</Stack>
			</Paper>
		</Box>
	);
};
