/**
 * Humanization configuration for Slovak language for time relative outputs.
 * Eg. form 'Tento link je neplatný už 1 rok 1 hodina, 2 minúty a 1 sekunda' to  'Tento link je neplatný už 1 rok 1 hodinu, 2 minúty a 1 sekundu.'
 */
export const slovakTimeRelativeViewHumanizationConfig = {
	y: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'rok';
		}
		if (n < 5) {
			return 'roky';
		}

		return 'rokov';
	},
	mo: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'mesiac';
		}
		if (n < 5) {
			return 'mesiace';
		}

		return 'mesiacov';
	},
	d: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'deň';
		}
		if (n < 5) {
			return 'dni';
		}

		return 'dní';
	},
	h: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'hodinu';
		}
		if (n < 5) {
			return 'hodiny';
		}

		return 'hodín';
	},
	m: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'minútu';
		}
		if (n < 5) {
			return 'minúty';
		}

		return 'minút';
	},
	s: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'sekundu';
		}
		if (n < 5) {
			return 'sekundy';
		}

		return 'sekúnd';
	},
	ms: (n: number | undefined) => {
		if (!n) {
			return '';
		}
		if (n === 1) {
			return 'milisekundu';
		}
		if (n < 5) {
			return 'milisekundy';
		}

		return 'milisekúnd';
	},
	decimal: ',',
};
