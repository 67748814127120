import * as React from 'react';
import { Paper, Box, Grid, Stack, FormGroup } from '@mui/material';
import { Dataset as DatasetIcon } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { Preloader } from '../../components/Preloader/Preloader';
import { DateTimeRangePicker } from '../../components/FormFields/DateTimeRangePicker/DateTimeRangePicker';
import { RadioButtonGroup } from '../../components/FormFields/RadioButtonGroup/RadioButtonGroup';
import { ERadioButtonGroupOrientation } from '../../components/FormFields/RadioButtonGroup/enums';
import { Rules } from './Components/Rules/Rules';
import { WeekdaySelector } from '../../components/FormFields/WeekdaySelector/WeekdaySelector';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { RuleSetState, FormValues } from './types';
import { getRequestData } from './helpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { AxiosError } from 'axios';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { ruleSet } from './schema';
import { useNavigate } from '../../hooks/useNavigate';

interface FieldsToReset {
	name: string;
	description: string;
	type: string;
	days: string[];
	['timeRange.start']: string | undefined;
	['timeRange.end']: string | undefined;
}

export const AddEditRuleSet: React.FC = (): JSX.Element => {
	const { id } = useParams<{ id: string | undefined }>();
	const { t } = useTranslation();
	const { isAllowed } = useACL();
	const swaggerApi = useSwaggerApi();
	const navigate = useNavigate();
	const [ruleSetState, setRuleSetState] = React.useState<RuleSetState>({
		loading: false,
		loaded: false,
		error: null,
		ruleSet: null,
	});

	const { handleSubmit, watch, register, setValue, control, reset, getValues, unregister } = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: {},
	});

	const getRuleSet = React.useCallback(
		async (id: number): Promise<void> => {
			setRuleSetState({
				...ruleSetState,
				loading: true,
			});
			try {
				const ruleSet = await swaggerApi.ruleSets.getRuleSet(id);
				setRuleSetState({
					...ruleSetState,
					loading: false,
					loaded: true,
					ruleSet: ruleSet.data,
				});
				const fieldsToReset: FieldsToReset = {
					name: ruleSet.data.name,
					description: ruleSet.data.description,
					days: [] as string[],
					type: 'type1',
					['timeRange.start']: undefined,
					['timeRange.end']: undefined,
				};

				if (ruleSet.data.timeRange && ruleSet.data.timeRange?.start && ruleSet.data.timeRange?.end) {
					fieldsToReset.type = 'type2';
					fieldsToReset['timeRange.start'] = ruleSet.data.timeRange.start;
					fieldsToReset['timeRange.end'] = ruleSet.data.timeRange.end;
				}
				if (ruleSet.data.days && ruleSet.data.days.length > 0) {
					fieldsToReset.type = 'type3';
					fieldsToReset.days = ruleSet.data.days;
				}
				reset(fieldsToReset);
			} catch (error) {
				setRuleSetState({
					...ruleSetState,
					loading: false,
					error: error as AxiosError,
				});
			}
		},
		[id],
	);

	React.useEffect(() => {
		if (id && !ruleSetState.loading && !ruleSetState.loaded && !ruleSetState.error) {
			getRuleSet(Number(id));
		}
	}, [id, ruleSetState]);

	const onSubmit = React.useCallback(
		async (values: Record<any, any>) => {
			if (!values.rules || values.rules.length === 0) {
				enqueueSnackbar(
					id ?
						t('page.ruleSet.form.errorMessage.updateFailedMissingRule')
					:	t('page.ruleSet.form.errorMessage.creationFailedMissingRule'),
					{
						variant: 'error',
					},
				);

				return;
			}
			const requestData = getRequestData(values);

			const isValid = ruleSet(t).safeParse(requestData);
			if (isValid.success === false) {
				enqueueSnackbar(isValid.error.errors.map((error) => error.message).join('\n'), {
					variant: 'error',
				});

				return;
			}

			try {
				if (id) {
					await swaggerApi.ruleSets.updateRuleSet(Number(id), requestData);
					enqueueSnackbar(t('page.ruleSet.form.actionMessages.ruleSetUpdated'), {
						variant: 'success',
					});
				} else {
					await swaggerApi.ruleSets.createRuleSet(requestData);
					enqueueSnackbar(t('page.ruleSet.form.actionMessages.ruleSetCreated'), {
						variant: 'success',
					});
				}
				navigate('/security/ruleSets');
			} catch (error) {
				console.error(error);
			}
		},
		[getValues, id],
	);

	const showTimeRange = watch('type') === 'type2';
	const showWeekDays = watch('type') === 'type3';

	const validateName = React.useCallback((value: string) => {
		if (!value || value.trim() === '') {
			return t('page.ruleSet.form.fields.name.error.required');
		}

		if (value.length > 64) {
			return t('page.ruleSet.form.fields.name.error.maxLength', { maxLength: 64 });
		}

		return true;
	}, []);

	const validateDescription = React.useCallback((value: string) => {
		if (!value || value.trim() === '') {
			return t('page.ruleSet.form.fields.description.error.required');
		}

		if (value.length > 255) {
			return t('page.ruleSet.form.fields.description.error.maxLength', { maxLength: 255 });
		}

		return true;
	}, []);

	const validateType = React.useCallback((value: string) => {
		if (!value || value.trim() === '') {
			return t('page.ruleSet.form.fields.type.error.required');
		}

		return true;
	}, []);

	const validateTimeRange = React.useCallback(
		(name: string) => (value: string) => {
			if (!value) {
				return t('page.ruleSet.form.fields.timeRange.error.required');
			}

			const isNotValidDate = isNaN(new Date(value).getTime());

			if (isNotValidDate) {
				return t('page.ruleSet.form.fields.timeRange.error.invalidFormat');
			}

			const endDate = watch('timeRange.end');

			if (name === 'startDate' && new Date(value) >= new Date(endDate)) {
				return t('page.ruleSet.form.fields.timeRange.error.invalid');
			}

			return true;
		},
		[watch],
	);

	const validateDays = React.useCallback((value: string[]) => {
		if (!value || value.length === 0) {
			return t('page.ruleSet.form.fields.days.error.required');
		}

		return true;
	}, []);

	React.useEffect(() => {
		if (showTimeRange) {
			register('timeRange');
		} else {
			unregister('timeRange');
		}
	}, [showTimeRange, register, unregister]);

	React.useEffect(() => {
		if (showWeekDays) {
			register('days');
		} else {
			unregister('days');
		}
	}, [showWeekDays, register, unregister]);

	React.useEffect(() => {
		if (!id) {
			setValue('type', 'type1');
		}
	}, [id]);

	return (
		<Box component={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
			{id && (ruleSetState.loading || !ruleSetState.loaded) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={id ? t('page.ruleSet.form.editTitle') : t('page.ruleSet.form.addTitle')}
							description={
								id ? t('page.ruleSet.form.editDescription') : t('page.ruleSet.form.addDescription')
							}
							icon={DatasetIcon}
						/>
					</Box>
					<Grid container>
						<Grid item xs={12} md={6}>
							<FormGroup
								sx={{
									padding: 2,
								}}
							>
								<Stack spacing={1}>
									<TextField
										name={'name'}
										control={control}
										rules={{ validate: validateName }}
										label={t('page.ruleSet.form.fields.name.label')}
										// disabled={shouldDisableForm}
										helperText={t('page.ruleSet.form.fields.name.helperText')}
									/>
									<Textarea
										name={'description'}
										control={control}
										rules={{ validate: validateDescription }}
										label={t('page.ruleSet.form.fields.description.label')}
										// disabled={shouldDisableForm}
										helperText={t('page.ruleSet.form.fields.description.helperText')}
										rows={4}
									/>
									<RadioButtonGroup
										name='type'
										control={control}
										rules={{ validate: validateType }}
										label={t('page.ruleSet.form.fields.type.label')}
										helperText={t('page.ruleSet.form.fields.type.helperText')}
										defaultValue='type1'
										orientation={ERadioButtonGroupOrientation.HORIZONTAL}
										options={[
											{ value: 'type1', label: t('page.ruleSet.form.fields.type.opt1') },
											{ value: 'type2', label: t('page.ruleSet.form.fields.type.opt2') },
											{ value: 'type3', label: t('page.ruleSet.form.fields.type.opt3') },
										]}
									/>
									{showTimeRange && (
										<DateTimeRangePicker
											name='timeRange'
											control={control}
											rulesStartDate={{ validate: validateTimeRange('startDate') }}
											rulesEndDate={{ validate: validateTimeRange('endDate') }}
											helperText={t('page.ruleSet.form.fields.timeRange.helperText')}
											disabled={false}
											label={t('page.ruleSet.form.fields.timeRange.label')}
										/>
									)}
									{showWeekDays && (
										<WeekdaySelector
											name='days'
											control={control}
											rules={{ validate: validateDays }}
											label={t('page.ruleSet.form.fields.days.label')}
											helperText={t('page.ruleSet.form.fields.days.helperText')}
										/>
									)}
								</Stack>
							</FormGroup>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} lg={8}>
							<FormGroup
								sx={{
									padding: 2,
								}}
							>
								<Stack spacing={1} sx={{ maxWidth: '100%' }}>
									<Rules control={control} watch={watch} ruleSet={ruleSetState.ruleSet} />
								</Stack>
							</FormGroup>
						</Grid>
					</Grid>
				</Paper>
			}
			{isAllowed([EPermission.SECURITY_POLICIES_CREATE, EPermission.SECURITY_POLICIES_UPDATE], false) && (
				<FloatingButtonSave
					type='submit'
					aria-label={t('page.ruleSet.form.ariaLabel.save')}
					tooltipTitle={t('page.ruleSet.form.tooltips.save')}
				/>
			)}
		</Box>
	);
};
