import React from 'react';
import { Typography, Box, Switch, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckBoxGroupID, CheckBoxGroupListProps, CheckBoxItemsGroup } from './types';
import CheckBoxGroup from './CheckBoxGroup';

const CheckBoxGroupList: React.FC<CheckBoxGroupListProps> = ({
	title,
	description,
	groups,
	setGroups,
	labelPlacement = 'end',
	controlType = 'checkbox',
	size = 'medium',
	itemTranslationBasePath = undefined,
	groupTranslationBasePath = undefined,
	showSelectedAllNumbers = false,
}) => {
	const { t } = useTranslation();
	const [expandedGroups, setExpandedGroups] = React.useState<Set<CheckBoxGroupID>>(
		new Set(), // All set are collapsed by default
	);
	const isAllExpanded = React.useMemo(() => expandedGroups.size === groups.size, [expandedGroups, groups]);

	const selectedItemsCount = React.useMemo(
		() =>
			Array.from(groups.values()).reduce(
				(acc, group) =>
					acc +
					Array.from(group.checkBoxItemMap.values()).reduce(
						(acc, item) => (item.isChecked ? acc + 1 : acc),
						0,
					),
				0,
			),
		[groups],
	);

	const totalItemsCount = React.useMemo(
		() =>
			Array.from(groups.values()).reduce(
				(acc, group) => acc + Array.from(group.checkBoxItemMap.values()).length,
				0,
			),
		[groups],
	);

	const setGroupExpanded = React.useCallback((id: CheckBoxGroupID) => {
		setExpandedGroups((prev) => {
			const newSet = new Set(prev);
			if (newSet.has(id)) {
				newSet.delete(id);
			} else {
				newSet.add(id);
			}

			return newSet;
		});
	}, []);

	const setAllExpanded = React.useCallback(
		(allExpanded: boolean) => {
			setExpandedGroups(() => {
				if (allExpanded) {
					return new Set(Array.from(groups.values()).map((group) => group.id));
				}

				return new Set();
			});
		},
		[groups, setExpandedGroups],
	);

	const handleCheckAll = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const allIsChecked = event.target.checked;
			const updatedState = new Map(groups);
			groups.forEach((group) => {
				const updatedGroup = new Map(group.checkBoxItemMap);
				updatedGroup.forEach((checkBox) => {
					updatedGroup.set(checkBox.id, {
						...checkBox,
						isChecked: allIsChecked,
					});
				});

				updatedState.set(group.id, {
					...group,
					checkBoxItemMap: updatedGroup,
				});
			});

			setGroups(updatedState);
		},
		[groups, setGroups],
	);

	const allChecked = React.useMemo(
		() =>
			Array.from(groups.values()).every((group) =>
				Array.from(group.checkBoxItemMap.values()).every((item) => item.isChecked),
			),
		[groups],
	);
	const indeterminate = React.useMemo(
		() =>
			Array.from(groups.values()).some((group) =>
				Array.from(group.checkBoxItemMap.values()).some((item) => item.isChecked),
			) && !allChecked,
		[groups, allChecked],
	);

	const renderCheckAllControl = React.useCallback(() => {
		const commonProps = {
			checked: allChecked,
			onChange: handleCheckAll,
			size: size === 'small' ? ('small' as const) : ('medium' as const),
		};

		switch (controlType) {
			case 'switch':
				return <Switch {...commonProps} />;
			case 'checkbox':
			default:
				return <Checkbox {...{ ...commonProps, indeterminate }} />;
		}
	}, [size, controlType, indeterminate, allChecked, handleCheckAll]);

	const renderGroup = React.useCallback(
		(group: CheckBoxItemsGroup) => {
			const expanded = expandedGroups.has(group.id);

			return (
				<CheckBoxGroup
					key={group.id}
					group={group}
					setGroup={(updatedGroup) => {
						setGroups(new Map(groups.set(updatedGroup.id, updatedGroup)));
					}}
					labelPlacement={labelPlacement}
					controlType={controlType}
					size={size}
					itemTranslationBasePath={itemTranslationBasePath}
					groupTranslationBasePath={groupTranslationBasePath}
					isExpanded={expanded}
					setIsExpanded={() => setGroupExpanded(group.id)}
					showSelectedAllNumbers={showSelectedAllNumbers}
				/>
			);
		},
		[
			expandedGroups,
			controlType,
			groups,
			itemTranslationBasePath,
			labelPlacement,
			size,
			setGroupExpanded,
			groupTranslationBasePath,
			setGroups,
			showSelectedAllNumbers,
		],
	);

	const handleCheckAllClick = (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
		event.stopPropagation();
	};

	React.useEffect(() => {
		if (!isAllExpanded) {
			setAllExpanded(true);
		}
	}, [groups.size]);

	return (
		<Box>
			<Box display='flex' flexDirection='column' width='100%' paddingBottom='16px'>
				<Box display='flex' justifyContent='space-between' alignItems='center' justifyItems='center'>
					<Typography variant='h6'>{title}</Typography>
				</Box>
				<Box
					display='flex'
					flexDirection='row'
					width='100%'
					alignContent='center'
					alignItems='center'
					justifyContent='space-between'
					paddingRight='16px'
				>
					<Typography>{description}</Typography>
					<FormControlLabel
						onClick={handleCheckAllClick}
						control={renderCheckAllControl()}
						label={`${t('component.checkBoxGroupList.toggleAll')}  ${showSelectedAllNumbers ? ` (${selectedItemsCount}/${totalItemsCount})` : ''}`}
						labelPlacement={labelPlacement}
						slotProps={{ typography: { variant: 'body2' } }}
					/>
				</Box>
			</Box>
			<Box display='flex' flexDirection='column' gap='8px'>
				{Array.from(groups.values()).map(renderGroup)}
			</Box>
		</Box>
	);
};

export default CheckBoxGroupList;
