import * as React from 'react';
import { Box, Typography, Accordion, AccordionSummary, Grid, AccordionDetails, Stack, Paper } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RuleSetPreviewProps, TimeRange } from './types';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { WeekdayPreview } from '../WeekdayPreview/WeekdayPreview';
import { TimePreview } from '../RulesPreview/TimePreview';
import { FactorPreview } from '../RulesPreview/FactorPreview';
import { GeofencePreview } from '../RulesPreview/GeofencePreview';
import { PhoneConnectionPreview } from '../RulesPreview/PhoneConnectionPreview';

export const RuleSetPreview: React.FC<RuleSetPreviewProps> = ({ data, expanded, onToggle }): JSX.Element => {
	const { t } = useTranslation();
	const formatDate = useFormatDate();

	const rules = React.useMemo(
		() =>
			[
				data.timeRanges.length > 0 && <TimePreview data={data} />,
				data.factors.length > 0 && <FactorPreview data={data} />,
				(data.geofences.length > 0 || data.geofenceGroups.length > 0) && <GeofencePreview data={data} />,
				(data.ips.length > 0 ||
					data.phoneConnectionIPs.length > 0 ||
					data.phoneConnectionStatuses.length > 0) && <PhoneConnectionPreview data={data} />,
			].filter(Boolean),
		[data],
	);

	const getValidPeriod = React.useCallback(
		(timeRange: TimeRange | undefined, days: string[]) => {
			if (!timeRange && days.length === 0) {
				return <Typography px={1}>{t('page.securityPolicy.detail.body.always')}</Typography>;
			} else if (timeRange && days.length === 0) {
				return (
					<Typography px={1}>{`${formatDate(timeRange.start)} - ${formatDate(timeRange.end)}`}</Typography>
				);
			} else if (!timeRange && days.length > 0) {
				return (
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
						<Typography px={1}>{t('page.securityPolicy.detail.body.recurring')}</Typography>
						<WeekdayPreview value={days} />
					</Box>
				);
			}

			return <Typography px={1}>{t('page.securityPolicy.detail.body.notSpecified')}</Typography>;
		},
		[t, formatDate],
	);

	const validPeriod = React.useMemo(
		() => getValidPeriod(data.timeRange, data.days),
		[getValidPeriod, data.timeRange, data.days],
	);

	const handleOnToggleOnExpanded = React.useCallback(
		(event: React.SyntheticEvent<Element, Event>, isExpanded: boolean) => {
			onToggle(data.id, isExpanded);
		},
		[data.id],
	);

	return (
		<Box sx={{ flex: 1, width: '100%' }}>
			<Accordion expanded={expanded} defaultExpanded={true} onChange={handleOnToggleOnExpanded} elevation={3}>
				<AccordionSummary
					id={`rule-set-preview-${data.id}`}
					expandIcon={<ExpandMoreIcon />}
					aria-controls={`rule-set-preview-${data.id}`}
				>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={3}>
								<Typography px={1} sx={{ fontWeight: 'bold' }}>
									{`${t('page.securityPolicy.detail.body.name')}:`}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={9}>
								<Typography px={1}>{data.name}</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography px={1} sx={{ fontWeight: 'bold' }}>
									{`${t('page.securityPolicy.detail.body.description')}:`}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={9}>
								<Typography px={1}>{data.description}</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<Typography px={1} sx={{ fontWeight: 'bold' }}>
									{`${t('page.securityPolicy.detail.body.validPeriod')}:`}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={9}>
								{validPeriod}
							</Grid>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<Stack spacing={3}>
						<Typography px={1} sx={{ fontWeight: 'bold' }}>
							{`${t('page.securityPolicy.detail.body.rules')}:`}
						</Typography>
						<Stack spacing={3}>
							{rules.map((rule, index) => (
								<Stack key={`rule-preview-${index}`} spacing={3}>
									{index > 0 && (
										<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
											<Paper elevation={3} sx={{ padding: 2 }}>
												<Typography>{t('page.ruleSet.form.body.and')}</Typography>
											</Paper>
										</Box>
									)}
									{rule}
								</Stack>
							))}
						</Stack>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};
