import * as React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { PageHeaderProps } from './types';
import { SEO } from '../SEO/SEO';

export const PageHeader: React.FC<PageHeaderProps> = ({ title, description, icon: Icon }): JSX.Element => {
	return (
		<Box>
			<Stack spacing={1}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
					{Icon && <Icon fontSize='medium' />}
					<Typography variant='h5'>{title}</Typography>
				</Box>
				{description && <Typography>{description}</Typography>}
				<SEO title={title} description={description} />
			</Stack>
			<Divider sx={{ paddingTop: 2 }} />
		</Box>
	);
};
