import * as React from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { enqueueSnackbar } from 'notistack';

import { useAuthContext } from '../AuthContext/AuthContext';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { validateGoogleApiKey } from '../../utils/GoogleMap';

import {
	GeofenceProviderProps,
	GeofenceContextModel,
	GeofenceContextState,
	GoogleApiKeyState,
	GoogleScriptProps,
} from './types';
import { INITIAL_MAP_ZOOM, LIBRARIES, DEFAULT_CENTER, GOOGLE_MAPS_IDENTIFIER } from './constants';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { EPermission } from '../../enums/permission/EPermission';

const InitialGeofenceContextModel: GeofenceContextModel = {
	initialMapZoom: INITIAL_MAP_ZOOM,
	libraries: LIBRARIES,
	defaultMapCenter: DEFAULT_CENTER,
	googleMapsApiKey: '',
	googleApiLoaded: false,
	enabledPlacesApi: false,
	googleApiKeyValid: false,
	useDefaultApiKey: false,
	checkCurrentLocation: () => {},
	handleOnLoadGoogleApiKey: () => Promise.resolve(),
};

export const GeofenceContext = React.createContext<GeofenceContextModel>(InitialGeofenceContextModel);

const GoogleScript: React.FC<GoogleScriptProps> = ({
	googleMapsApiKey,
	libraries,
	language,
	setGeofencesContextState,
}) => {
	const { t } = useTranslation();
	const { isLoaded, loadError } = useLoadScript({
		id: GOOGLE_MAPS_IDENTIFIER,
		googleMapsApiKey,
		language,
		libraries,
	});

	React.useEffect(() => {
		if (loadError) {
			enqueueSnackbar(t('component.geolocation.errorMessages.googleApiInitError'), {
				variant: 'error',
				persist: false,
			});
		}
		if (isLoaded && !loadError) {
			setGeofencesContextState((prevState) => ({
				...prevState,
				googleApiLoaded: true,
			}));
		}
	}, [isLoaded, loadError]);

	return null;
};

export const GeofenceContextProvider: React.FC<GeofenceProviderProps> = ({ children }): JSX.Element => {
	const swaggerApi = useSwaggerApi();
	const authContext = useAuthContext();
	const hasMapPermissions = React.useMemo(() => {
		return authContext.permissions.permissions.find((permission) => permission === EPermission.SETTINGS_MAP_READ);
	}, [authContext]);
	const { t } = useTranslation();
	const previousIsAuthenticated = usePreviousValue(authContext.user.isAuthenticated);
	const [googleApiKeyState, setGoogleApiKeyState] = React.useState<GoogleApiKeyState>({
		loading: false,
		loaded: false,
		error: null,
	});
	const [geofencesContextState, setGeofencesContextState] = React.useState<GeofenceContextState>({
		initialMapZoom: InitialGeofenceContextModel.initialMapZoom,
		libraries: InitialGeofenceContextModel.libraries,
		defaultMapCenter: InitialGeofenceContextModel.defaultMapCenter,
		googleMapsApiKey: InitialGeofenceContextModel.googleMapsApiKey,
		googleApiLoaded: InitialGeofenceContextModel.googleApiLoaded,
		enabledPlacesApi: InitialGeofenceContextModel.enabledPlacesApi,
		googleApiKeyValid: InitialGeofenceContextModel.googleApiKeyValid,
		useDefaultApiKey: InitialGeofenceContextModel.useDefaultApiKey,
	});

	const handleOnLoadGoogleApiKey = React.useCallback(async () => {
		if (!authContext.user.isAuthenticated || googleApiKeyState.loading || !hasMapPermissions) {
			return;
		}
		setGoogleApiKeyState({
			loading: true,
			loaded: false,
			error: null,
		});
		try {
			const systemProperties = await swaggerApi.settings.getMapSettingsProperties();
			const isApiKeyValid = await validateGoogleApiKey(systemProperties.data.googleApiKey as string);
			const googleElement = document.getElementById(GOOGLE_MAPS_IDENTIFIER);
			if (googleElement) {
				googleElement.remove();
			}
			setGeofencesContextState((prevState) => ({
				...prevState,
				googleMapsApiKey:
					systemProperties.data.googleApiKey ?
						systemProperties.data.googleApiKey
					:	systemProperties.data.googleMapsdefaultApiKey,
				enabledPlacesApi:
					systemProperties.data.enabledPlacesApi ? systemProperties.data.enabledPlacesApi : false,
				googleApiKeyValid: isApiKeyValid,
				useDefaultApiKey: !systemProperties.data.googleApiKey,
			}));
			setGoogleApiKeyState({
				loading: false,
				loaded: true,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setGoogleApiKeyState({
				loading: false,
				loaded: true,
				error: error as AxiosError,
			});
		}
	}, [authContext, googleApiKeyState, hasMapPermissions]);

	const checkCurrentLocation = React.useCallback(() => {
		if (!('geolocation' in navigator)) {
			enqueueSnackbar(t('component.geolocation.infoMessages.notAvailable'));

			return;
		}
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const { latitude, longitude } = position.coords;
				const newCenter = { lat: latitude, lng: longitude };
				setGeofencesContextState((prevState) => ({
					...prevState,
					defaultMapCenter: newCenter,
				}));
			},
			() => {
				enqueueSnackbar(t('component.geolocation.errorMessages.currentLocationError'), {
					variant: 'info',
					persist: false,
				});
			},
		);
	}, []);

	React.useEffect(() => {
		if (authContext.user.isAuthenticated && !previousIsAuthenticated) {
			handleOnLoadGoogleApiKey();
		}
	}, [authContext.user.isAuthenticated, previousIsAuthenticated]);

	const geofenceContextModel: GeofenceContextModel = {
		initialMapZoom: geofencesContextState.initialMapZoom,
		libraries: geofencesContextState.libraries,
		defaultMapCenter: geofencesContextState.defaultMapCenter,
		googleMapsApiKey: geofencesContextState.googleMapsApiKey,
		googleApiLoaded: geofencesContextState.googleApiLoaded,
		enabledPlacesApi: geofencesContextState.enabledPlacesApi,
		googleApiKeyValid: geofencesContextState.googleApiKeyValid,
		useDefaultApiKey: geofencesContextState.useDefaultApiKey,
		checkCurrentLocation,
		handleOnLoadGoogleApiKey,
	};

	return (
		<GeofenceContext.Provider value={geofenceContextModel}>
			{googleApiKeyState.loaded && geofencesContextState.googleMapsApiKey !== '' && (
				<GoogleScript
					googleMapsApiKey={geofencesContextState.googleMapsApiKey}
					libraries={geofencesContextState.libraries}
					language={'en'}
					setGeofencesContextState={setGeofencesContextState}
				/>
			)}
			{children}
		</GeofenceContext.Provider>
	);
};
