import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, FormGroup, Typography, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getSMTPSetupSchema } from '../schemas';
import { SMTPSetupProps, SubmitSmtpState, SMTPFormState, SMTPFormValues } from '../types';
import { ESetupSteps } from '../enums';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { Heading } from '../../../components/Heading/Heading';
import { resolveEMailSMTPSecurity } from '../../../enums/mail/EMailSMTPSecurity';
import { SMTPSecurity } from '../../../components/SMTPSecurity/SMTPSecurity';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { EFieldType } from '../../../components/FormFields/TextField/enums';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { SEO } from '../../../components/SEO/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { EMailSMTPSecurity } from '../../../api/Api';

export const SMTPSetup: React.FC<SMTPSetupProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const { setIndexPage, getCurrentStep } = props;
	const [submitSmtpState, setSubmitSMTPState] = React.useState<SubmitSmtpState>({
		loading: false,
		loaded: false,
		error: null,
	});
	const DEFAULT_PORT_VALUE = '587';
	const DEFAULT_SECURITY_VALUE = 'starttls';
	const [security, setSecurity] = React.useState<string>(DEFAULT_SECURITY_VALUE);
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<SMTPFormState>({
		mode: 'onChange',
		resolver: zodResolver(getSMTPSetupSchema(t)),
		defaultValues: {
			port: DEFAULT_PORT_VALUE,
		},
	});

	const handleOnBack = React.useCallback(async () => {
		if (submitSmtpState.loading) {
			return;
		}

		setSubmitSMTPState({
			loading: true,
			loaded: false,
			error: null,
		});

		try {
			const response = await api.setup.setBackSetupProgress();
			const step = response.data;

			setSubmitSMTPState({
				loading: false,
				loaded: true,
				error: null,
			});

			setIndexPage(getCurrentStep(step));
		} catch (error) {
			setSubmitSMTPState({
				loading: false,
				loaded: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	const handleOnSubmit = React.useCallback(
		async (formValues: SMTPFormValues) => {
			if (submitSmtpState.loading) {
				return;
			}
			setSubmitSMTPState({
				loading: true,
				loaded: false,
				error: null,
			});

			try {
				await api.setup.createSmtpConfiguration({
					name: formValues.name,
					description: formValues.description,
					host: formValues.host,
					port: Number(formValues.port),
					username: formValues.userName,
					password: formValues.password,
					sender: formValues.sender,
					email: formValues.email,
					security: resolveEMailSMTPSecurity(security),
				});
				setSubmitSMTPState({
					loading: false,
					loaded: true,
					error: null,
				});
				enqueueSnackbar(t('page.setupWizard.actionMessages.smtpSuccessfullyCreated'), {
					variant: 'success',
					persist: false,
				});

				setIndexPage(getCurrentStep(ESetupSteps.SMTP_VERIFY));
			} catch (error) {
				setSubmitSMTPState({
					loading: false,
					loaded: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[submitSmtpState, security],
	);

	const loadUpProps = React.useCallback(async () => {
		setSubmitSMTPState({
			loading: true,
			loaded: false,
			error: null,
		});

		try {
			const response = await api.setup.getSmtpConfiguration();
			const smtpConfigurationData = response.data;
			if (smtpConfigurationData) {
				const smtpFormStateData: SMTPFormState = {
					name: smtpConfigurationData.name ?? '',
					description: smtpConfigurationData.description ?? '',
					host: smtpConfigurationData.host ?? '',
					port: String(smtpConfigurationData.port || DEFAULT_PORT_VALUE),
					userName: smtpConfigurationData.username ?? '',
					sender: smtpConfigurationData.sender ?? '',
					email: smtpConfigurationData.verificationEmail ?? '',
					emailAgain: smtpConfigurationData.verificationEmail ?? '',
					password: '',
					security: smtpConfigurationData.security ?? DEFAULT_SECURITY_VALUE,
				};

				reset(smtpFormStateData);
			}

			setSubmitSMTPState({
				loading: false,
				loaded: true,
				error: null,
			});
		} catch (error) {
			setSubmitSMTPState({
				loading: false,
				loaded: true,
				error: error as AxiosError,
			});

			console.error(error);
		}
	}, [security]);

	React.useEffect(() => {
		loadUpProps();
	}, []);

	return (
		<Box>
			<SEO
				title={t('page.setupWizard.texts.SMTP.title')}
				description={t('page.setupWizard.texts.SMTP.description')}
			/>
			<Stack direction='column' spacing={2}>
				<Typography align='center'>{t('page.setupWizard.texts.SMTP.body.description.first')}</Typography>
				<Typography align='center'>{t('page.setupWizard.texts.SMTP.body.description.second')}</Typography>
			</Stack>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<Heading label={t('page.setupWizard.texts.SMTP.general')} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'name'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.name.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.name}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.name.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Textarea
							name={'description'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.description.label')}
							error={errors.description}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.description.helperText')}
							rows={6}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name={'sender'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.sender.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.sender}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.sender.helperText')}
							type={EFieldType.EMAIL}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<Heading label={t('page.setupWizard.texts.SMTP.network')} />
				<Grid container spacing={2}>
					<Grid item xs={8}>
						<TextField
							name={'host'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.host.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.host}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.host.helperText')}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextField
							name={'port'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.port.label')}
							error={errors.port}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.port.helperText')}
							type={EFieldType.NUMBER}
						/>
					</Grid>
					<Grid item xs={12}>
						<SMTPSecurity
							register={register}
							errors={errors}
							setSecurity={setSecurity}
							security={resolveEMailSMTPSecurity(security)}
							disabled={submitSmtpState.loading}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<Heading label={t('page.setupWizard.texts.SMTP.authentication')} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'userName'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.userName.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.userName}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.userName.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<PasswordField
							name={'password'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.password.label')}
							error={errors.password}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.password.helperText')}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<Heading label={t('page.setupWizard.texts.SMTP.verification')} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'email'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.email.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.email}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.email.helperText')}
							type={EFieldType.EMAIL}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name={'emailAgain'}
							register={register}
							label={t('page.setupWizard.texts.SMTP.form.emailAgain.label')}
							InputLabelProps={{ shrink: true }}
							error={errors.emailAgain}
							disabled={submitSmtpState.loading}
							helperText={t('page.setupWizard.texts.SMTP.form.emailAgain.helperText')}
							type={EFieldType.EMAIL}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Button fullWidth={device !== 'desktop'} variant='outlined' type='submit' onClick={handleOnBack}>
					{t('page.setupWizard.texts.SMTP.back')}
				</Button>
				<Button
					variant='contained'
					type='submit'
					onClick={handleSubmit(handleOnSubmit)}
					fullWidth={device !== 'desktop'}
				>
					{t('page.setupWizard.texts.SMTP.next')}
				</Button>
			</Grid>
		</Box>
	);
};
