import * as React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RulesPreviewProps } from './types';
import { LabeledBox } from '../../../../components/LabeledBox/LabeledBox';
import { ChipArray } from '../../../../components/ChipArray/ChipArray';

export const GeofencePreview: React.FC<RulesPreviewProps> = ({ data }): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexWrap: { xs: 'wrap', md: 'nowrap' },
				width: '100%',
				gap: 2,
				p: 2,
			}}
		>
			<LabeledBox label={t('page.securityPolicy.detail.rules.body.rule')} sx={{ flex: '0 0 180px' }}>
				<Typography>{t('page.securityPolicy.detail.rules.title.geofence')}</Typography>
			</LabeledBox>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				{data.geofences.map((geofenceList, index) => (
					<Box
						key={`geofence-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{index > 0 && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, width: '100%' }}>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.geofence')}
								sx={{ width: '100%' }}
							>
								<ChipArray chipList={geofenceList} wrap />
							</LabeledBox>
						</Box>
					</Box>
				))}
				{data.geofenceGroups.map((geofenceGroupList, index) => (
					<Box
						key={`geofence-groups-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{(index > 0 || data.geofences.length > 0) && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, width: '100%' }}>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.geofenceGroups')}
								sx={{ width: '100%' }}
							>
								<ChipArray chipList={geofenceGroupList} wrap />
							</LabeledBox>
						</Box>
					</Box>
				))}
			</Box>
		</Paper>
	);
};
