import { z } from 'zod';

import { EPAMTargetType, ERDPSecurityMode } from '../../api/Api';

export const userInputListSchema = z.object({
	id: z.number(),
	targetType: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string(),
	accountName: z.string(),
	startAt: z.string(),
	endAt: z.string().nullable(),
	userInput: z.array(
		z.object({
			sessionID: z.number(),
			line: z.string(),
			startAt: z.string(),
			endAt: z.string(),
		}),
	),
});

export const pamTargetListSchema = z.object({
	id: z.number(),
	type: z.string(),
	name: z.string(),
	description: z.string().optional(),
	hostname: z.string(),
	authorizationRequired: z.boolean(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
			})
			.optional(),
	),
});

export const sessionListSchema = z.object({
	id: z.number(),
	targetType: z.string(),
	targetName: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string().optional(),
	accountName: z.string(),
	startAt: z.string(),
	endAt: z.string().optional(),
});

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	status: z.string(),
	email: z
		.string()
		.min(1)
		.max(64)
		.refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
});

export const auditLogListSchema = z.object({
	clipboardEnableCopy: z.boolean().nullable().optional(),
	clipboardEnablePaste: z.boolean().nullable().optional(),
	description: z.string().nullable().optional(),
	domain: z.string().nullable().optional(),
	fileDownloadEnabled: z.boolean().nullable().optional(),
	fileTransferRootDirectory: z.string().nullable().optional(),
	fileUploadEnabled: z.boolean().nullable().optional(),
	fileUploadSizeLimit: z.number().nullable().optional(),
	hostname: z.string().nullable().optional(),
	ignoreCertificate: z.boolean().nullable().optional(),
	modifiedAt: z.string(),
	modifiedBy: z.string().nullable().optional(),
	name: z.string().nullable().optional(),
	passphrase: z.string().nullable().optional(),
	password: z.string().nullable().optional(),
	port: z.number().nullable().optional(),
	privateKey: z.string().nullable().optional(),
	recordingEnabled: z.boolean().nullable().optional(),
	recordingRetentionPeriod: z.number().nullable().optional(),
	remoteApplicationName: z.string().nullable().optional(),
	remoteApplicationWorkingDir: z.string().nullable().optional(),
	remoteApplicationCommandLineArgs: z.string().nullable().optional(),
	revID: z.number(),
	revType: z.number(),
	securityMode: z.nativeEnum(ERDPSecurityMode).nullable().optional(),
	targetID: z.number(),
	tenantID: z.number().nullable().optional(),
	type: z.nativeEnum(EPAMTargetType).nullable().optional(),
	username: z.string().nullable().optional(),
});
