import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DetailSectionProps, FieldProps } from './types';
import { LabeledBox } from '../LabeledBox/LabeledBox';

const Field: React.FC<FieldProps> = ({ field }): JSX.Element | null => {
	return field ?
			<Grid item xs={12}>
				<Grid container spacing={1} alignItems='center'>
					<Grid item xs={12} sm={4}>
						<Typography px={1} sx={{ fontWeight: 'bold' }}>
							{field.label}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={8}>
						{['string', 'number'].includes(typeof field.value) ?
							<Typography>{field.value ? field.value : 'N/A'}</Typography>
						:	field.value}
					</Grid>
				</Grid>
			</Grid>
		:	null;
};

export const DetailSection: React.FC<DetailSectionProps> = ({ sections, responsive = false }): JSX.Element => {
	return (
		<Box>
			<Grid container spacing={3}>
				{sections.map(
					(section, index) =>
						section.shouldRender !== false && (
							<Grid item xs={12} xl={responsive ? 6 : 12} key={`${index}-section`}>
								<LabeledBox label={section.title} sx={{ padding: 2 }}>
									<Grid container gap={1} padding={1}>
										{section.fields.map((field, index) => (
											<Field key={`${index}-field`} field={field} />
										))}
									</Grid>
								</LabeledBox>
							</Grid>
						),
				)}
			</Grid>
		</Box>
	);
};
