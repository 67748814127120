import { ContentCopy as ContentCopyIcon, Token as TokenIcon } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Heading } from '../../components/Heading/Heading';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Preloader } from '../../components/Preloader/Preloader';
import { useFetchOAuthClient } from '../../hooks/query/oAuthClients/useFetchOAuthClient';
import { formatDateTime, getDurationInString } from '../../utils/DateTime';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { WebhookList } from './webhooks/WebhookList';
import { FloatingButtonEdit } from '../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';

export const OAuthClientDetail: React.FC = () => {
	const { id: oAuthClientID } = useParams();
	const { isAllowed } = useACL();

	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const { t } = useTranslation();

	const { data: oAuthClient, isLoading: isLoadingOAuthClient } = useFetchOAuthClient(oAuthClientID ?? '');

	const copyClientIDToClipboard = () => {
		if (!oAuthClient || !oAuthClient.clientID) {
			return;
		}

		copy(oAuthClient.clientID);
		enqueueSnackbar(t('page.oAuthClients.detail.copyToClipboard'), {
			variant: 'success',
		});
	};

	return (
		<>
			{isLoadingOAuthClient && <Preloader />}
			{!isLoadingOAuthClient && oAuthClient && (
				<Box sx={{ marginBottom: 10 }}>
					<Paper elevation={3}>
						<Stack
							spacing={2}
							sx={{
								padding: 2,
							}}
						>
							<PageHeader
								title={t('page.oAuthClients.detail.title')}
								description={t('page.oAuthClients.detail.description')}
								icon={TokenIcon}
							/>
							<Stack sx={{ width: matchesLG ? '100%' : '50%' }}>
								<Heading label={t('page.oAuthClients.detail.subtitle.general')} />
								<Grid container gap={1}>
									<Grid container spacing={1} alignItems='center'>
										<Grid item xs={12} sm={4}>
											<Typography px={1} sx={{ fontWeight: 'bold' }}>
												{`${t('page.oAuthClients.detail.body.clientID')}: `}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Stack direction='row' alignItems='center'>
												<Typography px={2}>{oAuthClient.clientID}</Typography>
												{isAllowed([EPermission.OAUTH_CLIENTS_READ]) && (
													<Tooltip
														title={t('page.oAuthClients.detail.tooltips.copyToClipboard')}
														enterDelay={500}
														arrow
													>
														<IconButton
															aria-label='copy'
															size='small'
															onClick={copyClientIDToClipboard}
														>
															<ContentCopyIcon />
														</IconButton>
													</Tooltip>
												)}
											</Stack>
										</Grid>
									</Grid>
									<Grid container spacing={1}>
										<Grid item xs={12} sm={4}>
											<Typography px={1} sx={{ fontWeight: 'bold' }}>
												{`${t('page.oAuthClients.detail.body.name')}: `}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8}>
											<Typography px={2}>{oAuthClient.name}</Typography>
										</Grid>
									</Grid>
									{oAuthClient.description && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.oAuthClients.detail.body.description')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>{oAuthClient.description}</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{oAuthClient.createdAt && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.oAuthClients.detail.body.createdAt')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>
														{formatDateTime(oAuthClient.createdAt, true)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
									{oAuthClient.accessTokenValidity && (
										<Grid item xs={12}>
											<Grid container spacing={1}>
												<Grid item xs={12} sm={4}>
													<Typography px={1} sx={{ fontWeight: 'bold' }}>
														{`${t('page.oAuthClients.detail.body.accessTokenValidity')}: `}
													</Typography>
												</Grid>
												<Grid item xs={12} sm={8}>
													<Typography px={2}>
														{getDurationInString(oAuthClient.accessTokenValidity)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Stack>

							<Stack spacing={2}>
								<SectionHeader
									title={t('page.oAuthClients.detail.subtitle.webhooks.title')}
									description={t('page.oAuthClients.detail.subtitle.webhooks.description')}
								/>
								<WebhookList clientID={oAuthClientID} />
							</Stack>
						</Stack>
					</Paper>

					{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
						<Link to={`/security/oauth/edit/${oAuthClientID}`}>
							<FloatingButtonEdit
								ariaLabel={t('page.oAuthClients.detail.ariaLabel.edit')}
								tooltipTitle={t('page.oAuthClients.detail.tooltips.edit')}
							/>
						</Link>
					)}
					{isAllowed([EPermission.OAUTH_CLIENTS_CREATE, EPermission.OAUTH_CLIENTS_UPDATE], false) && (
						<Link to={`/security/oauth/${oAuthClientID}/new`}>
							<FloatingButtonAdd
								ariaLabel={t('page.oAuthClients.detail.ariaLabel.addWebhook')}
								tooltipTitle={t('page.oAuthClients.detail.tooltips.addWebhook')}
								right='100px'
							/>
						</Link>
					)}
				</Box>
			)}
		</>
	);
};
