import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { Typography, Tooltip, Box, IconButton, Paper, Stack } from '@mui/material';
import {
	SaveAlt as SaveAltIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Info as InfoIcon,
	Workspaces as WorkspacesIcon,
	MapOutlined,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useNavigate } from '../../../hooks/useNavigate';
import { Link } from '../../../components/Link/Link';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { EConfirmDialogState } from '../../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';
import { geofenceGroupListSchema } from './schema';
import { ChipArray } from '../../../components/ChipArray/ChipArray';
import {
	PagedResultGetGeofenceGroupDetailByIDResponseDto,
	GetGeofenceGroupDetailByIDResponseDto,
} from '../../../api/Api';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { useTableQuery } from '../../../hooks/useTableQuery';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';

export const GeofenceGroupList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		userTenants: { activeTenantID },
	} = useAuthContext();
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const [geofenceGroupIDToDelete, setGeofenceGroupIDToDelete] = React.useState<number | null>(null);
	const [multiGeofenceGroupIDToDelete, setMultiGeofenceGroupIDToDelete] = React.useState<{ ids: number[] }>({
		ids: [],
	});
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name']);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultGetGeofenceGroupDetailByIDResponseDto>({
		queryKey: [EQueryKey.GEOFENCE_GROUP_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					filterList: swaggerQuery.filter,
					sortList: swaggerQuery.sort,
					columnsList: [...swaggerQuery.columns, 'tenantID'], // tenantID is not shown but is used in shouldRenderActionButtons function
					offset: swaggerQuery.offset,
					limit: swaggerQuery.limit,
				};

				const response = await api.geofenceGroups.getDetailedGeofenceGroupCollection(query);
				response.data.entities.forEach((group) => {
					geofenceGroupListSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.geofenceGroup.list.confirmation.delete.title'));
				setConfirmationText(t('page.geofenceGroup.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.geofenceGroup.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.geofenceGroup.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenGroupDeleteDialog = React.useCallback(
		(geofenceGroupID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setGeofenceGroupIDToDelete(geofenceGroupID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnOpenMultipleGroupsDelete = React.useCallback(
		(table: MRTTableInstance<Partial<GetGeofenceGroupDetailByIDResponseDto>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.geofenceGroup.list.errorMessages.noGroupSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiGeofenceGroupIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, enqueueSnackbar, t, handleOnChangeConfirmDialog, rowSelection],
	);

	const handleOnConfirmGroupDelete = React.useCallback(async () => {
		if (geofenceGroupIDToDelete) {
			try {
				await api.geofenceGroups.deleteGeofenceGroup(geofenceGroupIDToDelete);
				enqueueSnackbar(t('page.geofenceGroup.list.actionMessages.groupSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setGeofenceGroupIDToDelete(null);
				updateRowSelection([geofenceGroupIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiGeofenceGroupIDToDelete.ids.length > 0) {
			try {
				await api.geofenceGroups.deleteGeofenceGroups(multiGeofenceGroupIDToDelete);
				enqueueSnackbar(t('page.geofenceGroup.list.actionMessages.groupsSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});

				reactQueryClient.invalidateQueries();
				setOpen(false);
				setMultiGeofenceGroupIDToDelete({ ids: [] });
				updateRowSelection(multiGeofenceGroupIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}
	}, [
		isAllowed,
		enqueueSnackbar,
		t,
		geofenceGroupIDToDelete,
		multiGeofenceGroupIDToDelete,
		api,
		reactQueryClient,
		handleOnChangeConfirmDialog,
		isAllowed,
	]);

	const handleClose = React.useCallback(() => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<GetGeofenceGroupDetailByIDResponseDto>>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.geofenceGroup.list.table.header.name'),
				grow: 1,
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							minHeight: '40px',
						}}
					>
						<MapOutlined sx={{ width: 30, height: 30 }} />
						<span>{renderedCellValue}</span>
						{row.original.description && (
							<Tooltip title={row.original.description} placement='right' enterDelay={500} arrow>
								<InfoIcon color='info' />
							</Tooltip>
						)}
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.geofences}`,
				header: t('page.geofenceGroup.list.table.header.geofences'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				grow: 3,
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<ChipArray wrap chipList={row.original.geofences} limitTags={3} />
					</Box>
				),
			},
		],
		[isAllowed, activeTenantID],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper
				elevation={3}
				sx={{
					paddingBottom: 2,
				}}
			>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.geofenceGroup.list.title')}
						description={t('page.geofenceGroup.list.description')}
						icon={WorkspacesIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						enableStickyHeader={false}
						state={{
							isLoading: isLoading,
							showAlertBanner: error !== null,
							pagination,
							rowSelection,
							showProgressBars: isRefetching,
							columnFilters,
							globalFilter,
							sorting,
							columnVisibility,
						}}
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						rowCount={total}
						manualPagination
						manualFiltering
						manualSorting
						onPaginationChange={setPagination}
						enableRowActions={isAllowed([EPermission.GEOFENCE_GROUPS_DELETE])}
						enableRowSelection={(row) => {
							const shouldEnableRowSelection = () => {
								if (!isAllowed([EPermission.GEOFENCE_GROUPS_DELETE])) {
									return false;
								}
								if (activeTenantID === null) {
									return true;
								} else if (row.original?.tenantID === activeTenantID) {
									return true;
								}

								return false;
							};

							return shouldEnableRowSelection();
						}}
						onRowSelectionChange={setRowSelection}
						onSortingChange={setSorting}
						onGlobalFilterChange={setGlobalFilter}
						onColumnFiltersChange={setColumnFilters}
						onColumnVisibilityChange={setColumnVisibility}
						getRowId={(originalRow) => originalRow.id?.toString() || ''}
						renderRowActions={({ row }) => {
							const shouldRenderActionButtons = () => {
								if (activeTenantID === null) {
									return true;
								} else if (row.original?.tenantID === activeTenantID) {
									return true;
								}

								return false;
							};

							return (
								<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
									{shouldRenderActionButtons() && isAllowed([EPermission.GEOFENCE_GROUPS_UPDATE]) && (
										<Tooltip
											title={t('page.geofenceGroup.list.tooltips.edit')}
											placement='left'
											enterDelay={500}
											arrow
										>
											<IconButton
												onClick={handleRedirect(`/geofences/groups/edit/${row.original.id}`)}
											>
												<EditIcon />
											</IconButton>
										</Tooltip>
									)}
									{shouldRenderActionButtons() && isAllowed([EPermission.GEOFENCE_GROUPS_DELETE]) && (
										<Tooltip
											title={t('page.geofenceGroup.list.tooltips.delete')}
											placement='right'
											enterDelay={500}
											arrow
										>
											<IconButton
												color='error'
												onClick={handleOnOpenGroupDeleteDialog(row.original.id as number)}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
								</Box>
							);
						}}
						renderToolbarInternalActions={({ table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<MRTToggleGlobalFilterButton table={table} />
								<MRTShowHideColumnsButton table={table} />
								{isAllowed([EPermission.GEOFENCE_GROUPS_DELETE]) && (
									<Tooltip
										title={t('page.geofenceGroup.list.tooltips.removeSelected')}
										enterDelay={500}
									>
										<span>
											<IconButton
												color='error'
												disabled={table.getSelectedRowModel().rows.length === 0}
												onClick={handleOnOpenMultipleGroupsDelete(table)}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								<MRTToggleDensePaddingButton table={table} />
								<MRTFullScreenToggleButton table={table} />
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.geofenceGroup.list.table.header.actions'),
								size: 120,
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1250 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								paddingLeft: 2,
								paddingBottom: 2,
							},
						})}
						muiTableBodyCellProps={() => ({
							sx: {
								paddingLeft: 2,
							},
						})}
						editDisplayMode='modal'
						positionActionsColumn='last'
						localization={MRTLocalization}
						layoutMode='grid-no-grow'
					/>
				</Stack>
			</Paper>
			{isAllowed([EPermission.GEOFENCE_GROUPS_CREATE]) && (
				<Link to='/geofences/groups/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.geofenceGroup.list.ariaLabel.createGroup')}
						tooltipTitle={t('page.geofenceGroup.list.tooltips.createGroup')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.GEOFENCE_GROUPS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmGroupDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.geofenceGroup.list.confirmation.delete.cancel')}
					confirmText={t('page.geofenceGroup.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
