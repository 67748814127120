import * as React from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { Box, IconButton, Button, Typography, Tooltip } from '@mui/material';
import { enUS as en, sk } from 'date-fns/locale';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

import { useCurrentLanguage } from '../../../../contexts/LocalizationContext/LocalizationContext';

import { TimeRuleProps } from './types';

export const TimeRule: React.FC<TimeRuleProps> = (props): JSX.Element => {
	const currentLanguage = useCurrentLanguage();
	const { control, name, initialTimeRanges } = props;
	const initializedRef = React.useRef(false);
	const { t } = useTranslation();
	const { fields, append, remove } = useFieldArray({
		control,
		name: `${name}.timeRanges`,
	});

	const watchedTimeRules =
		useWatch({
			control,
			name: `${name}.timeRanges`,
		}) || [];

	const getLocale = React.useMemo(() => {
		const locales = {
			en: en,
			sk: sk,
		};

		return locales[currentLanguage] || en;
	}, [currentLanguage]);

	const convertToTime = (timeString: string) => {
		if (!timeString) {
			return null;
		}
		const [hours, minutes] = timeString.split(':').map(Number);
		const date = new Date();
		date.setHours(hours, minutes, 0, 0);

		return date;
	};

	React.useEffect(() => {
		if (!initializedRef.current) {
			if (initialTimeRanges && initialTimeRanges.length > 0) {
				initialTimeRanges.forEach((timeRange) => {
					append({ from: convertToTime(timeRange.from), to: convertToTime(timeRange.to) });
				});
			} else {
				append({ from: null, to: null });
			}
			initializedRef.current = true;
		}
	}, [initialTimeRanges, append]);

	const handleOnAddTimeRangeRule = React.useCallback(() => {
		append({ from: null, to: null });
	}, [append]);

	const handleOnRemoveTimeRangeRule = React.useCallback(
		(index: number) => () => {
			remove(index);
		},
		[remove],
	);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale}>
			<Box sx={{ flex: 1 }}>
				{fields.map((field, index) => (
					<Box
						key={field.id}
						sx={{
							position: 'relative',
							display: 'flex',
							alignItems: 'flex-start',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
							justifyContent: 'space-between',
						}}
					>
						{index > 0 && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Controller
							name={`${name}.timeRanges.${index}.from`}
							control={control}
							rules={{
								required: t('page.ruleSet.form.fields.rules.time.from.helperText.required'),
								validate: (value) => {
									const toTime = watchedTimeRules[index]?.to;
									if (value && toTime && new Date(value) >= new Date(toTime)) {
										return t('page.ruleSet.form.fields.rules.time.validation.toGreaterThanFrom');
									}

									return true;
								},
							}}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TimePicker
									label={t('page.ruleSet.form.fields.rules.time.from.label')}
									value={value}
									onChange={(newValue) => {
										onChange(newValue);
									}}
									sx={{ flex: '1 1 180px' }}
									slotProps={{
										textField: {
											helperText:
												error && typeof error.message === 'string' ?
													<Typography component='span' color='error' sx={{ fontSize: 12 }}>
														{error.message}
													</Typography>
												:	t('page.ruleSet.form.fields.rules.time.from.helperText.select'),
										},
									}}
								/>
							)}
						/>
						<Controller
							name={`${name}.timeRanges.${index}.to`}
							control={control}
							rules={{
								required: t('page.ruleSet.form.fields.rules.time.to.helperText.required'),
								validate: (value) => {
									const fromTime = watchedTimeRules[index]?.from;
									if (value && fromTime && new Date(value) <= new Date(fromTime)) {
										return t('page.ruleSet.form.fields.rules.time.validation.toGreaterThanFrom');
									}

									return true;
								},
							}}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TimePicker
									label={t('page.ruleSet.form.fields.rules.time.to.label')}
									value={value}
									onChange={(newValue) => {
										onChange(newValue);
									}}
									sx={{ flex: '1 1 180px' }}
									slotProps={{
										textField: {
											helperText:
												error && typeof error.message === 'string' ?
													<Typography component='span' color='error' sx={{ fontSize: 12 }}>
														{error.message}
													</Typography>
												:	t('page.ruleSet.form.fields.rules.time.to.helperText.select'),
										},
									}}
								/>
							)}
						/>

						<Tooltip
							title={t('page.ruleSet.form.tooltips.removeTimeRange')}
							placement='top'
							enterDelay={500}
							arrow
						>
							<IconButton
								onClick={handleOnRemoveTimeRangeRule(index)}
								sx={{ flex: '0 0 34px', marginY: '10px' }}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				))}
				<Tooltip title={t('page.ruleSet.form.tooltips.addTimeRange')} placement='top' enterDelay={500} arrow>
					<Button onClick={handleOnAddTimeRangeRule} startIcon={<AddIcon />} sx={{ marginY: '10px' }}>
						{t('page.ruleSet.form.body.addTimeRange')}
					</Button>
				</Tooltip>
			</Box>
		</LocalizationProvider>
	);
};
