import * as React from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { usePreviousValue } from '../../../../hooks/usePreviousValue';
import { AuthorizationDialogProps } from './types';
import { dispatchPAMSession } from '../../helpers';

export const AuthorizationDialog: React.FC<AuthorizationDialogProps> = ({
	open,
	onClose,
	targetID,
	width,
	height,
	qrCode: intialQrCode,
}): JSX.Element => {
	const { t } = useTranslation();
	const [qrCode, setQrCode] = React.useState<string | null>(intialQrCode);
	const previousOpen = usePreviousValue(open);
	const swaggerApi = useSwaggerApi();
	const handleOnClose = () => {
		onClose();
		setQrCode(null);
		swaggerApi.cancelAllRequests();
	};

	const handleOnAuthorize = async () => {
		if (targetID) {
			try {
				const response = await swaggerApi.pam.createPamSession({
					targetID,
					width,
					height,
					first: false,
				});

				const { qr, sessionID } = response.data;
				if (qr) {
					setQrCode(qr);
				}
				if (sessionID) {
					handleOnClose();
					dispatchPAMSession(sessionID);
				}
			} catch (error) {
				console.error(error);
				handleOnClose();
			}
		}
	};

	React.useEffect(() => {
		if (previousOpen && open) {
			handleOnAuthorize();
		}
	}, [open, qrCode, previousOpen]);

	return (
		<Dialog open={open} onClose={handleOnClose}>
			<DialogTitle>{t('page.pam.authorization.title')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t('page.pam.authorization.description')}</DialogContentText>
				<Grid item xs zeroMinWidth>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							cursor: 'none',
						}}
						height={350}
					>
						{qrCode ?
							<Box
								loading='lazy'
								component='img'
								width={350}
								src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`}
								draggable={false}
							/>
						:	<CircularProgress />}
					</Box>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOnClose} color='primary'>
					{t('page.pam.authorization.cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
