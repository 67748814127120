import * as React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { Dns as DnsIcon } from '@mui/icons-material';
import { Box, Stack, Paper, FormGroup, useMediaQuery, useTheme, FormControl, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getServerSettingsSchema } from '../schema';
import { Preloader } from '../../../components/Preloader/Preloader';
import { DeploymentCertificateState, FormValues, SettingState, SubmitFormState } from './types';
import { EPermission } from '../../../enums/permission/EPermission';
import { useACL } from '../../../hooks/useACL';
import { Heading } from '../../../components/Heading/Heading';
import { TimezoneSelector } from '../../../components/FormFields/TimezoneSelector/TimezoneSelector';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { Certificate } from '../../../components/Certificate/Certificate';
import { ECertificateType } from '../../../enums/saml/ECertificateType';

export const ServerSettingPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const { isAllowed } = useACL();
	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [settingState, setSettingState] = React.useState<SettingState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});
	const [certificateState, setCertificateState] = React.useState<DeploymentCertificateState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
		control,
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getServerSettingsSchema(t)),
	});

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await api.settings.getSystemProperties();
				setSettingState({
					loading: false,
					loaded: true,
					data: {
						hostname: response.data.hostname ?? '',
						tokenUrl: response.data.tokenUrl ?? '',
						timezone: response.data.timezone ?? '',
						googleApiKey: response.data.googleApiKey ?? '',
						enabledPlacesApi: response.data.enabledPlacesApi ?? false,
						k8sOAuthUrl: response.data.k8sOAuthUrl ?? '',
						k8sOAuthUsername: response.data.k8sOAuthUsername ?? '',
						k8sAPIUrl: response.data.k8sAPIUrl ?? '',
						k8sAPINamespace: response.data.k8sAPINamespace ?? '',
					},
					error: null,
				});

				reset({
					hostname: response.data.hostname ?? '',
					tokenUrl: response.data.tokenUrl ?? '',
					timezone: response.data.timezone ?? '',
					googleApiKey: response.data.googleApiKey ?? '',
					enabledPlacesApi: response.data.enabledPlacesApi ?? false,
					k8sOAuthUrl: response.data.k8sOAuthUrl ?? '',
					k8sOAuthUsername: response.data.k8sOAuthUsername ?? '',
					k8sAPIUrl: response.data.k8sAPIUrl ?? '',
					k8sAPINamespace: response.data.k8sAPINamespace ?? '',
					k8sOAuthPassword: '',
				});
			} catch (error) {
				console.error(error);
			}

			try {
				const response = await api.settings.getDeploymentCertificate();
				setCertificateState({
					loading: false,
					loaded: true,
					data: response.data,
					error: null,
				});
			} catch (error) {
				console.error(error);

				setCertificateState({
					loading: false,
					loaded: true,
					data: null,
					error: error as AxiosError,
				});
			}
		};
		fetchData();
	}, []);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !settingState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || settingState.loading)) {
			setShouldDisableForm(true);
		}
	}, [settingState, submitFormState, shouldDisableForm]);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const handleOnSubmit = React.useCallback(
		async (formValues: FormValues) => {
			if (submitFormState.submitting || !isAllowed([EPermission.SETTINGS_GENERAL_UPDATE])) {
				return;
			}

			try {
				await api.settings.updateSystemProperties({
					hostname: formValues.hostname,
					tokenUrl: formValues.tokenUrl,
					timezone: formValues.timezone,
					googleKey: formValues.googleApiKey,
					enabledPlacesApi: formValues.enabledPlacesApi,
					k8sOAuthUrl: formValues.k8sOAuthUrl,
					k8sOAuthUsername: formValues.k8sOAuthUsername,
					k8sOAuthPassword: formValues.k8sOAuthPassword,
					k8sAPIUrl: formValues.k8sAPIUrl,
					k8sAPINamespace: formValues.k8sAPINamespace,
				});
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
				enqueueSnackbar(t('page.serverSettings.edit.actionMessages.success'), {
					variant: 'success',
					persist: false,
				});
			} catch (error) {
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[api.settings, enqueueSnackbar, setSubmitFormState, isAllowed],
	);

	return (
		<Box component={'form'} noValidate onSubmit={handleSubmit(handleOnSubmit)}>
			{settingState.loading || !settingState.loaded || certificateState.loading || !certificateState.loaded ?
				<Preloader />
			:	<Paper elevation={3}>
					<Stack
						spacing={2}
						sx={{
							padding: 2,
						}}
					>
						<PageHeader
							title={t('page.serverSettings.edit.title')}
							description={t('page.serverSettings.edit.description')}
							icon={DnsIcon}
						/>
						<FormGroup>
							<Stack
								spacing={1}
								sx={{
									width: getStackWidth(),
								}}
							>
								<Heading label={t('page.serverSettings.edit.subtitle.serverSettings')} />
								<Stack spacing={2}>
									<TextField
										name={'hostname'}
										register={register}
										label={t('page.serverSettings.edit.form.hostname.label')}
										error={errors.hostname}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.hostname.helperText')}
									/>

									<TextField
										name={'tokenUrl'}
										register={register}
										label={t('page.serverSettings.edit.form.tokenUrl.label')}
										error={errors.tokenUrl}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.tokenUrl.helperText')}
									/>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<TimezoneSelector
												control={control}
												name={'timezone'}
												error={errors.timezone}
												disabled={shouldDisableForm}
												updateValue={settingState.data?.timezone}
											/>
										</FormControl>
									</Grid>

									{isAllowed([EPermission.SETTINGS_GENERAL_READ]) && certificateState.data && (
										<Certificate
											title={t('page.serverSettings.edit.body.deploymentCertificate')}
											subject={certificateState.data.subject}
											issuer={certificateState.data.issuer}
											algorithm={certificateState.data.signatureAlgorithm}
											fingerprint={certificateState.data.fingerprint}
											expiration={new Date(certificateState.data.validity.notAfter)}
											content={certificateState.data.content}
											type={ECertificateType.DEPLOYMENT}
										/>
									)}
								</Stack>

								<Heading label={t('page.serverSettings.edit.subtitle.clusterConfig')} />
								<Stack spacing={2}>
									<TextField
										name={'k8sOAuthUrl'}
										register={register}
										label={t('page.serverSettings.edit.form.k8sOAuthUrl.label')}
										error={errors.k8sOAuthUrl}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.k8sOAuthUrl.helperText')}
									/>

									<TextField
										name={'k8sOAuthUsername'}
										register={register}
										label={t('page.serverSettings.edit.form.k8sOAuthUsername.label')}
										error={errors.k8sOAuthUsername}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.k8sOAuthUsername.helperText')}
									/>

									<PasswordField
										name={'k8sOAuthPassword'}
										register={register}
										label={t('page.serverSettings.edit.form.k8sOAuthPassword.label')}
										error={errors.k8sOAuthPassword}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.k8sOAuthPassword.helperText')}
									/>
									<TextField
										name={'k8sAPIUrl'}
										register={register}
										label={t('page.serverSettings.edit.form.k8sAPIUrl.label')}
										error={errors.k8sAPIUrl}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.k8sAPIUrl.helperText')}
									/>
									<TextField
										name={'k8sAPINamespace'}
										register={register}
										label={t('page.serverSettings.edit.form.k8sAPINamespace.label')}
										error={errors.k8sAPINamespace}
										disabled={shouldDisableForm}
										helperText={t('page.serverSettings.edit.form.k8sAPINamespace.helperText')}
									/>
								</Stack>
							</Stack>
						</FormGroup>
					</Stack>

					{isAllowed([EPermission.SETTINGS_GENERAL_UPDATE]) && (
						<FloatingButtonSave
							type='submit'
							disabled={shouldDisableForm}
							aria-label={t('page.serverSettings.ariaLabel.saveSettings')}
							tooltipTitle={t('page.serverSettings.tooltips.saveSettings')}
						/>
					)}
				</Paper>
			}
		</Box>
	);
};
