import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Button, FormGroup, InputAdornment, Typography } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import {
	IdentityStoreSetupProps,
	SubmitIdentityStoreState,
	IdentityStoreFormState,
	IdentityStoreFormValues,
} from '../types';
import { parseRTFContent } from '../../../utils/FileHelpers';
import { getIdentityStoreSchema } from '../schemas';
import { ESetupSteps } from '../enums';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { Heading } from '../../../components/Heading/Heading';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { EFieldType } from '../../../components/FormFields/TextField/enums';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { FileUploader } from '../../../components/FormFields/FileUploader/FileUploader';
import { SUPPORTED_FILE_TYPES_CA_CERTIFICATE } from '../constants';
import { SEO } from '../../../components/SEO/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { SectionHeader } from '../../../components/SectionHeader/SectionHeader';
import { SwitchField } from '../../../components/FormFields/Switch/Switch';

export const IdentityStoreSetup: React.FC<IdentityStoreSetupProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const { setIndexPage, getCurrentStep, setInitialSetupIndex } = props;
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
	const [submitIdentityStoreState, setSubmitIdentityStoreState] = React.useState<SubmitIdentityStoreState>({
		loading: false,
		loaded: false,
		error: null,
	});
	const DEFAULT_PORT_VALUE = '389';
	const {
		handleSubmit,
		getValues,
		register,
		control,
		trigger,
		reset,
		formState: { errors },
	} = useForm<IdentityStoreFormState>({
		mode: 'onChange',
		resolver: zodResolver(getIdentityStoreSchema(t)),
		defaultValues: {
			useTLS: false,
			port: DEFAULT_PORT_VALUE,
		},
	});

	const file = useWatch({ control: control, name: 'file', defaultValue: null });
	const useTLS = useWatch({ control: control, name: 'useTLS' });

	const useParsedCertificate = (response: string): string => {
		const lines = response.split('\\n');

		return lines.join('\n');
	};

	const handleOnSkip = React.useCallback(() => {
		try {
			setIndexPage(getCurrentStep(ESetupSteps.DEPLOYMENT_FINALIZATION));
			setInitialSetupIndex(ESetupSteps.DEPLOYMENT_FINALIZATION);
			enqueueSnackbar(t('page.setupWizard.actionMessages.identityStoreSuccessfullySkipped'), {
				variant: 'success',
				persist: false,
			});
		} catch (error) {
			console.error(error);
		}
	}, []);

	const handleCloseConfirmationDialog = React.useCallback(() => {
		setOpenConfirmationModal(false);
	}, []);

	const handleChangeFile = React.useCallback(
		async (file: File): Promise<void> => {
			if (!(file instanceof File)) {
				enqueueSnackbar(t('page.setupWizard.actionMessages.noSelectedFile'), {
					variant: 'error',
					persist: false,
				});

				return;
			}

			const isValid = await trigger('file');

			if (!isValid) {
				return;
			}

			const selectedFile = file;

			const reader = new FileReader();

			reader.onload = (e) => {
				if (e.target) {
					const content = e.target.result as string;
					const currentValues = getValues();
					reset({ ...currentValues, caCertificate: parseRTFContent(content) as string });
				}
			};

			reader.onerror = (error) => {
				enqueueSnackbar(error.toString(), {
					variant: 'error',
					persist: false,
				});
			};

			reader.readAsText(selectedFile);
		},
		[enqueueSnackbar, t],
	);

	const handleOnSubmit = React.useCallback(
		async (formValues: IdentityStoreFormValues) => {
			if (submitIdentityStoreState.loading) {
				return;
			}
			setSubmitIdentityStoreState({
				loading: true,
				loaded: false,
				error: null,
			});
			let host = formValues.host;
			if (formValues.host.startsWith('ldap://') || formValues.host.startsWith('ldaps://')) {
				host = host.substring(host.indexOf('//') + 2);
			}

			try {
				await api.setup.createIdentityStore({
					name: formValues.name,
					description: formValues.description,
					host,
					port: Number(formValues.port),
					useTLS: formValues.useTLS,
					caCertificate:
						formValues.useTLS && formValues.caCertificate ?
							useParsedCertificate(formValues.caCertificate)
						:	'',
					baseDN: formValues.baseDN,
					bindDN: formValues.bindDN,
					password: formValues.password,
				});
				setSubmitIdentityStoreState({
					loading: false,
					loaded: true,
					error: null,
				});
				enqueueSnackbar(t('page.setupWizard.actionMessages.identityStoreSuccessfullyCreated'), {
					variant: 'success',
					persist: false,
				});
				setIndexPage(getCurrentStep(ESetupSteps.IDENTITY_STORE_AUTHENTICATION));
			} catch (error) {
				setSubmitIdentityStoreState({
					loading: false,
					loaded: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[submitIdentityStoreState],
	);

	const handleOnBack = React.useCallback(async () => {
		if (submitIdentityStoreState.loading) {
			return;
		}

		setSubmitIdentityStoreState({
			loading: true,
			loaded: false,
			error: null,
		});

		try {
			const response = await api.setup.setBackSetupProgress();
			const step = response.data;

			setSubmitIdentityStoreState({
				loading: false,
				loaded: true,
				error: null,
			});

			setIndexPage(getCurrentStep(step));
		} catch (error) {
			setSubmitIdentityStoreState({
				loading: false,
				loaded: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	const loadUpProps = React.useCallback(async () => {
		setSubmitIdentityStoreState({
			loading: true,
			loaded: false,
			error: null,
		});

		try {
			const response = await api.setup.getIdentityStore();
			const identityStoreData = response.data;

			setSubmitIdentityStoreState({
				loading: false,
				loaded: true,
				error: null,
			});

			reset({
				name: identityStoreData?.name ?? '',
				description: identityStoreData?.description ?? '',
				baseDN: identityStoreData?.baseDN ?? '',
				bindDN: identityStoreData?.bindDN ?? '',
				host: identityStoreData?.host ?? '',
				port: String(identityStoreData?.port ?? DEFAULT_PORT_VALUE),
				useTLS: identityStoreData?.useTLS ?? false,
			});
		} catch (error) {
			setSubmitIdentityStoreState({
				loading: false,
				loaded: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	React.useEffect(() => {
		loadUpProps();
	}, []);

	React.useEffect(() => {
		if (!file || !!errors.file) {
			return;
		}
		handleChangeFile(file);
	}, [file, errors.file]);

	return (
		<Box>
			<SEO
				title={t('page.setupWizard.texts.identityStore.title')}
				description={t('page.setupWizard.texts.identityStore.description')}
			/>
			<Typography align='center'>
				{t('page.setupWizard.texts.identityStore.body.description.introduction')}
			</Typography>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<Heading label={t('page.setupWizard.texts.identityStore.general')} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'name'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.name.label')}
							InputLabelProps={{ shrink: true }}
							disabled={submitIdentityStoreState.loading}
							error={errors.name}
							helperText={t('page.setupWizard.texts.identityStore.form.name.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<Textarea
							name={'description'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.description.label')}
							disabled={submitIdentityStoreState.loading}
							error={errors.description}
							helperText={t('page.setupWizard.texts.identityStore.form.description.helperText')}
							rows={6}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				<SectionHeader
					title={t('page.setupWizard.texts.identityStore.network')}
					description={t('page.setupWizard.texts.identityStore.body.description.network')}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<SwitchField
							name={'useTLS'}
							control={control}
							label={t('page.setupWizard.texts.identityStore.form.useTLS.label')}
							disabled={submitIdentityStoreState.loading}
						/>
					</Grid>
					{useTLS && (
						<>
							<Grid item xs={12}>
								<Textarea
									name={'caCertificate'}
									register={register}
									label={t('page.setupWizard.texts.identityStore.form.caCertificate.label')}
									disabled={!!file || submitIdentityStoreState.loading}
									error={errors.caCertificate}
									helperText={t('page.setupWizard.texts.identityStore.form.caCertificate.helperText')}
									rows={6}
								/>
							</Grid>
							<Grid item xs={12}>
								<FileUploader
									control={control}
									name={'file'}
									error={errors.file}
									disabled={!useTLS || submitIdentityStoreState.loading}
									helperText={t('page.setupWizard.texts.identityStore.form.file.helperText')}
									multiple={false}
									accept={SUPPORTED_FILE_TYPES_CA_CERTIFICATE}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={8}>
						<TextField
							name={'host'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.host.label')}
							disabled={submitIdentityStoreState.loading}
							error={errors.host}
							placeholder={'example.sk.cloud'}
							helperText={t('page.setupWizard.texts.identityStore.form.host.helperText')}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>{useTLS ? 'ldaps://' : 'ldap://'}</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={4}>
						<TextField
							name={'port'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.port.label')}
							type={EFieldType.NUMBER}
							disabled={submitIdentityStoreState.loading}
							error={errors.port}
							helperText={t('page.setupWizard.texts.identityStore.form.port.helperText')}
						/>
					</Grid>
				</Grid>
			</FormGroup>
			<FormGroup
				sx={{
					marginBottom: 2,
					marginTop: 2,
				}}
			>
				{/* <Typography
					variant='subtitle1'
					sx={{
						marginBottom: 1,
					}}
				>
					{t('page.setupWizard.texts.identityStore.authentication')}
				</Typography> */}
				<Heading label={t('page.setupWizard.texts.identityStore.authentication')} />
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							name={'baseDN'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.baseDN.label')}
							InputLabelProps={{ shrink: true }}
							disabled={submitIdentityStoreState.loading}
							error={errors.baseDN}
							placeholder={'OU=Users,DC=example,DC=sk,DC=cloud'}
							helperText={t('page.setupWizard.texts.identityStore.form.baseDN.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name={'bindDN'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.bindDN.label')}
							InputLabelProps={{ shrink: true }}
							disabled={submitIdentityStoreState.loading}
							error={errors.bindDN}
							placeholder={'CN=ldap_user,OU=Users,DC=example,DC=sk,DC=cloud'}
							helperText={t('page.setupWizard.texts.identityStore.form.bindDN.helperText')}
						/>
					</Grid>
					<Grid item xs={12}>
						<PasswordField
							name={'password'}
							register={register}
							label={t('page.setupWizard.texts.identityStore.form.password.label')}
							error={errors.password}
							disabled={submitIdentityStoreState.loading}
							helperText={t('page.setupWizard.texts.identityStore.form.password.helperText')}
						/>
					</Grid>
				</Grid>
			</FormGroup>

			{device === 'desktop' ?
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					{/* <Button
						sx={{
							marginRight: device === 'desktop' ? 2 : 0,
						}}
						variant='outlined'
						type='submit'
						onClick={handleOpenConfirmationDialog}
					>
						{t('page.setupWizard.texts.identityStore.skip')}
					</Button> */}
					<Button variant='outlined' type='submit' onClick={handleOnBack}>
						{t('page.setupWizard.texts.identityStore.back')}
					</Button>
					<Button variant='contained' type='submit' onClick={handleSubmit(handleOnSubmit)}>
						{t('page.setupWizard.texts.identityStore.next')}
					</Button>
				</Grid>
			:	<Grid
					container
					spacing={2}
					sx={{
						marginTop: 2,
					}}
				>
					{/* <Grid item xs={12}>
						<Button fullWidth variant='outlined' type='submit' onClick={handleOpenConfirmationDialog}>
							{t('page.setupWizard.texts.identityStore.skip')}
						</Button>
					</Grid> */}
					<Grid item xs={12}>
						<Button fullWidth variant='outlined' type='submit' onClick={handleOnBack}>
							{t('page.setupWizard.texts.identityStore.back')}
						</Button>
						<Button fullWidth variant='contained' type='submit' onClick={handleSubmit(handleOnSubmit)}>
							{t('page.setupWizard.texts.identityStore.next')}
						</Button>
					</Grid>
				</Grid>
			}

			<ConfirmationDialog
				onClose={handleCloseConfirmationDialog}
				open={openConfirmationModal}
				onConfirm={handleOnSkip}
				title={t('page.setupWizard.texts.identityStore.skipIdentityStoreSetup.title')}
				text={t('page.setupWizard.texts.identityStore.skipIdentityStoreSetup.text')}
				cancelText={t('page.setupWizard.texts.identityStore.skipIdentityStoreSetup.cancel')}
				confirmText={t('page.setupWizard.texts.identityStore.skipIdentityStoreSetup.confirm')}
			/>
		</Box>
	);
};
