import * as React from 'react';
import axios from 'axios';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { APIProvider } from '@vis.gl/react-google-maps';
import { LocatoinSectionProps } from './types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '../../../../hooks/useNavigate';
import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { Preloader } from '../../../../components/Preloader/Preloader';
import { EQueryKey } from '../../../../enums/reactQuery/EQueryKey';
import { LIBRARIES, validationPath } from '../../../../utils/GoogleMap';
import { useACL } from '../../../../hooks/useACL';
import { EPermission } from '../../../../enums/permission/EPermission';
import { LocationSectionMap } from './LocationSectionMap';
import { SectionHeader } from '../../../../components/SectionHeader/SectionHeader';

export const LocationSection: React.FC<LocatoinSectionProps> = ({ location, systemGeofences }) => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { data: mapSettings, isLoading: isLoadingMapSettings } = useQuery({
		queryKey: [EQueryKey.MAP_SETTINGS_QUERY],
		queryFn: async () => {
			try {
				const { data } = await api.settings.getMapSettingsProperties();

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
	});

	const googleApiKey = mapSettings?.googleApiKey || mapSettings?.googleMapsdefaultApiKey;

	const { data: isValidGoogleApiKey, isLoading: isValidating } = useQuery({
		queryKey: [EQueryKey.GOOGLE_API_QUERY, googleApiKey],
		queryFn: () => axios.get(`${validationPath}${googleApiKey}`),
		enabled: !!googleApiKey,
		select: (response) => response.status === 200,
	});

	const isLoading = isLoadingMapSettings || isValidating;

	const handleOnRedirectToSettings = React.useCallback(() => {
		navigate('/settings/general/map');
	}, []);

	return (
		<Stack spacing={1}>
			<SectionHeader
				title={t('page.action.list.drawer.subtitle.location')}
				description={t('page.action.list.drawer.description.location')}
			/>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={4} alignContent='center'>
							<Typography fontWeight='bold'>
								{`${t('page.action.list.drawer.body.location')}: `}
							</Typography>
						</Grid>
						<Grid item xs={8} alignContent='center'>
							<Typography>
								{location?.name ? location.name : t('page.action.list.drawer.body.notAvailable')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					{isLoading ?
						<Preloader />
					: !isLoading && mapSettings && googleApiKey && isValidGoogleApiKey ?
						<APIProvider apiKey={googleApiKey} libraries={LIBRARIES} language={'en'}>
							{location && <LocationSectionMap location={location} systemGeofences={systemGeofences} />}
						</APIProvider>
					:	<Stack
							direction='column'
							spacing={2}
							sx={{
								paddingTop: 2,
								paddingBottom: 2,
							}}
						>
							<Typography>
								{t('page.geofence.list.text.incorrectGoogleApiKey')}
								{isAllowed([EPermission.SETTINGS_MAP_UPDATE]) ?
									<>
										{t('page.geofence.list.text.incorrectGoogleApiKeyEditSettingsFirtHalf')}
										<Link
											onClick={handleOnRedirectToSettings}
											sx={{
												cursor: 'pointer',
											}}
										>
											{t('page.geofence.list.text.here')}
										</Link>
										{t('page.geofence.list.text.incorrectGoogleApiKeyEditSettingsSecondHalf')}
									</>
								:	t('page.geofence.list.text.incorrectGoogleApiKeyContactAdmin')}
							</Typography>
						</Stack>
					}
				</Grid>
			</Grid>
		</Stack>
	);
};
