import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { ConfirmationDialogProps } from './types';
import { Typography, useTheme } from '@mui/material';

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	text,
	title,
	disableCancel,
	disableConfirm,
	cancelText,
	confirmText,
	onClose,
	onConfirm,
	content,
}): JSX.Element => {
	const theme = useTheme();

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{content ?
					<Typography
						component='div'
						// Style to match DialogContentText style
						style={{
							whiteSpace: 'pre-wrap',
							margin: 0,
							padding: 0,
							color: theme.palette.text.secondary,
							fontWeight: theme.typography.fontWeightRegular,
						}}
					>
						{content}
					</Typography>
				:	<DialogContentText>{text}</DialogContentText>}
			</DialogContent>
			<DialogActions>
				{disableCancel ? null : (
					<Button onClick={onClose} color='primary'>
						{cancelText ? cancelText : 'Cancel'}
					</Button>
				)}

				{disableConfirm ? null : (
					<Button onClick={onConfirm} color='primary'>
						{confirmText ? confirmText : 'Confirm'}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
