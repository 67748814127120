import * as React from 'react';

import { Controller } from 'react-hook-form';
import { Box, FormControlLabel, FormHelperText, Switch } from '@mui/material';

import { SwitchProps } from './types';

export const SwitchField: React.FC<SwitchProps> = (props): JSX.Element => {
	return (
		<Controller
			name={props.name}
			control={props.control}
			render={({ field: { onChange, value } }) => {
				return (
					<Box>
						<FormControlLabel
							control={
								<Switch
									checked={value}
									onChange={(e) => {
										const newValue = e.target.checked;

										onChange(newValue);
										props.onChangeCallback?.(newValue);
									}}
									disabled={props.disabled}
								/>
							}
							label={props.label}
						/>
						{props.error && typeof props.error.message === 'string' ?
							<FormHelperText error id={`switch-${props.name}`}>
								{props.error.message}
							</FormHelperText>
						:	<FormHelperText>{props.helperText}</FormHelperText>}
					</Box>
				);
			}}
		/>
	);
};
