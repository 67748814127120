import * as React from 'react';
import { Box, Grid, Paper, Stack, TextField, Typography, Tooltip, IconButton, Link } from '@mui/material';
import { Autocomplete, GoogleMap } from '@react-google-maps/api';
import { useNavigate } from '../../hooks/useNavigate';
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	AddLocation as AddLocationIcon,
	SaveAlt as SaveAltIcon,
	LensTwoTone as LensTwoToneIcon,
	PlaceOutlined,
} from '@mui/icons-material';
import {
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleGlobalFilterButton,
	MRT_ShowHideColumnsButton,
	MaterialReactTable,
	MRT_TableInstance,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { LABEL_VISIBILITY_ZOOM_LEVEL } from './constants';
import { useACL } from '../../hooks/useACL';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { circleOptions, mapOptions, circleSystemOptions } from './mapSettings';
import { LatLngLiteral } from '../../types/MapTypes';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { GeoEditationDialog } from './Components/GeoEditationDialog/GeoEditationDialog';
import { EPermission } from '../../enums/permission/EPermission';
import {
	PickGeofenceModelExcludeKeysId,
	GeofenceModel,
	PagedResultGetGeofenceResponseDto,
	GetGeofenceResponseDto,
} from '../../api/Api';
import { useGeofenceContext } from '../../hooks/useGeofenceContext';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';

export const GeofencesPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const navigate = useNavigate();
	const {
		googleApiLoaded,
		initialMapZoom,
		defaultMapCenter,
		enabledPlacesApi,
		googleApiKeyValid,
		checkCurrentLocation,
		useDefaultApiKey,
	} = useGeofenceContext();
	const circlesRef = React.useRef<google.maps.Circle[]>([]);
	const labelsRef = React.useRef<google.maps.Marker[]>([]);
	const authContext = useAuthContext();
	const { MRTLocalization } = useMRTLocalization();

	const { t } = useTranslation();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const [open, setOpen] = React.useState(false);
	const [geofenceIDToDelete, setGeofenceIDToDelete] = React.useState<number | null>(null);
	const [multiGeofenceIDsToDelete, setMultiGeofenceIDsToDelete] = React.useState<number[]>([]);
	const [shouldCenterToAll, setShouldCenterToAll] = React.useState(true);
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');
	const [mapInitialized, setMapInitialized] = React.useState(false);
	const [geofenceToEdit, setGeofenceToEdit] = React.useState<GeofenceModel | null>(null);
	const mapRef = React.useRef<google.maps.Map | null>(null);
	const autocompleteRef = React.useRef<google.maps.places.Autocomplete | null>(null);
	const [geofencesApiState, setGeofencesApiState] = React.useState({
		saving: false,
		saved: false,
		error: null,
	});
	const [zoom, setZoom] = React.useState(initialMapZoom);
	const [placesService, setPlacesService] = React.useState<google.maps.places.PlacesService | null>(null);
	const [centerMap, setCenterMap] = React.useState<LatLngLiteral>(defaultMapCenter);
	const [isWarningDialogOpen, setIsWarningDialogOpen] = React.useState(false);
	const [value, setValue] = useLocalStorage('hasViewedApiKeyWarning', false);

	const {
		columnFilters: columnFilterSystemGeofences,
		setColumnFilters: setColumnFiltersSystemGeofences,
		sorting: sortingSystemGeofences,
		setSorting: setSortingSystemGeofences,
		columnVisibility: columnVisibilitySystemGeofences,
		setColumnVisibility: setColumnVisibilitySystemGeofences,
		globalFilter: globalFilterSystemGeofences,
		setGlobalFilter: setGlobalFilterSystemGeofences,
		pagination: paginationSystemGeofences,
		setPagination: setPaginationSystemGeofences,
		swaggerQuery: swaggerQuerySystemGeofences,
	} = useTableQuery(['name', 'radius']);

	const {
		rowSelection,
		setRowSelection,
		columnFilters: columnFilterGeofences,
		setColumnFilters: setColumnFiltersGeofences,
		sorting: sortingGeofences,
		setSorting: setSortingGeofences,
		columnVisibility: columnVisibilityGeofences,
		setColumnVisibility: setColumnVisibilityGeofences,
		globalFilter: globalFilterGeofences,
		setGlobalFilter: setGlobalFilterGeofences,
		pagination: paginationGeofences,
		setPagination: setPaginationGeofences,
		swaggerQuery: swaggerQueryGeofences,
	} = useTableQuery(['name', 'radius']);

	const {
		data: systemData,
		isRefetching: systemIsRefetching,
		isLoading: systemIsLoading,
		error: systemError,
	} = useQuery<PagedResultGetGeofenceResponseDto>({
		queryKey: [
			EQueryKey.GEOFENCE_SYSTEM_LIST_QUERY,
			authContext.userTenants.activeTenantID,
			mapInitialized,
			swaggerQuerySystemGeofences,
		],
		queryFn: async () => {
			try {
				if (authContext.userTenants.activeTenantID !== null && mapInitialized) {
					const response = await api.geofences.getSystemGeofences({
						filterList: swaggerQuerySystemGeofences.filter,
						sortList: swaggerQuerySystemGeofences.sort,
						columnsList: swaggerQuerySystemGeofences.columns,
						offset: swaggerQuerySystemGeofences.offset,
						limit: swaggerQuerySystemGeofences.limit,
					});

					return response.data;
				}

				return { entities: [], total: 0 };
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: systemEntities = [], total: systemTotal = 0 } = systemData ? systemData : {};
	const { data, isRefetching, isLoading, error } = useQuery<PagedResultGetGeofenceResponseDto>({
		queryKey: [EQueryKey.GEOFENCE_COMMON_LIST_QUERY, swaggerQueryGeofences, mapInitialized],
		queryFn: async () => {
			try {
				if (mapInitialized) {
					const response = await api.geofences.getGeofences({
						filterList: swaggerQueryGeofences.filter,
						sortList: swaggerQueryGeofences.sort,
						columnsList: swaggerQueryGeofences.columns,
						offset: swaggerQueryGeofences.offset,
						limit: swaggerQueryGeofences.limit,
					});

					return response.data;
				}

				return { entities: [], total: 0 };
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	React.useEffect(() => {
		if (googleApiKeyValid) {
			checkCurrentLocation();
		} else if (useDefaultApiKey && !value) {
			setIsWarningDialogOpen(true);
			setValue(true);
		}
	}, [googleApiKeyValid, useDefaultApiKey]);

	const handleClose = React.useCallback(() => setOpen(false), []);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.geofence.list.confirmation.delete.title'));
				setConfirmationText(t('page.geofence.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.geofence.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.geofence.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnOpenMultipleGeofencesDelete = React.useCallback(
		(table: MRT_TableInstance<GetGeofenceResponseDto>) => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.geofence.list.errorMessages.noGeofenceSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiGeofenceIDsToDelete(selectedRowsOnActivePageIds);
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[confirmationTitle, confirmationText, enqueueSnackbar, rowSelection],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, [isAllowed]);

	const handleOnOpenGeofenceDeleteDialog = React.useCallback(
		(geofenceID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setGeofenceIDToDelete(geofenceID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnConfirmGeofenceDelete = React.useCallback(async (): Promise<void> => {
		if (geofenceIDToDelete) {
			try {
				await api.geofences.deleteGeofence(geofenceIDToDelete);
				enqueueSnackbar(t('page.geofence.list.actionMessages.geofenceSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setGeofenceIDToDelete(null);
				updateRowSelection([geofenceIDToDelete]);
			} catch (error) {
				console.error(error);
			}
		} else if (multiGeofenceIDsToDelete.length > 0) {
			try {
				await api.geofences.deleteGeofences({ ids: multiGeofenceIDsToDelete });
				enqueueSnackbar(t('page.geofence.list.actionMessages.geofencesSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiGeofenceIDsToDelete([]);
				updateRowSelection(multiGeofenceIDsToDelete);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [
		geofenceIDToDelete,
		multiGeofenceIDsToDelete,
		enqueueSnackbar,
		reactQueryClient,
		confirmationTitle,
		confirmationText,
		isAllowed,
	]);

	const onGeofenceClick = (geofence: GeofenceModel) => {
		try {
			if (!mapRef.current) {
				return;
			}

			const { latitude, longitude, radius } = geofence;
			const geofenceCenter = new google.maps.LatLng(latitude, longitude);
			const northPoint = google.maps.geometry.spherical.computeOffset(geofenceCenter, radius, 0);
			const eastPoint = google.maps.geometry.spherical.computeOffset(geofenceCenter, radius, 90);
			const southPoint = google.maps.geometry.spherical.computeOffset(geofenceCenter, radius, 180);
			const westPoint = google.maps.geometry.spherical.computeOffset(geofenceCenter, radius, 270);

			const bounds = new google.maps.LatLngBounds();
			bounds.extend(northPoint);
			bounds.extend(eastPoint);
			bounds.extend(southPoint);
			bounds.extend(westPoint);

			mapRef.current.fitBounds(bounds);
		} catch (error: any) {
			console.error(error);
		}
	};

	React.useEffect(() => {
		setShouldCenterToAll(true);
	}, [paginationSystemGeofences, paginationGeofences, paginationSystemGeofences]);

	const startEditing = (geofence: GeofenceModel) => {
		setGeofenceToEdit(geofence);
	};

	const updateGeofence = async (
		geofenceID: number,
		geofence: Omit<GeofenceModel, 'id'>,
		refresh = false,
	): Promise<void> => {
		if (geofencesApiState.saving) {
			return;
		}
		setGeofencesApiState({
			saving: true,
			saved: false,
			error: null,
		});
		const updateGeofenceRequestData = {
			longitude: geofence.longitude,
			latitude: geofence.latitude,
			radius: geofence.radius,
			name: geofence.name,
		};
		try {
			await api.geofences.updateGeofence(geofenceID, updateGeofenceRequestData);
			setGeofencesApiState({
				saving: false,
				saved: true,
				error: null,
			});

			if (refresh) {
				reactQueryClient.invalidateQueries();
				onGeofenceClick({
					...geofence,
					id: geofenceID,
				});
			}
		} catch (error) {
			console.error(error);
			setGeofencesApiState({
				saving: false,
				saved: false,
				error: null,
			});
		}
	};

	const columns = React.useMemo<MRT_ColumnDef<GetGeofenceResponseDto>[]>(
		() => [
			{
				accessorKey: 'name',
				header: t('page.geofence.list.table.header.name'),
				Cell: ({ row }) => {
					return (
						<Box
							onClick={() => onGeofenceClick(row.original)}
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
								cursor: 'pointer',
							}}
						>
							<LensTwoToneIcon sx={{ color: 'coral' }} />
							{row.original.name}
						</Box>
					);
				},
			},
			{
				accessorKey: 'radius',
				header: t('page.geofence.list.table.header.radius'),
			},
		],
		[isAllowed, entities, systemEntities],
	);

	const systemColumns = React.useMemo<MRT_ColumnDef<GetGeofenceResponseDto>[]>(
		() => [
			{
				accessorKey: 'name',
				header: t('page.geofence.list.table.header.name'),
				Cell: ({ row }) => (
					<Box
						onClick={() => onGeofenceClick(row.original)}
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							cursor: 'pointer',
						}}
					>
						<LensTwoToneIcon sx={{ color: 'darkGreen' }} />
						{row.original.name}
					</Box>
				),
			},
			{
				accessorKey: 'radius',
				header: t('page.geofence.list.table.header.radius'),
				Cell: ({ row }) => {
					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
								minHeight: '40px',
							}}
						>
							{row.original.radius}
						</Box>
					);
				},
			},
		],
		[isAllowed, entities, systemEntities],
	);

	const onMapLoad = (map: google.maps.Map) => {
		mapRef.current = map;
		setMapInitialized(true);
	};

	const onMapUnmount = React.useCallback(() => {
		mapRef.current = null;
	}, [mapRef]);

	const onAutocompleteLoad = React.useCallback(
		(autocomplete: google.maps.places.Autocomplete) => {
			autocompleteRef.current = autocomplete;
		},
		[autocompleteRef],
	);

	const onAutocompleteUnmount = React.useCallback(() => {
		autocompleteRef.current = null;
	}, [autocompleteRef]);

	const generateGeofenceName = React.useCallback(() => {
		const geofenceUniqueID = Math.floor(Math.random() * (9999 - 1 + 1)) + 1;
		const currentTenant = authContext.userTenants.tenants.find((tenant) => {
			return tenant.id === authContext.userTenants.activeTenantID;
		});
		const tenantName = currentTenant?.name ? currentTenant.name : 'System';

		return `Geofence ${geofenceUniqueID} - ${tenantName}`;
	}, [authContext]);

	const handleOnRedirectToSettings = React.useCallback(() => {
		navigate('/settings/general/map');
	}, []);

	const fetchPlaceNameByLocation = (
		location: google.maps.LatLng,
		bounds: google.maps.LatLngBounds,
	): Promise<string> => {
		if (!enabledPlacesApi) {
			const generatedName = generateGeofenceName();

			return Promise.resolve(generatedName);
		}

		if (!placesService) {
			throw new Error(t('page.geofence.list.errorMessages.placesServiceNotAvailable'));
		}

		return new Promise<string>((resolve, reject) => {
			placesService.nearbySearch(
				{
					location,
					bounds,
				},
				(results, status) => {
					if (status !== google.maps.places.PlacesServiceStatus.OK) {
						reject(new Error(t('page.geofence.list.errorMessages.noNearbyPlaces')));

						return;
					}

					if (results === null || !Array.isArray(results)) {
						resolve('Geofence');

						return;
					}

					const place = results[0];

					resolve(place.name ?? 'Geofence');
				},
			);
		});
	};

	const extendBoundsToGeofence = (geofence: GeofenceModel, bounds: google.maps.LatLngBounds) => {
		const { latitude, longitude, radius } = geofence;
		const geofenceCenter = new google.maps.LatLng(latitude, longitude);

		const geofenceCircle = new google.maps.Circle({
			center: geofenceCenter,
			radius: radius,
		});

		const circleBounds = geofenceCircle.getBounds();
		if (circleBounds) {
			bounds.union(circleBounds);
		}
	};

	const addGeofence = async (): Promise<void> => {
		if (geofencesApiState.saving) {
			return;
		}
		try {
			if (!mapRef.current) {
				throw new Error(t('page.geofence.list.errorMessages.noMapLoaded'));
			}

			const center = mapRef.current.getCenter();
			if (!center) {
				throw new Error(t('page.geofence.list.errorMessages.noMapCenter'));
			}

			const bounds = mapRef.current.getBounds();
			if (!bounds) {
				throw new Error(t('page.geofence.list.errorMessages.noMapBounds'));
			}

			const northEast = bounds.getNorthEast();

			const radius = google.maps.geometry.spherical.computeDistanceBetween(center, northEast) / 2;

			const placeName = await fetchPlaceNameByLocation(center, bounds);

			const latitude = center.lat();
			const longitude = center.lng();

			const newGeofence: PickGeofenceModelExcludeKeysId = {
				name: placeName,
				typeID: 2,
				latitude,
				longitude,
				radius,
			};
			setGeofencesApiState({
				saving: true,
				saved: false,
				error: null,
			});
			const createdGeofenceResponse = await api.geofences.createGeofence(newGeofence);
			reactQueryClient.invalidateQueries();
			setGeofenceToEdit(createdGeofenceResponse.data);
			enqueueSnackbar(t('page.geofence.list.actionMessages.geofenceSuccessfullyCreated'), {
				variant: 'success',
				persist: false,
			});
			setGeofencesApiState({
				saving: false,
				saved: true,
				error: null,
			});
			setTimeout(() => {
				onGeofenceClick(createdGeofenceResponse.data);
			}, 1500);
		} catch (error) {
			console.error(error);
			setGeofencesApiState({
				saving: false,
				saved: false,
				error: null,
			});
		}
	};

	React.useEffect(() => {
		if (!googleApiLoaded) {
			return;
		}

		const container = document.createElement('div');
		const placesService = new google.maps.places.PlacesService(container);

		setPlacesService(placesService);

		return () => {
			container.remove();
		};
	}, [googleApiLoaded]);

	React.useEffect(() => {
		const commonEntities = [
			...entities.map((entity) => ({
				...entity,
				editable: true,
			})),
			...systemEntities.map((entity) => ({
				...entity,
				editable: false,
			})),
		];
		if (!mapRef.current || !mapInitialized) {
			return;
		}

		if (commonEntities.length === 0) {
			circlesRef.current.forEach((circle) => {
				circle.setMap(null);
			});
			labelsRef.current.forEach((label) => {
				label.setMap(null);
			});
			setCenterMap(defaultMapCenter);
			setZoom(initialMapZoom);

			return;
		}

		circlesRef.current.forEach((circle) => {
			circle.setMap(null);
		});
		labelsRef.current.forEach((label) => {
			label.setMap(null);
		});

		const circles = commonEntities.map((geofence) => {
			const config = geofence.editable ? circleOptions : circleSystemOptions;
			const circle = new google.maps.Circle({
				...config,
				editable: isAllowed([EPermission.GEOFENCES_UPDATE]) ? geofence.editable : false,
				center: {
					lat: geofence.latitude,
					lng: geofence.longitude,
				},
				radius: geofence.radius,
			});
			const label = new google.maps.Marker({
				position: circle.getCenter(),
				map: mapRef.current,
				label: {
					text: geofence.name,
					color: 'white',
					fontWeight: 'bold',
					fontSize: '14px',
				},
				icon: {
					url: 'data:image/svg+xml;utf-8,',
					size: new google.maps.Size(1, 1),
					scaledSize: new google.maps.Size(1, 1),
					anchor: new google.maps.Point(0, 0),
					labelOrigin: geofence.editable ? new google.maps.Point(0, -16) : new google.maps.Point(0, 0),
				},
			});
			circle.addListener('center_changed', async () => {
				const center = circle.getCenter();
				if (!center) {
					return;
				}

				const latitude = center.lat();
				const longitude = center.lng();
				label.setPosition(center);
				await updateGeofence(geofence.id, {
					...geofence,
					latitude,
					longitude,
				});
			});

			circle.addListener('radius_changed', async () => {
				const radius = circle.getRadius();

				await updateGeofence(geofence.id, {
					...geofence,
					radius,
				});
			});

			circle.setMap(mapRef.current);

			label.setVisible(zoom >= LABEL_VISIBILITY_ZOOM_LEVEL);

			return { circle, label };
		});

		circlesRef.current = circles.map((circle) => circle.circle);
		labelsRef.current = circles.map((circle) => circle.label);
		const bounds = new google.maps.LatLngBounds();

		commonEntities.forEach((geofence) => {
			extendBoundsToGeofence(geofence, bounds);
		});
		if (shouldCenterToAll) {
			mapRef.current?.fitBounds(bounds);

			const center = bounds.getCenter();
			setCenterMap({
				lat: center.lat(),
				lng: center.lng(),
			});
			setShouldCenterToAll(false);
		}
	}, [
		mapRef.current,
		entities,
		systemEntities,
		mapInitialized,
		shouldCenterToAll,
		geofenceToEdit,
		EPermission,
		isAllowed,
	]);

	const autocompletePlaceChanged = () => {
		if (!mapRef.current) {
			return;
		}

		if (!autocompleteRef.current) {
			return;
		}

		try {
			const place = autocompleteRef.current.getPlace();
			if (!place || !place.geometry?.viewport) {
				return;
			}
			mapRef.current.fitBounds(place.geometry.viewport);
		} catch (error) {
			console.error(error);
		}
	};

	const closeConfirmDialog = React.useCallback(() => {
		setIsWarningDialogOpen(false);
	}, []);

	React.useEffect(() => {
		return () => {
			const googleMapsElements = document.querySelectorAll('.pac-container');
			googleMapsElements.forEach((element) => element.remove());
		};
	}, []);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3} sx={{ padding: 2 }}>
				<Box sx={{ marginBottom: 2 }}>
					<PageHeader
						title={t('page.geofence.list.title')}
						description={t('page.geofence.list.description')}
						icon={AddLocationIcon}
					/>
				</Box>
				{/* this condition will be false only if API key is provided but isnt valid  */}
				{googleApiKeyValid || useDefaultApiKey ?
					<Grid container spacing={2}>
						<Grid item xs={7}>
							{googleApiLoaded && (
								<Stack spacing={2}>
									{googleApiKeyValid && enabledPlacesApi ?
										<Autocomplete
											onLoad={onAutocompleteLoad}
											onUnmount={onAutocompleteUnmount}
											onPlaceChanged={autocompletePlaceChanged}
										>
											<TextField
												disabled={!googleApiKeyValid || !enabledPlacesApi}
												id='outlined-basic'
												label={t('page.geofence.list.searchLables.search')}
												variant='outlined'
												fullWidth
											/>
										</Autocomplete>
									:	<Typography>
											{t('page.geofence.list.searchLables.searchNotAvailable')}
											<Link
												onClick={handleOnRedirectToSettings}
												sx={{
													cursor: 'pointer',
												}}
											>
												{t('page.geofence.list.text.here')}
											</Link>
										</Typography>
									}

									<GoogleMap
										mapContainerStyle={{
											width: '100%',
											height: '75vh',
										}}
										center={centerMap}
										zoom={zoom}
										onLoad={onMapLoad}
										onUnmount={onMapUnmount}
										options={mapOptions}
									></GoogleMap>
								</Stack>
							)}
						</Grid>

						<Grid item xs={5}>
							<Box
								sx={{
									visibility: authContext.userTenants.activeTenantID === null ? 'hidden' : 'visible',
									paddingBottom: authContext.userTenants.activeTenantID === null ? 0 : 3,
									width: authContext.userTenants.activeTenantID === null ? '0px' : 'auto',
									height: authContext.userTenants.activeTenantID === null ? '0px' : 'auto',
								}}
							>
								<Stack spacing={2}>
									<SectionHeader
										title={t('page.geofence.list.systemTitle')}
										description={t('page.geofence.list.table.description.systemGeofences')}
									/>
									<MaterialReactTable
										columns={systemColumns}
										data={systemEntities}
										enableStickyHeader={false}
										state={{
											isLoading: systemIsLoading,
											showAlertBanner: error !== null,
											pagination: paginationSystemGeofences,
											showProgressBars: systemIsRefetching,
											columnFilters: columnFilterSystemGeofences,
											sorting: sortingSystemGeofences,
											columnVisibility: columnVisibilitySystemGeofences,
											globalFilter: globalFilterSystemGeofences,
										}}
										getRowId={(originalRow) => originalRow.id?.toString() || ''}
										muiToolbarAlertBannerProps={{
											color: 'error',
											children: <>{systemError}</>,
										}}
										rowCount={systemTotal}
										manualPagination
										manualFiltering
										manualSorting
										onPaginationChange={setPaginationSystemGeofences}
										initialState={{
											columnVisibility: { createdAt: false },
											density: 'compact',
										}}
										onColumnFiltersChange={setColumnFiltersSystemGeofences}
										onGlobalFilterChange={setGlobalFilterSystemGeofences}
										onSortingChange={setSortingSystemGeofences}
										onColumnVisibilityChange={setColumnVisibilitySystemGeofences}
										renderToolbarInternalActions={({ table }) => (
											<Box>
												<MRT_ToggleGlobalFilterButton table={table} />
												{/* <MRT_ToggleFiltersButton table={table} />
													<MRT_ShowHideColumnsButton table={table} />
													<Tooltip title={t('page.geofence.list.tooltips.export)} enterDelay={500}>
														<IconButton>
															<SaveAltIcon />
														</IconButton>
													</Tooltip>

													<MRT_ToggleDensePaddingButton table={table} /> */}
												<MRT_ToggleFullScreenButton table={table} />
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1250 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										muiSelectCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiSelectAllCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												verticalAlign: 'baseline',
											},
										})}
										localization={MRTLocalization}
									/>
								</Stack>
							</Box>
							<Box
								sx={{
									paddingBottom: 1,
								}}
							>
								<Stack spacing={2}>
									<SectionHeader
										title={t('page.geofence.list.title')}
										description={
											authContext.user.user?.tenantID ?
												t('page.geofence.list.table.description.geofences.tenant')
											:	t('page.geofence.list.table.description.geofences.system')
										}
									/>
									<MaterialReactTable
										columns={columns}
										data={entities}
										enableRowActions
										enableStickyHeader={false}
										state={{
											isLoading: isLoading,
											showAlertBanner: error !== null,
											pagination: paginationGeofences,
											rowSelection,
											showProgressBars: isRefetching,
											columnFilters: columnFilterGeofences,
											sorting: sortingGeofences,
											columnVisibility: columnVisibilityGeofences,
											globalFilter: globalFilterGeofences,
										}}
										getRowId={(originalRow) => originalRow.id?.toString() || ''}
										muiToolbarAlertBannerProps={{
											color: 'error',
											children: <>{error}</>,
										}}
										rowCount={total}
										manualPagination
										manualFiltering
										manualSorting
										enableRowSelection={isAllowed([EPermission.GEOFENCES_DELETE])}
										onPaginationChange={setPaginationGeofences}
										onRowSelectionChange={setRowSelection}
										onColumnFiltersChange={setColumnFiltersGeofences}
										onGlobalFilterChange={setGlobalFilterGeofences}
										onSortingChange={setSortingGeofences}
										onColumnVisibilityChange={setColumnVisibilityGeofences}
										initialState={{
											columnVisibility: { createdAt: false },
											density: 'compact',
										}}
										renderRowActions={({ row }) => (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '1rem',
												}}
											>
												{isAllowed([EPermission.GEOFENCES_UPDATE]) && (
													<Tooltip
														title={t('page.geofence.list.tooltips.edit')}
														placement='left'
														enterDelay={500}
														arrow
													>
														<IconButton onClick={() => startEditing(row.original)}>
															<EditIcon />
														</IconButton>
													</Tooltip>
												)}
												<Tooltip
													title={t('page.geofence.list.tooltips.delete')}
													placement='right'
													enterDelay={500}
													arrow
												>
													<IconButton
														color='error'
														onClick={handleOnOpenGeofenceDeleteDialog(
															row.original.id as number,
														)}
													>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											</Box>
										)}
										renderToolbarInternalActions={({ table }) => (
											<Box>
												<MRT_ToggleGlobalFilterButton table={table} />
												<MRT_ShowHideColumnsButton table={table} />
												{/* <MRT_ToggleFiltersButton table={table} />
													<MRT_ShowHideColumnsButton table={table} />
													<Tooltip title={t('page.geofence.list.tooltips.export)} enterDelay={500}>
														<IconButton>
															<SaveAltIcon />
														</IconButton>
													</Tooltip> */}
												{isAllowed([EPermission.GEOFENCES_DELETE]) && (
													<Tooltip
														title={t('page.geofence.list.tooltips.removeSelected')}
														enterDelay={500}
														arrow
													>
														<span>
															<IconButton
																color='error'
																disabled={table.getSelectedRowModel().rows.length === 0}
																onClick={() =>
																	handleOnOpenMultipleGeofencesDelete(table)
																}
															>
																<DeleteIcon />
															</IconButton>
														</span>
													</Tooltip>
												)}
												{/* <MRT_ToggleDensePaddingButton table={table} /> */}
												<MRT_ToggleFullScreenButton table={table} />
											</Box>
										)}
										displayColumnDefOptions={{
											'mrt-row-actions': {
												header: t('page.geofence.list.table.header.actions'),
											},
											'mrt-row-select': {
												enableHiding: true,
												visibleInShowHideMenu: false,
											},
										}}
										muiTablePaperProps={({ table }) => ({
											style: {
												zIndex: table.getState().isFullScreen ? 1250 : undefined,
												boxShadow: 'none',
												outline: '1px solid #e0e0e0',
											},
										})}
										muiSelectCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiSelectAllCheckboxProps={() => ({
											sx: {
												width: '50px',
												height: '50px',
											},
										})}
										muiTableHeadCellProps={() => ({
											sx: {
												verticalAlign: 'baseline',
											},
										})}
										localization={MRTLocalization}
										positionActionsColumn='last'
									/>
								</Stack>
							</Box>
						</Grid>
						<ConfirmationDialog
							open={isWarningDialogOpen}
							onClose={closeConfirmDialog}
							onConfirm={handleOnRedirectToSettings}
							text={t('page.geofence.list.text.warning')}
							title={t('page.geofence.list.text.warningTitle')}
							cancelText={t('page.geofence.list.text.cancel')}
							confirmText={t('page.geofence.list.text.openSettings')}
						/>
					</Grid>
				:	<Stack
						direction='column'
						spacing={2}
						sx={{
							paddingTop: 2,
							paddingBottom: 2,
						}}
					>
						<Typography align='center'>
							{t('page.geofence.list.text.incorrectGoogleApiKey')}
							{isAllowed([EPermission.SETTINGS_MAP_UPDATE]) ?
								<>
									{t('page.geofence.list.text.incorrectGoogleApiKeyEditSettingsFirtHalf')}
									<Link
										onClick={handleOnRedirectToSettings}
										sx={{
											cursor: 'pointer',
										}}
									>
										{t('page.geofence.list.text.here')}
									</Link>
									{t('page.geofence.list.text.incorrectGoogleApiKeyEditSettingsSecondHalf')}
								</>
							:	<>{t('page.geofence.list.text.incorrectGoogleApiKeyContactAdmin')}</>}
						</Typography>
					</Stack>
				}
			</Paper>

			{isAllowed([EPermission.GEOFENCES_CREATE]) && (googleApiKeyValid || useDefaultApiKey) && (
				<FloatingButtonAdd
					ariaLabel={t('page.geofence.list.ariaLabel.addGeofence')}
					tooltipTitle={t('page.geofence.list.tooltips.addGeofence')}
					onClick={() => addGeofence()}
				/>
			)}

			{isAllowed([EPermission.GEOFENCES_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmGeofenceDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.geofence.list.confirmation.delete.cancel')}
					confirmText={t('page.geofence.list.confirmation.delete.confirm')}
				/>
			)}
			{isAllowed([EPermission.GEOFENCES_CREATE, EPermission.GEOFENCES_UPDATE], false) && (
				<GeoEditationDialog
					geofence={geofenceToEdit}
					open={geofenceToEdit !== null}
					onClose={() => setGeofenceToEdit(null)}
					updateGeofence={updateGeofence}
				/>
			)}
		</Box>
	);
};
