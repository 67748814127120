import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Send as SendIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	FormGroup,
	Stack,
	Grid,
	FormControl,
	FormHelperText,
	FormLabel,
	FormControlLabel,
	Switch,
	Typography,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

import { ESignatureMethod, WebhookModel } from '../../../../api/Api';
import { FormValues } from './types';
import { Heading } from '../../../../components/Heading/Heading';
import { Select } from '../../../../components/FormFields/Select/Select';
import { TextField } from '../../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../../components/FormFields/Textarea/Textarea';
import { FileUploader } from '../../../../components/FormFields/FileUploader/FileUploader';
import { enqueueSnackbar } from 'notistack';
import { PasswordField } from '../../../../components/FormFields/PasswordField/PasswordField';
import { Preloader } from '../../../../components/Preloader/Preloader';
import { getWebhookFormSchema } from './schemas';
import { EQueryKey } from '../../../../enums/reactQuery/EQueryKey';
import { useSwaggerApi } from '../../../../hooks/useSwaggerApi';
import { SectionHeader } from '../../../../components/SectionHeader/SectionHeader';
import { SwitchField } from '../../../../components/FormFields/Switch/Switch';

const splitByUppercaseCharacter = (str: string) => str.match(/[A-Z][a-z]+/g);

const signatureMethodOptions = Object.entries(ESignatureMethod).map(([label, value]) => {
	const labelWords = splitByUppercaseCharacter(label);

	if (!labelWords) {
		return { label, value };
	}

	if (labelWords.length === 1) {
		return { value, label: label.toUpperCase() };
	}

	return { value, label: labelWords?.join(' ') ?? label };
});

const capitalizeFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const transformToTranslationKey = (str: string) => {
	const words = str.toLowerCase().split('_');

	if (words.length === 1) {
		return words[0];
	}

	const [firstWord, ...rest] = words;

	return [firstWord, ...rest.map(capitalizeFirstLetter)].join('');
};

function parseCertificate(response: string): string {
	const lines = response.split('\\n');

	return lines.join('\n');
}

const formatFormValues = ({ secret, file: _, ...formValues }: FormValues): FormValues => ({
	...formValues,
	caCertificate:
		formValues.useTLS && formValues.caCertificate ? parseCertificate(formValues.caCertificate) : undefined,
	secret: formValues.signatureMethod === ESignatureMethod.Hmac ? secret : undefined,
});

export const WebhookForm: React.FC<{
	formID: string;
	onSubmit: (formValues: FormValues) => void;
	id?: WebhookModel['id'];
	defaultValues?: FormValues;
}> = ({ defaultValues, formID, id, onSubmit }) => {
	const { t } = useTranslation();

	const api = useSwaggerApi();

	const { data: eventTypesResponse = { eventTypes: [] }, isLoading: isLoadingEventTypes } = useQuery({
		queryKey: [EQueryKey.EVENT_TYPE_LIST_QUERY],
		queryFn: async () => {
			try {
				const { data } = await api.webhookEventTypes.getEventTypes();

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
	});

	const eventTypesOptions = useMemo(() => {
		const { eventTypes } = eventTypesResponse;

		if (eventTypes.length === 0) {
			return [];
		}

		const filteredEventTypes = eventTypes.filter(({ name }) => name.toLowerCase() !== 'test');

		return filteredEventTypes.map(({ id, name }) => ({
			id,
			name,
			label: t(`page.oAuthClients.webhooks.events.${transformToTranslationKey(name)}`),
		}));
	}, [eventTypesResponse.eventTypes]);

	const { mutate, isPending } = useMutation({
		mutationFn: (webhookID: WebhookModel['id']) => api.oauthClient.sendTestWebhookEvent(webhookID),
		onSuccess: () => {
			enqueueSnackbar(t('page.oAuthClients.webhooks.form.actionMessages.webhookTestSuccess'), {
				variant: 'success',
			});
		},
	});

	const {
		control,
		handleSubmit,
		register,
		resetField,
		setValue,
		trigger,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<FormValues>({
		defaultValues: defaultValues ?? {
			eventTypeIDs: [],
			caCertificate: '',
			file: null,
			secret: '',
			signatureMethod: ESignatureMethod.Hmac,
			url: '',
			useTLS: true,
		},
		resolver: zodResolver(getWebhookFormSchema(t)),
		mode: 'onChange',
	});

	const disabled = isSubmitting;
	const file = watch('file');
	const signatureMethod = watch('signatureMethod');
	const useTLS = watch('useTLS');

	const onFileChange = async (file: File | null) => {
		if (!(file instanceof File)) {
			setValue('caCertificate', '', { shouldDirty: true });

			return;
		}

		const isValid = await trigger('file');

		if (!isValid) {
			return;
		}

		const reader = new FileReader();

		reader.onload = (event) => {
			const caCertificateFromFile = event.target?.result;

			if (typeof caCertificateFromFile !== 'string') {
				enqueueSnackbar(t('page.oAuthClients.webhooks.form.errorMessages.caCertificateFormat'), {
					variant: 'error',
					persist: false,
				});

				return;
			}

			setValue('caCertificate', caCertificateFromFile, { shouldDirty: true });
		};

		reader.onerror = (error) => {
			// eslint-disable-next-line no-console
			console.error(error);

			enqueueSnackbar(t('page.oAuthClients.webhooks.form.errorMessages.fileUploadError'), {
				variant: 'error',
				persist: false,
			});
		};

		reader.readAsText(file);
	};

	const onTlsChange = (value: boolean) => {
		if (!value) {
			setValue('caCertificate', '', { shouldDirty: true, shouldValidate: true });
			setValue('file', null, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onSignatureMethodChange = (event: SelectChangeEvent<ESignatureMethod>) => {
		if (event.target.value === ESignatureMethod.DeploymentPrivateKey) {
			resetField('secret', { defaultValue: '' });
		}
	};

	const sendTestWebhookEvent = () => id && mutate(id);

	return (
		<Box
			component={'form'}
			noValidate
			id={formID}
			onSubmit={handleSubmit((formValues) => onSubmit(formatFormValues(formValues)))}
		>
			<Grid container>
				<Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Heading label={t('page.oAuthClients.webhooks.form.subtitle.general')} />
						<Stack spacing={2}>
							<TextField
								name='url'
								disabled={disabled}
								error={errors.url}
								helperText={t('page.oAuthClients.webhooks.form.url.helperText')}
								label={t('page.oAuthClients.webhooks.form.url.label')}
								register={register}
							/>
							{isLoadingEventTypes && <Preloader />}
							{!isLoadingEventTypes && eventTypesOptions.length > 0 && (
								<Controller
									name='eventTypeIDs'
									control={control}
									render={({ field: { value, onChange, ...restField } }) => {
										return (
											<>
												<FormControl component='fieldset' variant='standard' {...restField}>
													<FormLabel component='legend'>
														{t('page.oAuthClients.webhooks.form.eventTypes.label')}
													</FormLabel>
													<FormGroup>
														{eventTypesOptions.map(({ id, label, name }) => {
															const checked = value.includes(id);

															return (
																<FormControlLabel
																	key={name}
																	control={
																		<Switch
																			checked={checked}
																			onChange={() => {
																				if (checked) {
																					onChange(
																						value.filter((v) => v !== id),
																					);
																				} else {
																					onChange([...value, id]);
																				}
																			}}
																			name={name}
																		/>
																	}
																	label={label}
																/>
															);
														})}
													</FormGroup>
													<FormHelperText>
														{t('page.oAuthClients.webhooks.form.eventTypes.helperText')}
													</FormHelperText>
												</FormControl>
											</>
										);
									}}
								/>
							)}
							{eventTypesOptions.length > 0 && id && (
								<Box>
									<Tooltip
										arrow
										enterDelay={500}
										title={t('page.oAuthClients.webhooks.form.sendTestingEvent.helperText')}
									>
										<LoadingButton
											loading={isPending}
											startIcon={<SendIcon />}
											variant='outlined'
											onClick={sendTestWebhookEvent}
										>
											{t('page.oAuthClients.webhooks.form.sendTestingEvent.label')}
										</LoadingButton>
									</Tooltip>
								</Box>
							)}
						</Stack>
					</FormGroup>
				</Grid>

				<Grid item xs={12} md={6} order={{ xs: 1, md: 3 }}>
					<FormGroup sx={{ padding: 2 }}>
						<SectionHeader
							title={t('page.oAuthClients.webhooks.form.subtitle.network.title')}
							description={t('page.oAuthClients.webhooks.form.subtitle.network.description')}
						/>
						<Stack marginTop={1} spacing={3} maxWidth={'100%'}>
							<SwitchField
								control={control}
								name='useTLS'
								disabled={disabled}
								helperText={t('page.oAuthClients.webhooks.form.useTLS.helperText')}
								label={t('page.oAuthClients.webhooks.form.useTLS.label')}
								onChangeCallback={onTlsChange}
							/>

							{useTLS && (
								<Stack spacing={2}>
									<Grid item sm={12}>
										<Textarea
											name='caCertificate'
											register={register}
											disabled={!!file || disabled}
											error={errors.caCertificate}
											helperText={t('page.oAuthClients.webhooks.form.caCertificate.helperText')}
											label={t('page.oAuthClients.webhooks.form.caCertificate.label')}
											rows={6}
										/>
									</Grid>
									<Grid item sm={12}>
										<FileUploader
											name='file'
											control={control}
											disabled={disabled}
											error={errors.file}
											helperText={t('page.oAuthClients.webhooks.form.file.helperText')}
											multiple={false}
											onChangeCallback={onFileChange}
										/>
									</Grid>
								</Stack>
							)}
						</Stack>
					</FormGroup>
				</Grid>

				<Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Heading label={t('page.oAuthClients.webhooks.form.subtitle.authentication')} />
						<Stack spacing={2}>
							<Select
								name='signatureMethod'
								control={control}
								disabled={disabled}
								error={errors.signatureMethod}
								helperText={t('page.oAuthClients.webhooks.form.signatureMethod.helperText')}
								label={t('page.oAuthClients.webhooks.form.signatureMethod.label')}
								options={signatureMethodOptions}
								onChangeCallback={onSignatureMethodChange}
							/>
							{signatureMethod === ESignatureMethod.Hmac && (
								<PasswordField
									name='secret'
									disabled={disabled}
									error={errors.secret}
									helperText={t('page.oAuthClients.webhooks.form.secret.helperText')}
									label={t('page.oAuthClients.webhooks.form.secret.label')}
									register={register}
								/>
							)}
						</Stack>
					</FormGroup>
				</Grid>
			</Grid>
		</Box>
	);
};
