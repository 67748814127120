export enum EQueryKey {
	CLIENTS_LIST_QUERY = 'clients-list-query',
	OAUTH_CLIENT_LIST_QUERY = 'oauth-client-list-query',
	OAUTH_CLIENT_DETAIL_QUERY = 'oauth-client-detail-query',
	OAUTH_WEBHOOK_LIST_QUERY = 'oauth-webhook-list-query',
	OAUTH_WEBHOOK_DETAIL_QUERY = 'oauth-webhook-detail-query',
	EVENT_TYPE_LIST_QUERY = 'event-type-list-query',
	IDENTITY_STORE_LIST_QUERY = 'identity-store-list-query',
	SESSION_SEARCH_TERM_QUERY = 'session-search-term-query',
	SESSION_DETAIL_QUERY = 'session-detail-query',
	SESSION_LIST_QUERY = 'session-list-query',
	SMTP_CONFIG_LIST_QUERY = 'smtp-config-list-query',
	TENANT_LIST_QUERY = 'tenant-list-query',
	TENANT_LIST_INFINITE_QUERY = 'tenant-list-infinite-query',
	PAM_TARGET_LIST_QUERY = 'pam-target-list-query',
	PAM_TARGET_SESSION_LIST_QUERY = 'pam-target-session-list-query',
	PAM_TARGET_USER_LIST_QUERY = 'pam-target-user-list-query',
	PAM_TARGET_AUDIT_LOG_LIST_QUERY = 'pam-target-audit-logs-query',
	PAM_SESSION_FILE_TRANSFERS_QUERY = 'pam-session-file-transfer-list-query',
	PAM_DETAILED_TARGET_LIST_QUERY = 'pam-detailed-target-list-query',
	PAM_GROUP_LIST_QUERY = 'pam-group-list-query',
	PAM_GROUP_DETAIL_LIST_QUERY = 'pam-group-detail-list-query',
	PAM_GROUP_TARGET_LIST_QUERY = 'pam-group-target-list-query',
	PAM_GROUP_DETAIL_QUERY = 'pam-group-detail-query',
	ROLE_LIST_QUERY = 'role-list-query',
	USER_DETAIL_LIST_QUERY = 'user-detail-list-query',
	USER_DETAIL_QUERY = 'user-detail-query',
	USER_GROUP_LIST_QUERY = 'user-group-list-query',
	USER_GROUP_QUERY = 'user-group-query',
	USER_LIST_QUERY = 'user-list-query',
	USER_GROUP_USER_LIST_QUERY = 'user-group-user-list-query',
	INVITATION_LIST_QUERY = 'invitation-list-query',
	IDENTITY_STORE_USER_LIST_QUERY = 'identity-store-user-list-query',
	SECURITY_POLICY_LIST_QUERY = 'security-policy-list-query',
	GEOFENCE_GROUP_LIST_QUERY = 'geofence-group-list-query',
	GEOFENCE_GROUP_GEOFENCE_LIST_QUERY = 'geofence-group-geofence-list-query',
	GEOFENCE_COMMON_LIST_QUERY = 'geofence-common-list-query',
	GEOFENCE_SYSTEM_LIST_QUERY = 'geofence-system-list-query',
	TENANT_RESOURCE_LIST_QUERY = 'tenant-resource-list-query',
	RULE_SET_LIST_QUERY = 'rule-set-list-query',
	RULE_SET_SYSTEM_LIST_QUERY = 'rule-set-system-list-query',
	TOKEN_DETAIL_LIST_QUERY = 'token-detail-list-query',
	EXPIRATION_TIMES_QUERY = 'expiration-times-query',
	ACTION_LIST_QUERY = 'action-list-query',
	VERSIONS_QUERY = 'versions-query',
	MAP_SETTINGS_QUERY = 'map-settings-query',
	GOOGLE_API_QUERY = 'google-api-query',
	NETWORK_POLICY_LIST_QUERY = 'network-policy-list-query',
}
