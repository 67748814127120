import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { PromiseWrapper } from '@excalibur-enterprise/promise-wrapper';
import { enqueueSnackbar } from 'notistack';

import { Api, ApiConfig } from '../../api/Api';
import { SwaggerApiClientOptions, excludedPaths, RequestQueueItem } from './types';
import { ClientPaths } from './enums';
import { getTenantFromPath, getPathName, hasPath } from '../../utils/Routing';
import { EXCLUDE_URLS, REQUEST_SENT_RESET_TIME, TENANT_PREFIX } from './constants';
import { tenantIDPathRegex, tenantSlugPathRegex } from '../../utils/Tenant';

// Becaue the fact that the SwaggerApiClient multiple instances are created, the isRefreshing and requestQueue
// are shared between all instances
let isRefreshing = false;
let requestQueue: RequestQueueItem[] = [];
const cancelTokens: Map<string, CancelTokenSource> = new Map();

export interface SwaggerApiClientType<SecurityDataType = unknown> extends Api<SecurityDataType> {
	cancelRequest(url: string): void;
	cancelAllRequests(): void;
}

export class SwaggerApiClient<SecurityDataType = unknown>
	extends Api<SecurityDataType>
	implements SwaggerApiClientType<SecurityDataType>
{
	constructor(options: ApiConfig<SecurityDataType> = {}, swaggerApiClientOptions: SwaggerApiClientOptions = {}) {
		super(options);

		this.instance.interceptors.request.use((config) => {
			const { tenantID, tenantSlug } = getTenantFromPath();
			const cancelTokenSource = axios.CancelToken.source();
			config.cancelToken = cancelTokenSource.token;
			if (config.url) {
				cancelTokens.set(config.url, cancelTokenSource);
			}
			if (swaggerApiClientOptions.setRequestSent) {
				swaggerApiClientOptions.setRequestSent(true);

				setTimeout(() => {
					if (swaggerApiClientOptions.setRequestSent) {
						swaggerApiClientOptions.setRequestSent(false);
					}
				}, REQUEST_SENT_RESET_TIME);
			}
			if (
				tenantID &&
				config.url !== ClientPaths.REFRESH &&
				!config.url?.includes(`/${TENANT_PREFIX}/${tenantID}`)
			) {
				config.url = `/${TENANT_PREFIX}/${tenantID}${config.url}`;
			} else if (
				tenantSlug &&
				config.url !== ClientPaths.REFRESH &&
				!config.url?.includes(`/${TENANT_PREFIX}/${tenantSlug}`)
			) {
				config.url = `/${TENANT_PREFIX}/${tenantSlug}${config.url}`;
			}

			return config;
		});

		this.instance.interceptors.response.use(
			(response: AxiosResponse) => {
				return response;
			},
			async (error: AxiosError) => {
				const errorData = error?.response?.data as {
					code?: string;
					arguments?: Record<string, any>;
				};
				const originaUrl = error?.config?.url;
				if (originaUrl) {
					cancelTokens.delete(originaUrl);
				}
				if (
					originaUrl &&
					EXCLUDE_URLS.some((url) => originaUrl.includes(url)) &&
					errorData?.code &&
					parseInt(errorData?.code) === 115012
				) {
					console.error(error);

					return;
				}
				if (
					errorData?.code &&
					swaggerApiClientOptions.t &&
					error.response?.status !== 401 &&
					originaUrl !== ClientPaths.REFRESH &&
					originaUrl?.match(tenantIDPathRegex) === null &&
					originaUrl?.match(tenantSlugPathRegex) === null
				) {
					const errorMessage = swaggerApiClientOptions.t(
						`services.${errorData.code}`,
						errorData.arguments ? { ...errorData.arguments } : undefined,
					);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
						persist: false,
					});
				} else if (
					error.response?.status !== 401 &&
					swaggerApiClientOptions.t &&
					!originaUrl?.includes(ClientPaths.REFRESH) &&
					error.code !== 'ERR_CANCELED'
				) {
					const errorMessage = swaggerApiClientOptions.t(`errors.somethingWrong`);
					enqueueSnackbar(errorMessage, {
						variant: 'error',
						persist: false,
					});
				}

				// If any error status 502
				if (error.response?.status === 502 && swaggerApiClientOptions.checkApiStatus) {
					swaggerApiClientOptions.checkApiStatus();
				}

				// If error is not an AxiosError, rethrow it
				if (!(error instanceof AxiosError)) {
					throw error;
				}

				// If response status is not 401, rethrow it as we are not interested in this error
				if (error.response?.status !== 401) {
					throw error;
				}

				const originalRequest = error.config;

				// If request is not available, rethrow it as well. Not sure when this can happen
				// but it's better to be safe than sorry
				if (!originalRequest) {
					throw error;
				}

				const request: AxiosRequestConfig & { isRetry?: boolean } = originalRequest;
				if (request.isRetry) {
					throw error;
				}

				// Mark the request as retry
				request.isRetry = true;

				try {
					localStorage.setItem('hasViewedApiKeyWarning', 'false');
					if (request.url?.includes(ClientPaths.REFRESH)) {
						throw error;
					}

					// If we are already refreshing the token, enqueue the request and wait for the token to be refreshed
					if (isRefreshing) {
						const promiseWrapper = new PromiseWrapper();

						requestQueue.push({ promiseWrapper, request });

						const promise = promiseWrapper.getPromise();

						return promise;
					}

					isRefreshing = true;

					await this.instance.post(ClientPaths.REFRESH, { withCredentials: true });

					isRefreshing = false;

					for (const queueItem of requestQueue) {
						queueItem.promiseWrapper.resolve(this.instance(queueItem.request));
					}
					requestQueue = [];

					return this.instance(request);
				} catch (refreshError) {
					isRefreshing = false;

					// Reject all requests in the queue
					for (const queueItem of requestQueue) {
						queueItem.promiseWrapper.reject(refreshError);
					}

					requestQueue = [];
					let pathname = window.location.pathname;
					if (swaggerApiClientOptions.location?.pathname) {
						pathname = swaggerApiClientOptions.location.pathname;
					} else {
						pathname = getPathName();
					}
					const { tenantID, tenantSlug } = getTenantFromPath();
					if (!swaggerApiClientOptions.disableRedirect) {
						const original = pathname;
						if (original && !hasPath(excludedPaths, original)) {
							if (tenantID) {
								window.location.href = `/tenant/${tenantID}${ClientPaths.LOGIN}?original=${original}`;
							} else if (tenantSlug) {
								window.location.href = `/tenant/${tenantSlug}${ClientPaths.LOGIN}?original=${original}`;
							} else {
								window.location.href = `${ClientPaths.LOGIN}?original=${original}`;
							}
						} else if (!(original && !hasPath(excludedPaths, original))) {
							if (tenantID) {
								window.location.href = `/tenant/${tenantID}${ClientPaths.LOGIN}`;
							} else if (tenantSlug) {
								window.location.href = `/tenant/${tenantSlug}${ClientPaths.LOGIN}`;
							} else {
								window.location.href = ClientPaths.LOGIN;
							}
						}
					}

					throw refreshError;
				}
			},
		);
	}

	public cancelRequest(url: string): void {
		const cancelTokenSource = cancelTokens.get(url);
		if (cancelTokenSource) {
			cancelTokenSource.cancel(`Request to ${url} canceled by the user.`);
			cancelTokens.delete(url);
		}
	}

	public cancelAllRequests(): void {
		cancelTokens.forEach((cancelTokenSource, url) => {
			cancelTokenSource.cancel(`Request to ${url} canceled.`);
		});
		cancelTokens.clear();
	}
}
