import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MaterialReactTable,
	MRT_RowSelectionState,
	MRT_TableInstance,
} from 'material-react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Box, Tooltip, IconButton, Typography, Paper, Stack } from '@mui/material';
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	FolderShared as FolderSharedIcon,
	SaveAlt as SaveAltIcon,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { IdentityStoreDto, PagedResultPartialIdentityStoreDto, PartialIdentityStoreDto } from '../../../api/Api';
import { Link } from '../../../components/Link/Link';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { ldapIdentityStoreSchema } from './schemas';
import { EConfirmDialogState } from '../../../enums/teanant/EConfirmDialogState';
import { FloatingButtonAdd } from '../../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useTableQuery } from '../../../hooks/useTableQuery';
import { EPermission } from '../../../enums/permission/EPermission';
import { useReactQueryClient } from '../../../hooks/useReactQueryClient';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { useACL } from '../../../hooks/useACL';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';

export const IdentityStoreList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const [identityStoreIDToDelete, setIdentityStoreIDToDelete] = React.useState<number | null>(null);
	const [multiIdentityStoresIDToDelete, setMultiIdentityStoresIDToDelete] = React.useState<{ ids: number[] }>({
		ids: [],
	});
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['name', 'description', 'hostname', 'port']);

	const { data, isRefetching, isLoading, error } = useQuery<PagedResultPartialIdentityStoreDto>({
		queryKey: [EQueryKey.IDENTITY_STORE_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const response = await api.identityStores.getAllIdentityStores({
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					sort: swaggerQuery.sort,
					filter: swaggerQuery.filter,
				});
				response.data.entities.forEach((identityStore) => {
					ldapIdentityStoreSchema.parse(identityStore);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.identityStore.list.confirmation.delete.title'));
				setConfirmationText(t('page.identityStore.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.identityStore.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.identityStore.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenIdentityStoreDeleteDialog = React.useCallback(
		(identityStoreID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setIdentityStoreIDToDelete(identityStoreID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[handleOnChangeConfirmDialog, isAllowed],
	);

	const handleOnOpenMultipleStoresDelete = React.useCallback(
		(table: MRT_TableInstance<Partial<IdentityStoreDto>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(
					t('page.tenants.sections.identityStores.list.errorMessages.noIdentityStoressSelected'),
					{
						variant: 'warning',
						persist: false,
					},
				);

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiIdentityStoresIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, t, enqueueSnackbar, handleOnChangeConfirmDialog],
	);

	const handleOnConfirmIdentityStoreDelete = React.useCallback(async (): Promise<void> => {
		if (identityStoreIDToDelete) {
			try {
				await api.identityStores.deleteIdentityStore(identityStoreIDToDelete);
				enqueueSnackbar(t('page.identityStore.list.actionMessages.identityStoreSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setIdentityStoreIDToDelete(null);
				updateRowSelection([identityStoreIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiIdentityStoresIDToDelete.ids.length > 0) {
			try {
				await api.identityStores.deleteIdentityStores(multiIdentityStoresIDToDelete);
				enqueueSnackbar(t('page.identityStore.list.actionMessages.identityStoresSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiIdentityStoresIDToDelete({ ids: [] });
				updateRowSelection(multiIdentityStoresIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [
		identityStoreIDToDelete,
		multiIdentityStoresIDToDelete,
		api.identityStores,
		enqueueSnackbar,
		reactQueryClient,
		handleOnChangeConfirmDialog,
		rowSelection,
		isAllowed,
	]);

	const handleClose = React.useCallback(() => setOpen(false), []);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, [isAllowed]);

	const columns = React.useMemo<MRTColumnDef<PartialIdentityStoreDto>[]>(
		() => [
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: t('page.identityStore.list.columns.name'),
			},
			{
				accessorFn: (row) => `${row.description}`,
				accessorKey: 'description',
				header: t('page.identityStore.list.columns.description'),
			},
			{
				accessorFn: (row) => `${row.host}`,
				accessorKey: 'hostname',
				header: t('page.identityStore.list.columns.host'),
			},
			{
				accessorFn: (row) => `${row.port}`,
				accessorKey: 'port',
				header: t('page.identityStore.list.columns.port'),
			},
		],
		[],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.identityStore.list.title')}
						description={t('page.identityStore.list.description')}
						icon={FolderSharedIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						enableRowActions
						enableStickyHeader={false}
						state={{
							isLoading: isLoading,
							showAlertBanner: error !== null,
							rowSelection,
							globalFilter,
							columnFilters,
							sorting,
							columnVisibility,
							pagination,
							showProgressBars: isRefetching,
						}}
						muiToolbarAlertBannerProps={{
							color: 'error',
							children: <>{error}</>,
						}}
						rowCount={total}
						manualPagination
						manualFiltering
						manualSorting
						onSortingChange={setSorting}
						onColumnVisibilityChange={setColumnVisibility}
						onColumnFiltersChange={setColumnFilters}
						onPaginationChange={setPagination}
						enableRowSelection
						onGlobalFilterChange={setGlobalFilter}
						onRowSelectionChange={setRowSelection}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						renderRowActions={({ row }) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem',
								}}
							>
								{isAllowed([EPermission.IDENTITY_STORES_UPDATE]) && (
									<Tooltip
										title={t('page.identityStore.list.tooltips.edit')}
										placement='left'
										enterDelay={500}
										arrow
									>
										<span>
											<Link to={`/identityStores/edit/${row.original.id}`}>
												<IconButton>
													<EditIcon />
												</IconButton>
											</Link>
										</span>
									</Tooltip>
								)}
								{isAllowed([EPermission.IDENTITY_STORES_DELETE]) && (
									<Tooltip
										title={t('page.identityStore.list.tooltips.delete')}
										placement='right'
										enterDelay={500}
										arrow
									>
										<IconButton
											color='error'
											onClick={handleOnOpenIdentityStoreDeleteDialog(row.original.id as number)}
										>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
						)}
						renderToolbarInternalActions={({ table }) => (
							<Box>
								<MRTToggleGlobalFilterButton table={table} />
								<MRTToggleFiltersButton table={table} />
								<MRTShowHideColumnsButton table={table} />
								{/* <Tooltip title={t('page.identityStore.list.tooltips.export')} enterDelay={500}>
									<IconButton>
										<SaveAltIcon />
									</IconButton>
								</Tooltip> */}
								{isAllowed([EPermission.IDENTITY_STORES_DELETE]) && (
									<Tooltip
										title={t('page.identityStore.list.tooltips.removeSelected')}
										enterDelay={500}
									>
										<span>
											<IconButton
												color='error'
												disabled={
													!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
												}
												onClick={handleOnOpenMultipleStoresDelete(table)}
											>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}

								<MRTToggleDensePaddingButton table={table} />
								<MRTFullScreenToggleButton table={table} />
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.identityStore.list.columns.actions'),
								size: 120,
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								zIndex: table.getState().isFullScreen ? 1250 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiSelectCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiSelectAllCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								verticalAlign: 'baseline',
							},
						})}
						localization={MRTLocalization}
						positionActionsColumn='last'
					/>
				</Stack>
			</Paper>
			{isAllowed([EPermission.IDENTITY_STORES_CREATE]) && (
				<Link to='/identityStores/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.identityStore.list.ariaLabel.addIdentityStore')}
						tooltipTitle={t('page.identityStore.list.tooltips.addIdentityStore')}
					/>
				</Link>
			)}
			{isAllowed([EPermission.IDENTITY_STORES_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmIdentityStoreDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.identityStore.list.confirmation.delete.cancel')}
					confirmText={t('page.identityStore.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
