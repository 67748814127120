import React, { useCallback, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { NetworkAddressesImportModalProps } from './types';
import { FileUploader } from '../FormFields/FileUploader/FileUploader';

export const NetworkAddressesImportModal: React.FC<NetworkAddressesImportModalProps> = ({
	open,
	control,
	name,
	error,
	disabled = false,
	trigger,
	onClose,
	onImport,
}) => {
	const [fileContent, setFileContent] = useState<string>('');

	const handleFileChange = async (file: File | null): Promise<void> => {
		if (!file) {
			setFileContent('');

			return;
		}

		const isValid = await trigger(name);
		if (!isValid) {
			setFileContent('');

			return;
		}

		const selectedFile = file;

		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target) {
				const content = e.target.result as string;
				setFileContent(content);
			}
		};
		reader.onerror = (error) => {
			enqueueSnackbar(error.toString(), { variant: 'error' });
		};
		reader.readAsText(selectedFile);
	};

	const handleClose = useCallback(() => {
		setFileContent('');
		onClose();
	}, [onClose]);

	const handleImport = React.useCallback(() => {
		const commaSeparatedRanges = fileContent.split(',').map((range) => range.trim());
		const networkAddresses = commaSeparatedRanges.flatMap((range) =>
			range.split(/\r?\n/).map((line) => line.trim()),
		);
		const filteredNetworkAddresses = networkAddresses.filter((range) => range.length > 0);
		onImport(filteredNetworkAddresses);
		handleClose();
	}, [fileContent, onImport, handleClose]);

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
			<DialogTitle>{t('component.networkAddressImportModal.title')}</DialogTitle>
			<DialogContent>
				<FileUploader
					control={control}
					name={name}
					error={error}
					disabled={disabled}
					helperText={t('component.networkAddressImportModal.helperText')}
					multiple={false}
					onChangeCallback={handleFileChange}
				/>
				<pre>{fileContent}</pre>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					{t('component.networkAddressImportModal.cancel')}
				</Button>
				<Button disabled={!fileContent || !!error} onClick={handleImport} color='primary'>
					{t('component.networkAddressImportModal.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
