import { z } from 'zod';

export const actionListSchema = z.object({
	accountName: z.string(),
	action: z.string(),
	id: z.number(),
	createdAt: z.string(),
	status: z.string(),
	targetID: z.number(),
	targetName: z.string(),
	targetType: z.number(),
	userName: z.string(),
	userSurname: z.string(),
	userTitle: z.string().optional(),
	geofenceName: z.string().nullable(),
});
