import { TFunction } from 'i18next';
import { VerifyPAMTargetResponseDto } from '../../api/Api';

export const generateVerificationErrors = (
	verifyPamTargetResponse: VerifyPAMTargetResponseDto,
	nameExists: boolean,
	t: TFunction<'translation', undefined>,
): { text: string; title: string }[] => {
	const isPamTargetReachable = verifyPamTargetResponse.reachable;
	const isPamTargetInRange = verifyPamTargetResponse.inRange;

	const errors: { text: string; title: string }[] = [];

	if (nameExists) {
		errors.push({
			text: t('page.pam.confirmation.nameExistsError.text'),
			title: t('page.pam.confirmation.nameExistsError.title'),
		});
	}
	if (!isPamTargetReachable) {
		errors.push({
			text: t('page.pam.confirmation.connectionError.text'),
			title: t('page.pam.confirmation.connectionError.title'),
		});
	}
	if (!isPamTargetInRange) {
		errors.push({
			text: t('page.pam.confirmation.outOfIPRangeError.text'),
			title: t('page.pam.confirmation.outOfIPRangeError.title'),
		});
	}

	return errors;
};
