import * as React from 'react';
import {
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Box,
	IconButton,
	Button,
	TextField,
	SelectChangeEvent,
	Typography,
	FormHelperText,
	Tooltip,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PhoneConnectionProps, PhoneConnectionState } from './types';
import {
	PHONE_CONNECTION_STATUS_TYPE,
	PHONE_CONNECTION_IP_TYPE,
	PHONE_CONNECTION_SINGLE_IP_TYPE,
	IP_REGEX,
	ONLINE_STATUS,
	OFFLINE_STATUS,
} from './constants';
import { EIPAddressType } from './enum';

interface PhoneConnectionRuleField {
	type: string;
	value: any;
}

export const PhoneConnectionRule: React.FC<PhoneConnectionProps> = (props): JSX.Element => {
	const { control, watch, name, initialRuleSet } = props;
	const { t } = useTranslation();
	const [currentType, setCurrentType] = React.useState<PhoneConnectionState>({});
	const initializedRef = React.useRef(false);
	const validateIP = React.useCallback(
		(value: string) => {
			return IP_REGEX.test(value) || t('page.ruleSet.form.errorMessage.invalidIP');
		},
		[t],
	);

	const { fields, append, remove, update } = useFieldArray({
		control,
		name: `${name}.rules`,
	});

	const ipToNumber = React.useCallback((ip: string) => {
		return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
	}, []);

	const validateIPRange = React.useCallback(
		(index: number, type: EIPAddressType) => (value: string) => {
			if (!value) {
				return t('page.ruleSet.form.fields.rules.phoneConnection.error.required');
			}

			const fromIP = type === EIPAddressType.FROM ? value : watch(`${name}.rules.${index}.value.from`);
			const toIP = type === EIPAddressType.TO ? value : watch(`${name}.rules.${index}.value.to`);

			const fromIPNum = ipToNumber(fromIP);
			const toIPNum = ipToNumber(toIP);

			return (
				fromIPNum >= toIPNum ?
					type === EIPAddressType.FROM ?
						t('page.ruleSet.form.fields.rules.phoneConnection.ipFrom.helperText.invalid')
					:	t('page.ruleSet.form.fields.rules.phoneConnection.ipTo.helperText.invalid')
				:	true
			);
		},
		[name, watch],
	);

	const validateUniqueIP = React.useCallback(
		(validatedIndex: number) => (value: string) => {
			const typedFields = fields as unknown as PhoneConnectionRuleField[];
			const valueNum = ipToNumber(value);

			const isUnique = typedFields.every((field, index) => {
				if (index === validatedIndex) {
					return true;
				}

				if (field.type === PHONE_CONNECTION_SINGLE_IP_TYPE) {
					const singleIP = field.value.ip;
					const singleIPNum = ipToNumber(singleIP);

					return singleIPNum !== valueNum;
				}

				return true;
			});

			return isUnique || t('page.ruleSet.form.fields.rules.phoneConnection.singleIp.helperText.notUnique');
		},
		[fields, ipToNumber],
	);

	React.useEffect(() => {
		if (!initializedRef.current && initialRuleSet) {
			initialRuleSet.phoneConnectionIPs.forEach((ipRange, index) => {
				append({ type: PHONE_CONNECTION_IP_TYPE, value: ipRange } as PhoneConnectionRuleField);
				setCurrentType((prev) => ({ ...prev, [index]: PHONE_CONNECTION_IP_TYPE }));
			});
			initialRuleSet.phoneConnectionStatuses.forEach((status, index) => {
				const statusIndex = initialRuleSet.phoneConnectionIPs.length + index;
				append({ type: PHONE_CONNECTION_STATUS_TYPE, value: { type: status } } as PhoneConnectionRuleField);
				setCurrentType((prev) => ({ ...prev, [statusIndex]: PHONE_CONNECTION_STATUS_TYPE }));
			});
			initialRuleSet.ips.forEach((ip, index) => {
				const ipIndex =
					initialRuleSet.phoneConnectionIPs.length + initialRuleSet.phoneConnectionStatuses.length + index;
				append({ type: PHONE_CONNECTION_SINGLE_IP_TYPE, value: { ip } } as PhoneConnectionRuleField);
				setCurrentType((prev) => ({ ...prev, [ipIndex]: PHONE_CONNECTION_SINGLE_IP_TYPE }));
			});
			initializedRef.current = true;
		}
	}, [initialRuleSet, append]);

	const handleTypeChange = React.useCallback(
		(index: number, value: string) => {
			setCurrentType((prev) => ({ ...prev, [index]: value }));
			if (value === PHONE_CONNECTION_STATUS_TYPE) {
				update(index, { type: value, value: { type: ONLINE_STATUS } } as PhoneConnectionRuleField);
			} else if (value === PHONE_CONNECTION_IP_TYPE) {
				update(index, { type: value, value: { from: '', to: '' } } as PhoneConnectionRuleField);
			} else {
				update(index, { type: value, value: { ip: '' } } as PhoneConnectionRuleField);
			}
		},
		[update],
	);

	const handleOnAddRule = React.useCallback(() => {
		const typedFields = fields as unknown as PhoneConnectionRuleField[];
		if (typedFields.some((field) => field.type === PHONE_CONNECTION_STATUS_TYPE)) {
			append({ type: PHONE_CONNECTION_IP_TYPE, value: { from: '', to: '' } } as PhoneConnectionRuleField);
			setCurrentType((prev) => ({ ...prev, [fields.length]: PHONE_CONNECTION_IP_TYPE }));
		} else if (typedFields.some((field) => field.type === PHONE_CONNECTION_SINGLE_IP_TYPE)) {
			append({ type: PHONE_CONNECTION_STATUS_TYPE, value: { type: ONLINE_STATUS } } as PhoneConnectionRuleField);
			setCurrentType((prev) => ({ ...prev, [fields.length]: PHONE_CONNECTION_STATUS_TYPE }));
		} else {
			append({ type: PHONE_CONNECTION_SINGLE_IP_TYPE, value: { ip: '' } } as PhoneConnectionRuleField);
			setCurrentType((prev) => ({ ...prev, [fields.length]: PHONE_CONNECTION_SINGLE_IP_TYPE }));
		}
	}, [append, fields]);

	const handleOnRemoveRule = React.useCallback(
		(index: number) => () => {
			remove(index);
			setCurrentType((prev) => {
				const newState = { ...prev };
				delete newState[index];

				return Object.keys(newState).reduce(
					(acc, key) => {
						const numericKey = Number(key);
						const newIndex = numericKey > index ? numericKey - 1 : numericKey;
						acc[newIndex] = newState[key];

						return acc;
					},
					{} as Record<number, string>,
				);
			});
		},
		[remove],
	);

	const typedFields = fields as unknown as PhoneConnectionRuleField[];
	const statusOptionDisabled = typedFields.some((field) => field.type === PHONE_CONNECTION_STATUS_TYPE);

	return (
		<Box sx={{ flex: 1 }}>
			{fields.map((field, index) => (
				<Box
					key={field.id}
					sx={{
						position: 'relative',
						display: 'flex',
						alignItems: 'flex-start',
						flexWrap: 'wrap',
						gap: 2,
						mb: 2,
						maxWidth: '100%',
						justifyContent: 'space-between',
					}}
				>
					{index > 0 && (
						<Box
							sx={{
								position: { xs: 'relative', md: 'absolute' },
								top: 0,
								bottom: 0,
								left: { xs: 0, md: '-50px' },
								width: { xs: '100%', md: 'auto' },
							}}
						>
							<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
						</Box>
					)}
					<Controller
						name={`${name}.rules.${index}.type`}
						control={control}
						render={({ field, fieldState }) => (
							<FormControl variant='outlined' error={!!fieldState.error} sx={{ flex: '1 1 180px' }}>
								<InputLabel id={`${name}-type-label`}>
									{t('page.ruleSet.form.fields.rules.phoneConnection.typeSelect.label')}
								</InputLabel>
								<Select
									labelId={`${name}-type-label`}
									id={`${name}-type-select`}
									value={field.value || ''}
									sx={{ minWidth: '180px', width: '100%' }}
									onChange={(event: SelectChangeEvent<any>) => {
										field.onChange(event.target.value);
										handleTypeChange(index, event.target.value);
									}}
									label={t('page.ruleSet.form.fields.rules.phoneConnection.typeSelect.label')}
								>
									<MenuItem value={PHONE_CONNECTION_STATUS_TYPE} disabled={statusOptionDisabled}>
										{t(
											'page.ruleSet.form.fields.rules.phoneConnection.typeSelect.options.statusLabel',
										)}
									</MenuItem>
									<MenuItem value={PHONE_CONNECTION_IP_TYPE}>
										{t(
											'page.ruleSet.form.fields.rules.phoneConnection.typeSelect.options.ipRangeLabel',
										)}
									</MenuItem>
									<MenuItem value={PHONE_CONNECTION_SINGLE_IP_TYPE}>
										{t(
											'page.ruleSet.form.fields.rules.phoneConnection.typeSelect.options.singleIpLabel',
										)}
									</MenuItem>
								</Select>
								{fieldState.error && typeof fieldState.error.message === 'string' ?
									<FormHelperText error id={`${name}-error`}>
										{fieldState.error.message}
									</FormHelperText>
								:	<FormHelperText>
										{t('page.ruleSet.form.fields.rules.phoneConnection.typeSelect.helperText')}
									</FormHelperText>
								}
							</FormControl>
						)}
					/>
					{currentType[index] === PHONE_CONNECTION_STATUS_TYPE && (
						<Controller
							name={`${name}.rules.${index}.value.type`}
							control={control}
							render={({ field, fieldState }) => (
								<FormControl variant='outlined' error={!!fieldState.error} sx={{ flex: '1 1 180px' }}>
									<InputLabel id={`${name}-status-label`}>
										{t('page.ruleSet.form.fields.rules.phoneConnection.statusSelect.label')}
									</InputLabel>
									<Select
										labelId={`${name}-status-label`}
										id={`${name}-status-select`}
										value={field.value || ONLINE_STATUS}
										onChange={(event: SelectChangeEvent<any>) => {
											field.onChange(event.target.value);
										}}
										label={t('page.ruleSet.form.fields.rules.phoneConnection.statusSelect.label')}
									>
										<MenuItem value={ONLINE_STATUS}>
											{t(
												'page.ruleSet.form.fields.rules.phoneConnection.statusSelect.options.onlineLabel',
											)}
										</MenuItem>
										<MenuItem value={OFFLINE_STATUS}>
											{t(
												'page.ruleSet.form.fields.rules.phoneConnection.statusSelect.options.offlineLabel',
											)}
										</MenuItem>
									</Select>
									{fieldState.error && typeof fieldState.error.message === 'string' ?
										<FormHelperText error id={`${name}-error`}>
											{fieldState.error.message}
										</FormHelperText>
									:	<FormHelperText>
											{t(
												'page.ruleSet.form.fields.rules.phoneConnection.statusSelect.helperText',
											)}
										</FormHelperText>
									}
								</FormControl>
							)}
						/>
					)}
					{currentType[index] === PHONE_CONNECTION_IP_TYPE && (
						<Box sx={{ display: 'flex', flex: '1 1 180px', flexFlow: 'row wrap', gap: 2 }}>
							<Controller
								name={`${name}.rules.${index}.value.from`}
								control={control}
								rules={{
									validate: {
										validateIP: validateIP,
										validateIPRange: validateIPRange(index, EIPAddressType.FROM),
									},
								}}
								render={({ field, fieldState }) => (
									<TextField
										sx={{ minWidth: '180px', flex: '1 1 180px' }}
										variant='outlined'
										label={t('page.ruleSet.form.fields.rules.phoneConnection.ipFrom.label')}
										placeholder={t(
											'page.ruleSet.form.fields.rules.phoneConnection.ipFrom.placeholder',
										)}
										value={field.value || ''}
										onChange={(
											event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
										) => {
											field.onChange(event.target.value);
										}}
										error={!!fieldState.error}
										helperText={
											fieldState.error && typeof fieldState.error.message === 'string' ?
												<Typography component='span' color='error' sx={{ fontSize: 12 }}>
													{fieldState.error.message}
												</Typography>
											:	t('page.ruleSet.form.fields.rules.phoneConnection.ipFrom.helperText.main')
										}
									/>
								)}
							/>
							<Controller
								name={`${name}.rules.${index}.value.to`}
								control={control}
								rules={{
									validate: {
										validateIP: validateIP,
										validateIPRange: validateIPRange(index, EIPAddressType.TO),
									},
								}}
								render={({ field, fieldState }) => (
									<TextField
										sx={{ minWidth: '180px', flex: '1 1 180px' }}
										variant='outlined'
										label={t('page.ruleSet.form.fields.rules.phoneConnection.ipTo.label')}
										placeholder={t(
											'page.ruleSet.form.fields.rules.phoneConnection.ipTo.placeholder',
										)}
										value={field.value || ''}
										onChange={(
											event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
										) => {
											field.onChange(event.target.value);
										}}
										error={!!fieldState.error}
										helperText={
											fieldState.error && typeof fieldState.error.message === 'string' ?
												<Typography component='span' color='error' sx={{ fontSize: 12 }}>
													{fieldState.error.message}
												</Typography>
											:	t('page.ruleSet.form.fields.rules.phoneConnection.ipTo.helperText.main')
										}
									/>
								)}
							/>
						</Box>
					)}
					{currentType[index] === PHONE_CONNECTION_SINGLE_IP_TYPE && (
						<Controller
							name={`${name}.rules.${index}.value.ip`}
							control={control}
							rules={{
								validate: {
									validateIP: validateIP,
									validateUniqueIP: validateUniqueIP(index),
								},
							}}
							render={({ field, fieldState }) => (
								<TextField
									sx={{ minWidth: '180px', flex: '1 1 180px' }}
									variant='outlined'
									label={t('page.ruleSet.form.fields.rules.phoneConnection.singleIp.label')}
									placeholder={t(
										'page.ruleSet.form.fields.rules.phoneConnection.singleIp.placeholder',
									)}
									value={field.value || ''}
									onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
										field.onChange(event.target.value);
									}}
									error={!!fieldState.error}
									helperText={
										fieldState.error && typeof fieldState.error.message === 'string' ?
											<Typography component='span' color='error' sx={{ fontSize: 12 }}>
												{fieldState.error.message}
											</Typography>
										:	t('page.ruleSet.form.fields.rules.phoneConnection.singleIp.helperText.main')
									}
								/>
							)}
						/>
					)}
					<Tooltip
						title={t('page.ruleSet.form.tooltips.removePhoneConnection')}
						placement='top'
						enterDelay={500}
						arrow
					>
						<IconButton onClick={handleOnRemoveRule(index)} sx={{ flex: '0 1 34px', marginY: '10px' }}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			))}
			<Tooltip title={t('page.ruleSet.form.tooltips.addPhoneConnection')} placement='top' enterDelay={500} arrow>
				<Button onClick={handleOnAddRule} startIcon={<AddIcon />} sx={{ marginY: '10px' }}>
					{t('page.ruleSet.form.body.addCondition')}
				</Button>
			</Tooltip>
		</Box>
	);
};
