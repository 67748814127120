import * as React from 'react';
import { Autocomplete, Box, TextField, AutocompleteRenderInputParams, InputAdornment } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { US as EN, SK } from 'country-flag-icons/react/3x2';

import { LanguageSelection, LanguageSwitcherProps } from './types';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useChangeLanguage, useCurrentLanguage } from '../../contexts/LocalizationContext/LocalizationContext';
import { LANGUAGES } from '../../contexts/LocalizationContext/constants';

import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { ELanguageCode } from '../../api/Api';

export const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props): JSX.Element => {
	const currentLanguage = useCurrentLanguage();
	const changeLanguage = useChangeLanguage();
	const { t } = useTranslation();
	const swaggerApi = useSwaggerApi();
	const reactQueryClient = useReactQueryClient();
	const previousActiveLanguageValue = usePreviousValue(currentLanguage);

	const authLayoutProps = React.useMemo(
		() => ({
			openOnFocus: true,
			size: 'small',
			sx: {
				maxWidth: props.isCompactMode ? 80 : 200,
				width: '100%',
				'.MuiInputBase-input ': { color: props.textColor ? props.textColor : 'inherit' },
				'.MuiSvgIcon-root': { color: props.textColor },
				'.MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					border: 'none',
				},
			},
		}),
		[props.isCompactMode, props.textColor],
	);

	const defaultProps = React.useMemo(
		() => ({
			sx: { maxWidth: props.isCompactMode ? 80 : 200, width: '100%' },
		}),
		[props.isCompactMode],
	);

	const options: LanguageSelection[] = React.useMemo(() => {
		const optns = LANGUAGES.map((language: ELanguageCode) => {
			const option: LanguageSelection = {
				label: language.toUpperCase(),
				value: language,
			};

			return option;
		});

		return optns;
	}, [LANGUAGES]);

	const defaultSelectedOption = React.useMemo(() => {
		if (currentLanguage === null) {
			return options.find((option) => option.value === 'en');
		}

		return options.find((option) => option.value === currentLanguage);
	}, [options, currentLanguage]);

	const renderFlag = React.useMemo(() => {
		const renderCountryFlag = (language: ELanguageCode) => {
			switch (language) {
				case ELanguageCode.En:
					return <EN width={25} />;
				case ELanguageCode.Sk:
					return <SK width={25} />;
				default:
					return null;
			}
		};

		return renderCountryFlag;
	}, []);

	const handleOnSwitchLanguage = React.useCallback(
		(_: React.SyntheticEvent, language: LanguageSelection | null) => {
			if (!language) {
				return;
			}
			const value = language.value && typeof language.value === 'string' ? language.value : null;
			// swaggerApi.users.getUser(22, { columns: ['id'] });
			if (value) {
				// localizationContext.handleOnChangeLanguage(value as ELanguageCode);
				changeLanguage(value as ELanguageCode);

				if (!props.isAuthLayout) {
					swaggerApi.localizations.changeLanguage({ language: value });
				}
			}
		},
		[changeLanguage, props.isAuthLayout],
	);

	React.useEffect(() => {
		if (previousActiveLanguageValue === currentLanguage) {
			return;
		} else if (previousActiveLanguageValue !== currentLanguage) {
			reactQueryClient.invalidateQueries();
		}

		const hasActiveLanguageChanged =
			previousActiveLanguageValue !== currentLanguage && previousActiveLanguageValue !== undefined;
		const activeLanguage = currentLanguage;

		if (hasActiveLanguageChanged) {
			enqueueSnackbar(
				t('component.languageSwitcher.switchLanguageSuccessMessage', {
					languageIndex: activeLanguage,
				}),
				{ variant: 'success' },
			);
		}
	}, [currentLanguage, previousActiveLanguageValue, t]);

	return (
		<Autocomplete
			disablePortal
			disableClearable
			defaultValue={defaultSelectedOption}
			id='language-switcher'
			onChange={handleOnSwitchLanguage}
			options={options}
			value={defaultSelectedOption}
			{...(props.isAuthLayout ? authLayoutProps : defaultProps)}
			renderOption={(props, option) => (
				<Box component='li' sx={{ '& > svg': { mr: 2, flexShrink: 0 } }} {...props}>
					{renderFlag(option.value)}
					{option.label}
				</Box>
			)}
			renderInput={(params: AutocompleteRenderInputParams) => (
				<TextField
					{...params}
					label={props.isAuthLayout ? undefined : t('component.languageSwitcher.label')}
					helperText={props.isAuthLayout ? undefined : t('component.languageSwitcher.helperText')}
					variant={props.isCompactMode ? 'standard' : 'outlined'}
					fullWidth
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position='start'>
								{defaultSelectedOption && renderFlag(defaultSelectedOption?.value)}
							</InputAdornment>
						),
					}}
				/>
			)}
		/>
	);
};
