import { forwardRef, useEffect, useRef, useState } from 'react';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { useFetchUserGroupsOptionsPaginated } from '../../hooks/query/userGroups/useFetchUserGroupsPaginated';
import { UserGroupsIDs } from './types';
import { CREATE_INVITATIONS_MK } from '../../hooks/query/users/useCreateInvitations';

const getTenantId = (strTenantId: string | undefined) => (strTenantId ? Number(strTenantId) : undefined);

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	const { id } = useParams();
	const location = useLocation();
	const tenantId = getTenantId(id ?? location.state?.tenantIdOrSlug);

	const { t } = useTranslation();

	const { hasNextPage, isFetchingNextPage, fetchNextPage } = useFetchUserGroupsOptionsPaginated(tenantId);

	const renderWaypoint = () => {
		if (!isFetchingNextPage && hasNextPage) {
			return <Waypoint onEnter={() => fetchNextPage()} bottomOffset={-300} />;
		} else if (isFetchingNextPage && hasNextPage) {
			return (
				<Typography px={2} pb={1}>
					{t('component.inviteUserDialog.loading')}
				</Typography>
			);
		}
	};

	return (
		<div ref={ref}>
			<ul {...props} />
			{renderWaypoint()}
		</div>
	);
});

const InviteUserDialog: React.FC<{
	open: boolean;
	confirmText: string;
	text: string;
	title: string;
	onClose: () => void;
	onConfirm: (userGroupsIDs: UserGroupsIDs) => void;
}> = ({ open, confirmText, text, title, onClose, onConfirm }) => {
	const [selectedUserGroupsIds, setSelectedUserGroupsIds] = useState<UserGroupsIDs>([]);

	const defaultSelectedRef = useRef<UserGroupsIDs | null>(null);

	const { id } = useParams();
	const location = useLocation();
	const tenantId = getTenantId(id ?? location.state?.tenantIdOrSlug);

	const { t } = useTranslation();

	const { data: userGroupsOptions = [], isLoading } = useFetchUserGroupsOptionsPaginated(tenantId, {
		enabled: open,
	});

	const invitationMutationsCount = useIsMutating({ mutationKey: CREATE_INVITATIONS_MK });
	const isCreatingInvitation = invitationMutationsCount > 0;

	useEffect(() => {
		if (!open) {
			return;
		}
		if (defaultSelectedRef.current) {
			setSelectedUserGroupsIds(defaultSelectedRef.current);
			defaultSelectedRef.current = null;

			return;
		}
		if (userGroupsOptions.length > 0) {
			const usersGroupOption = userGroupsOptions.find((group) => group.label === 'Users');
			setSelectedUserGroupsIds((prev) => (prev.length === 0 && usersGroupOption ? [usersGroupOption.id] : prev));
			defaultSelectedRef.current = null;
		}
	}, [open, userGroupsOptions]);

	const handleClose = () => {
		if (userGroupsOptions.length > 0) {
			const usersGroupOption = userGroupsOptions.find((group) => group.label === 'Users');
			defaultSelectedRef.current = usersGroupOption ? [usersGroupOption.id] : [];
		}
		onClose();
	};

	return (
		<Dialog fullWidth open={open} onClose={handleClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText mb={2}>{text}</DialogContentText>
				<Autocomplete
					multiple
					id='user-groups'
					size='small'
					loading={isLoading}
					value={userGroupsOptions.filter((o) => selectedUserGroupsIds.includes(o.id))}
					limitTags={2}
					ListboxComponent={ListboxComponent}
					onChange={(_, newValue) => setSelectedUserGroupsIds(newValue.map((option) => option.id))}
					options={userGroupsOptions}
					getOptionLabel={(option) => option.label}
					renderInput={({ inputProps, ...rest }) => (
						<TextField
							{...rest}
							label={t('component.inviteUserDialog.userGroups')}
							inputProps={{ ...inputProps, readOnly: true }}
						/>
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					{t('component.inviteUserDialog.cancel')}
				</Button>

				<LoadingButton
					color='primary'
					disabled={selectedUserGroupsIds.length === 0}
					loading={isCreatingInvitation}
					onClick={() => {
						onConfirm(selectedUserGroupsIds);
						handleClose();
					}}
				>
					{confirmText}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default InviteUserDialog;
