import * as React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Step, StepLabel, StepContent, Typography, Stepper, Paper, Stack } from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import { MailServerStep } from './steps/MailServerStep';
import { EmailVerificationStep } from './steps/EmailVerificationStep';
import { VerifyEmailStep } from './steps/VerifyEmailStep';
import { SMTP_FORM_INIT_VALUES } from './SmtpInitialValues';
import { SmtpFormData } from './types';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const StyledStepper = styled(Stepper)({
	'.Mui-completed': {
		color: 'green !important',
	},
});

export const SmtpAddPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { id } = useParams();
	const [activeStepEmailSetup, setActiveStepEmailSetup] = React.useState(0);
	const [formDataSmtp, setFormDataSmtp] = React.useState<SmtpFormData>(SMTP_FORM_INIT_VALUES);
	const [createdConfigId, setCreatedConfigId] = React.useState<number | null>(null);

	return (
		<Box>
			<Paper
				elevation={3}
				sx={{
					paddingBottom: 2,
				}}
			>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={id ? t('page.smtp.edit.title') : t('page.smtp.add.title')}
						description={id ? t('page.smtp.edit.description') : t('page.smtp.add.description')}
						icon={EmailIcon}
					/>
					<StyledStepper activeStep={activeStepEmailSetup} orientation='vertical'>
						<Step>
							<StepLabel>{t('page.smtp.edit.step.mailServer')}</StepLabel>
							<StepContent>
								<MailServerStep
									setFormDataSmtp={setFormDataSmtp}
									formDataSmtp={formDataSmtp}
									setActiveStepEmailSetup={setActiveStepEmailSetup}
								/>
							</StepContent>
						</Step>
						<Step>
							<StepLabel>{t('page.smtp.edit.step.emailVerification')}</StepLabel>
							<StepContent>
								<EmailVerificationStep
									setActiveStepEmailSetup={setActiveStepEmailSetup}
									formDataSmtp={formDataSmtp}
									setFormDataSmtp={setFormDataSmtp}
									setCreatedConfigId={setCreatedConfigId}
								/>
							</StepContent>
						</Step>
						<Step>
							<StepLabel>{t('page.smtp.edit.step.adminVerification')}</StepLabel>
							<StepContent>
								<VerifyEmailStep createdConfigId={createdConfigId} />
							</StepContent>
						</Step>
					</StyledStepper>
				</Stack>
			</Paper>
		</Box>
	);
};
