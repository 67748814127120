import { ServiceName } from './types';

export const mapServiceName = (name: ServiceName) => {
	switch (name) {
		case 'api':
			return 'API';
		case 'client':
			return 'Client';
		case 'ca':
			return 'CA';
		case 'core':
			return 'Core';
		case 'dashboard':
			return 'Dashboard';
		case 'hsm':
			return 'HSM';
		case 'identityStore':
			return 'Identity Store';
		case 'mailer':
			return 'Mailer';
		case 'pamOrchestrator':
			return 'PAM Orchestrator';
		case 'repository':
			return 'Repository';
		case 'token':
			return 'Token';
		default:
			return name;
	}
};
