import { Policy as PolicyIcon } from '@mui/icons-material';
import { Box, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NetworkAddressTable } from '../../components/NetworkAddressTable/NetworkAddressTable';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const NetworkPolicy = () => {
	const { t } = useTranslation();

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.networkPolicy.title')}
						description={t('page.networkPolicy.description')}
						icon={PolicyIcon}
					/>
					<NetworkAddressTable />
				</Stack>
			</Paper>
		</Box>
	);
};
