import * as React from 'react';
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	IconButton,
	InputAdornment,
	FormHelperText,
	Tooltip,
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { PasswordFieldProps } from './types';
import { useTranslation } from 'react-i18next';
import { EFieldType } from './enums';

export const PasswordField: React.FC<PasswordFieldProps> = ({
	register,
	name,
	label,
	helperText,
	error,
	disabled,
	fullWidth,
}): JSX.Element => {
	const { t } = useTranslation();

	const [show, setShow] = React.useState(false);

	const handleToggleVisibility = React.useCallback(() => {
		setShow((show) => !show);
	}, []);

	return (
		<FormControl sx={{ mt: 1, width: '100%' }}>
			<InputLabel
				htmlFor={`outlined-adornment-${name}`}
				disabled={disabled}
				sx={{
					color: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
				}}
			>
				{label}
			</InputLabel>
			<OutlinedInput
				id={`outlined-adornment-${name}`}
				type={show ? EFieldType.TEXT : EFieldType.PASSWORD}
				label={label}
				{...register(name)}
				endAdornment={
					<InputAdornment position='end'>
						<Tooltip
							title={
								show ?
									t('component.passwordField.body.tooltip.hide')
								:	t('component.passwordField.body.tooltip.show')
							}
						>
							<span>
								<IconButton
									aria-label={t('component.passwordField.body.ariaLabel')}
									onClick={handleToggleVisibility}
									disabled={disabled}
									edge='end'
								>
									{show ?
										<VisibilityOff />
									:	<Visibility />}
								</IconButton>
							</span>
						</Tooltip>
					</InputAdornment>
				}
				error={!!error}
				disabled={disabled}
				fullWidth={fullWidth}
			/>
			{error && typeof error.message === 'string' ?
				<FormHelperText error id={`${name}-error`}>
					{error.message}
				</FormHelperText>
			:	<FormHelperText disabled={disabled}>{helperText}</FormHelperText>}
		</FormControl>
	);
};
